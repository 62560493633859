<template>
	<div :class="color">
		<svg
			:class="size"
			fill="currentColor"
			:viewBox="icon.viewBox"
			v-html="icon.code"
			aria-hidden="true"
		></svg>
	</div>
</template>

<script>
export default {
	name: 'KtaIcons',
	props: {
		name: {
			type: String,
			required: true
		},
		color: {
			type: String,
			required: false,
			default: 'text-kta-blue'
		},
		size: {
			type: String,
			required: false
		}
	},
	data: () => ({
		creditcard: {
			code: `
			<g
				transform="translate(-462.246 -110.17)"
			>
				<path
					d="M508.929,116.862l0-2.88a3.812,3.812,0,0,0-3.812-3.812h-39.06a3.812,3.812,0,0,0-3.812,3.812l0,2.88Z"
				/>
				<path
					d="M504.255,122.012a11.935,11.935,0,0,0-7.984,3.062H462.246V138.12a3.813,3.813,0,0,0,3.814,3.814h26.378a11.86,11.86,0,0,1-.147-8.447c-.007.173-.026.342-.026.517a11.991,11.991,0,0,0,23.983,0V134A11.991,11.991,0,0,0,504.255,122.012Zm4.023,17.651v.008a5.038,5.038,0,0,1-3.251,1.243v1.737h-1.946v-1.776a4.856,4.856,0,0,1-2.849-1.1,3.785,3.785,0,0,1-1.112-2.54h2.95a1.483,1.483,0,0,0,.641,1.058,3.4,3.4,0,0,0,1.745.347,2.2,2.2,0,0,0,1.4-.347,1.244,1.244,0,0,0,.517-1.042,1.351,1.351,0,0,0-.394-.973,4.034,4.034,0,0,0-1.459-.772q-.918-.339-1.861-.726a12.5,12.5,0,0,1-1.83-.95,3.714,3.714,0,0,1-1.2-1.266,3.379,3.379,0,0,1-.386-1.706,3.336,3.336,0,0,1,1.012-2.509,4.878,4.878,0,0,1,2.826-1.22v-1.776h1.953v1.737a4.763,4.763,0,0,1,3,1.066,3.4,3.4,0,0,1,1.019,2.463h-2.865a1.158,1.158,0,0,0-.517-1.1,2.575,2.575,0,0,0-1.482-.394,2.784,2.784,0,0,0-1.544.347,1.135,1.135,0,0,0-.51,1,1.321,1.321,0,0,0,.286.88,3.093,3.093,0,0,0,1.089.71,9.519,9.519,0,0,0,1.143.456q.6.193,1.166.409t1.127.479a5.774,5.774,0,0,1,1.027.618,3.439,3.439,0,0,1,1.112,1.251,3.59,3.59,0,0,1,.324,1.544A3.783,3.783,0,0,1,508.278,139.663Z"
					transform="translate(0 -2.698)"
				/>
			</g>`,
			viewBox: '0 0 54 33.126'
		},
		vehicle: {
			code: `
			<path d="M68.665,53.121,56.254,50.827a.442.442,0,0,1-.34-.171c-1.275-1.53-8.925-9.605-13.94-9.605H26.509c-4.791,0-9.951,3.91-12.416,7.141a11.68,11.68,0,0,0-2.3,7.055v1.87l.085,3.061v2.465a4.209,4.209,0,0,0,4.25,4.251h1.111a7.319,7.319,0,0,0,14.053,0H51.963a7.319,7.319,0,0,0,14.053,0H67.39a4.218,4.218,0,0,0,4.165-3.655l.68-5.355A4.468,4.468,0,0,0,68.665,53.121Zm-44.4,15.3a3.586,3.586,0,1,1,3.586-3.586A3.586,3.586,0,0,1,24.269,68.425Zm7.759-16.578H20.977a.752.752,0,0,1-.68-1.105c1.615-2.89,5.781-5.781,7.82-5.781h2.805a.816.816,0,0,1,.765.6l1.105,5.355A.768.768,0,0,1,32.028,51.847Zm17.851,0H37.638a.818.818,0,0,1-.765-.6l-1.1-5.355a.8.8,0,0,1,.765-.935h5.441c1.785,0,5.611,2.891,8.415,5.526A.779.779,0,0,1,49.879,51.847Zm9.11,16.578a3.586,3.586,0,1,1,3.586-3.586A3.586,3.586,0,0,1,58.989,68.425Z" transform="translate(-11.797 -41.051)"/>`,
			viewBox: '0 0 60.451 31.111'
		},
		driveks: {
			code: `<path
			d="M31.1085 3.94301L41.3494 3.94301L42.4174 10.4316H42.4599L45.8767 3.94301H51.4769L44.4779 14.7983H38.7974L35.946 4.6281L34.0953 14.7983H29.1331L31.1085 3.94301Z"
		/>
		<path
			d="M31.808 0.133972H36.7701L36.2786 2.87024H31.3165L31.808 0.133972Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M12.7249 0.630181H2.73918L0.149414 14.7983H10.5605C15.2627 14.7983 18.1833 12.6953 19.0812 7.71424C19.9933 2.65285 17.5311 0.630181 12.7249 0.630181ZM9.26559 11.2256H5.86298L7.15786 4.20293H10.5652C13.1266 4.20293 13.9206 5.47419 13.5094 7.71424C13.0983 9.9543 11.827 11.2256 9.26559 11.2256Z"
		/>
		<path
			d="M29.4451 3.94301C29.7333 3.94301 30.1019 3.96192 30.4611 3.99027L29.6719 8.32388C29.1662 8.21519 28.6747 8.13485 28.1076 8.13485C26.3402 8.13485 24.3506 8.70668 23.9253 11.0507L23.2353 14.7983H18.2731L20.2485 3.94301H25.0547L24.62 6.34847H24.6578C25.8912 4.47703 27.739 3.94301 29.4451 3.94301Z"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M63.9531 11.5422C63.3766 12.6717 62.3936 13.6074 61.0467 14.2407C59.6998 14.8739 57.9891 15.2331 55.801 15.2331C50.7916 15.2331 48.3105 13.3286 49.0667 9.35885C49.7897 5.60652 52.9466 3.50351 57.6346 3.50351C62.3227 3.50351 65.3331 5.1103 64.2083 10.5261H53.7264C53.561 11.3012 54.1281 12.4921 56.2263 12.4921C57.4645 12.4921 58.2443 12.2133 58.8823 11.5422H63.9531ZM59.8747 8.03088C60.0165 6.85887 59.0713 6.12636 57.4362 6.12636C55.801 6.12636 54.7188 6.66038 54.1989 8.03088H59.8747Z"
		/>
		<path
			d="M91.7412 3.5224C93.4945 3.5224 94.1088 3.90519 94.1986 5.04885L94.2081 5.06303H100.12C100.144 4.74167 100.158 4.33997 100.144 4.21238C100.002 2.85133 99.1088 1.7502 97.6674 1.13584C96.3442 0.559287 94.6854 0.32772 92.1004 0.32772C89.0569 0.32772 87.2847 0.663256 85.9378 1.51391C84.5343 2.36456 83.7923 3.78232 83.9482 5.24734C84.0711 6.43353 84.7422 7.32199 86.0135 7.95998C86.9539 8.42784 88.5465 8.84844 91.2639 9.3163C92.9274 9.5904 93.5937 9.93066 93.6552 10.5025C93.7308 11.2208 92.7525 11.7312 91.3442 11.7312C89.8225 11.7312 88.693 11.0932 88.5985 10.1811C88.5752 9.94798 88.5887 9.82065 88.6299 9.4314L88.6316 9.41554H82.6061C82.4927 9.98737 82.469 10.2851 82.5163 10.753C82.8188 13.6357 85.7346 15.1858 90.7866 15.1858C93.5701 15.1858 95.8716 14.718 97.3083 13.8909C99.0143 12.9174 100.007 11.1783 99.8271 9.48171C99.7184 8.46092 99.0852 7.6339 97.9273 6.977C96.9822 6.44771 95.8621 6.14998 92.0531 5.40802C90.4416 5.09138 89.9879 4.87872 89.9406 4.41086C89.8792 3.83903 90.5361 3.5224 91.7412 3.5224Z"
		/>
		<path
			d="M78.4002 0.639642H86.3491L78.4286 7.83708L66.4878 4.81874L67.2424 0.639642H74.1091L73.1072 5.87117L78.4002 0.639642Z"
		/>
		<path
			d="M65.441 10.6157L64.6858 14.7983L71.3918 14.7936L72.4846 9.10883L65.441 10.6157Z"
		/>
		<path
			d="M72.5169 9.10192L76.3114 14.7983H84.8179L78.4286 7.83708L72.5169 9.10192Z"
		/>`,
			viewBox: '0 0 101 16'
		},
		ktag: {
			code: `
			<svg fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clip-path="url(#clip0_2186_114)">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M31.3493 4.6001H36V4.60319L35.6634 6.1441C35.3886 7.31137 34.9161 8.18528 34.0854 8.86464C33.2701 9.544 32.2757 9.86206 30.988 9.86206C28.3692 9.86206 26.6646 8.35203 26.5102 5.90942C26.4113 4.31292 26.9209 2.73186 27.9029 1.60783C28.7583 0.650548 29.972 0.137939 31.4419 0.137939C32.9119 0.137939 34.1904 0.635108 34.9655 1.49666C35.5028 2.105 35.7839 2.78745 35.8734 3.78487H32.9335C32.9029 3.30413 32.8874 3.27276 32.7938 3.08259L32.7915 3.07772C32.5722 2.67319 32.0843 2.41071 31.5284 2.41071C30.8367 2.41071 30.2252 2.8245 29.8021 3.60268C29.4315 4.30983 29.2463 5.14359 29.2988 5.96191C29.3636 6.96243 29.9843 7.5862 30.9139 7.5862C31.9237 7.5862 32.4888 7.10447 32.742 6.32321H31.0405L31.3493 4.6001ZM22.0779 0.372559L17.0381 9.62421H20.0614L20.6327 8.40445H23.7085L23.8567 9.62421H26.9325L25.2186 0.372559H22.0779ZM21.5715 6.47445L23.1217 3.16102L23.5201 6.47445H21.5715ZM19.934 2.7998L21.2557 0.372635H14.1561H8.96181L5.50308 3.79105L6.15777 0.372635H1.67069L0 9.62429L4.38208 9.6212L5.10162 5.87854L7.59685 9.62429H13.1555L8.98034 5.07566L11.4848 2.7998H14.5977L13.3717 9.62429H16.4598L17.6858 2.7998H19.934Z"
						fill="#0D2C6C"
					/>
					<path
						d="M1.17646 3.09644L0.49707 6.8638L8.97713 5.07893L1.17646 3.09644Z"
						fill="#FFFFFF"
					/>
				</g>
				<defs>
					<clipPath id="clip0_2186_114">
						<rect
							width="36.0001"
							height="9.72412"
							fill="white"
							transform="translate(0 0.137939)"
						/>
					</clipPath>
				</defs>
			</svg>`,
			viewBox: '-5 -3 45 15'
		},
		questionBubble: {
			code: `
			<g transform="translate(-7.844 -3.125)">
				<path d="M10.011,40.248h9.251l7.218,7.084a2.165,2.165,0,0,0,3.049,0l7.218-7.084H46a2.168,2.168,0,0,0,2.167-2.167V5.292A2.168,2.168,0,0,0,46,3.125H10.011A2.168,2.168,0,0,0,7.844,5.292V38.081A2.168,2.168,0,0,0,10.011,40.248ZM25.6,32.109a2.352,2.352,0,1,1,2.359,2.345A2.348,2.348,0,0,1,25.6,32.109Zm5.783-8.919a2.607,2.607,0,0,0-1.324,2.316,2.093,2.093,0,0,1-4.186,0,6.725,6.725,0,0,1,3.582-6.013,3.285,3.285,0,0,0,1.669-3.6,3.234,3.234,0,0,0-6.344.2,2.092,2.092,0,0,1-4.129-.676A7.411,7.411,0,1,1,31.385,23.19Z" transform="translate(0 0)" fill="#003e7e"/>
			</g>`,
			viewBox: '0 0 40.321 44.834'
		}
	}),
	computed: {
		icon() {
			return this[this.name]
		}
	}
}
</script>
