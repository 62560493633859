<template>
	<div
		:class="[
			{ 'md:hidden': !displayTabs },
			'flex overflow-x-scroll pt-16 text-white sm:pt-28 md:overflow-hidden md:pt-0'
		]"
		role="tablist"
		aria-label="Map tabs"
	>
		<button
			v-for="(tab, index) in tabs"
			:ref="`tab${index}`"
			type="button"
			:key="tab.type"
			:class="[
				tab.type === markerType
					? 'border-white md:border-cobalt md:text-kta-blue'
					: 'border-transparent text-sky hover:border-sky md:text-cobalt hover:md:text-kta-blue',
				'focus-visible:kta-ring-inset flex flex-1 items-center justify-center whitespace-nowrap border-b-4 px-3 py-3 text-center text-sm font-medium md:whitespace-normal md:px-4 '
			]"
			role="tab"
			:aria-selected="tab.type === markerType ? true : false"
			:tabindex="tab.type === markerType ? 0 : -1"
			@click="updateTab(tab.hash, tab.type)"
			@keyup.left="handleKey($event)"
			@keyup.right="handleKey($event)"
		>
			<span
				class="mr-2 h-5 w-5 md:h-8 md:w-8"
				v-html="tabIcons[tab.icon]"
			></span>
			<span class="leading-4">{{ tab.buttonName }}</span>
		</button>
	</div>
	<div class="mb-2 hidden md:block" v-if="!displayTabs">
		<span
			><em>
				Click a pin to view {{ currentTab.buttonName.toLowerCase() }}.</em
			></span
		>
	</div>
</template>

<script>
import tabIcons from '@/components/config/TabIcons'
export default {
	name: 'MapTabs',
	emits: ['updateMarkerType'],
	props: {
		displayTabs: {
			type: Boolean,
			default: false,
			required: false
		},
		hash: {
			type: String,
			default: '',
			required: true
		}
	},
	data: () => ({
		tabs: [
			{
				type: 'plaza',
				buttonName: 'Plan Trip',
				icon: 'exit',
				hash: ''
			},
			{
				type: 'service_area',
				buttonName: 'Service Areas',
				icon: 'service',
				hash: '#service-areas'
			},
			{
				type: 'camera',
				buttonName: 'Cameras',
				icon: 'camera',
				hash: '#cameras'
			},
			{
				type: 'parking',
				buttonName: 'Parking',
				icon: 'parking',
				hash: '#parking'
			},
			{
				type: 'dms',
				buttonName: 'Message Signs',
				icon: 'dms',
				hash: '#message-signs'
			},
			{
				type: 'weather',
				buttonName: 'Weather',
				icon: 'weather',
				hash: '#weather'
			}
		]
	}),
	methods: {
		handleKey(e) {
			if (e.keyCode === 37) {
				this.prevTab()
			} else if (e.keyCode === 39) {
				this.nextTab()
			}
		},
		nextTab() {
			const index =
				this.tabIndex + 1 === this.tabs.length ? 0 : this.tabIndex + 1

			this.$refs[`tab${index}`][0].focus()
			this.updateTab(this.tabs[index].hash, this.tabs[index].type)
		},
		prevTab() {
			const index =
				this.tabIndex === 0 ? this.tabs.length - 1 : this.tabIndex - 1

			this.$refs[`tab${index}`][0].focus()
			this.updateTab(this.tabs[index].hash, this.tabs[index].type)
		},
		updateTab(hash, markerType) {
			this.$router.replace({ hash: hash })
			this.updateMarkerType(markerType)
		},
		updateMarkerType(markerType) {
			this.$emit('updateMarkerType', markerType)
		}
	},
	computed: {
		currentTab() {
			return this.tabs.find((tab) => this.markerType === tab.type)
		},
		markerType() {
			return (
				this.tabs.find((tab) => tab.hash === this.$route.hash)?.type || 'plaza'
			)
		},
		tabIcons() {
			return tabIcons
		},
		tabIndex() {
			return this.tabs.map((tab) => tab.type).indexOf(this.currentTab.type)
		}
	},
	watch: {
		markerType: {
			handler() {
				this.updateMarkerType(this.currentTab.type)
			},
			immediate: true
		}
	}
}
</script>
