import axios from 'axios'
import { defineStore } from 'pinia'
import { useMainStore } from '@/stores/mainStore'

export const useFormStore = defineStore('form', {
	state: () => ({
		forms: {},
		span: {
			1: 'col-span-1',
			2: 'col-span-2',
			3: 'col-span-3',
			4: 'col-span-4',
			5: 'col-span-5',
			6: 'col-span-6',
			7: 'col-span-7',
			8: 'col-span-8',
			9: 'col-span-9',
			10: 'col-span-10',
			11: 'col-span-11',
			12: 'col-span-12'
		}
	}),
	getters: {
		mainStore() {
			return useMainStore()
		}
	},
	actions: {
		async fetchForm(id) {
			try {
				const {
					data: { content, ...rest }
				} = await axios.get(`${process.env.VUE_APP_API}/forms/published/${id}`)
				return { ...rest, content, mappedContent: this.mapFormItems(content) }
			} catch (error) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		},
		mapFormItems(content) {
			return content.reduce((arr, row) => {
				row.components.forEach(({ props }) => {
					const value = props.__type === 'CheckInput' ? [] : ''
					arr.push({
						label: props.label,
						required: props.required,
						error: false,
						value
					})
				})
				return arr
			}, [])
		},
		async submit(id, form) {
			try {
				const data = form.map((item) => {
					item.value =
						typeof item.value !== 'string' ? item.value.toString() : item.value
					return item
				})
				await axios.post(`${process.env.VUE_APP_API}/forms/${id}/submit`, {
					data
				})
				return true
			} catch (error) {
				this.mainStore.handleErrors(error.response.data.error)
				return false
			}
		}
	}
})
