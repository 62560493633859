<template>
	<div
		v-if="alert?.type"
		:class="[
			alert?.type === 'success'
				? 'border-green-500 bg-green-200 text-green-800'
				: 'border-red-500 bg-red-200 text-red-800',
			'relative mb-4 flex items-center justify-between rounded-md border p-4 text-sm'
		]"
	>
		<span class="pr-4">
			<span v-html="alert?.message"></span>
			<slot></slot>
		</span>
		<button
			:class="[
				alert?.type === 'success'
					? 'hover:text-green-900'
					: 'hover:text-red-900',
				'focus-visible:kta-ring absolute right-4 top-4'
			]"
			type="button"
			@click="closeAlert"
		>
			<span class="sr-only">Close alert</span>
			<XMarkIcon class="h-4 w-4" />
		</button>
	</div>
</template>

<script>
import { XMarkIcon } from '@heroicons/vue/20/solid'
export default {
	name: 'FormAlert',
	components: {
		XMarkIcon
	},
	props: {
		alert: {
			required: true
		}
	},
	emits: ['closeAlert'],
	methods: {
		closeAlert() {
			this.$emit('closeAlert')
		}
	}
}
</script>
