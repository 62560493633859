<template>
	<div
		:class="[
			active
				? 'visible bottom-2 md:h-auto md:max-h-12'
				: 'invisible -bottom-32 md:max-h-0',
			'fixed right-0 z-30 overflow-clip px-2 ease-out motion-safe:transition-all motion-safe:duration-300 print:hidden sm:z-50 md:sticky md:top-0 md:px-0'
		]"
		:aria-hidden="!active"
	>
		<div
			class="relative flex flex-col rounded bg-gradient-to-r from-[#ed631a] to-[#da4f12] py-3 pl-4 pr-4 text-white shadow-inset md:flex-row md:items-center md:rounded-none md:py-1 md:pr-2 md:shadow-md"
		>
			<span class="mr-4 shrink-0 text-lg font-bold md:text-base">{{
				title
			}}</span>
			<span
				id="text"
				class="text-sm md:text-sm [&_a]:text-white"
				v-html="text"
			></span>
			<span
				:class="[
					{ invisible: !active },
					'focus-visible:kta-ring absolute right-2 ml-auto cursor-pointer md:static md:inline-block'
				]"
				tabindex="0"
				@click="close"
				@keyup.enter="close"
			>
				<span class="sr-only">Close alert message</span>
				<XMarkIcon class="h-5 w-5" />
			</span>
		</div>
	</div>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'
import { XMarkIcon } from '@heroicons/vue/24/solid'
import { mapStores } from 'pinia'
export default {
	name: 'AlertBox',
	components: {
		XMarkIcon
	},
	methods: {
		close() {
			this.mainStore.closeAlert()
		}
	},
	computed: {
		...mapStores(useMainStore),
		alert() {
			return this.mainStore.getAlert
		},
		active() {
			return this.alert?.active
		},
		title() {
			return this.alert?.title
		},
		text() {
			return this.alert?.body
		},
		position() {
			return this.alert?.position
		}
	}
}
</script>
