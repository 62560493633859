<template>
	<label class="relative text-dark">
		<div v-if="label" class="mb-2 leading-none">
			{{ label }}
		</div>
		<Datepicker
			:class="{ '[&_input]:!border-kta-red': error }"
			v-model="value"
			:clearable="clearable"
			:enableTimePicker="enableTimePicker"
			:hideInputIcon="hideInputIcon"
			:is24="is24"
			:teleportCenter="teleportCenter"
		></Datepicker>
		<span v-if="error" class="absolute pt-1 text-xs italic text-kta-red">{{
			uppercaseFirst(error)
		}}</span>
	</label>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import upperFirst from 'lodash/upperFirst'
export default {
	name: 'DatePicker',
	props: {
		label: {
			type: String,
			required: false
		},
		modelValue: {
			required: true
		},
		clearable: {
			type: Boolean,
			default: true,
			required: false
		},
		enableTimePicker: {
			type: Boolean,
			default: false,
			required: false
		},
		hideInputIcon: {
			type: Boolean,
			default: false,
			required: false
		},
		is24: {
			type: Boolean,
			default: false,
			required: false
		},
		teleportCenter: {
			type: Boolean,
			default: true,
			required: false
		},
		error: {
			type: String,
			default: '',
			required: false
		}
	},
	emits: ['update:modelValue', 'removeError'],
	components: {
		Datepicker
	},
	methods: {
		uppercaseFirst(string) {
			return upperFirst(string)
		}
	},
	computed: {
		value: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		}
	},
	watch: {
		modelValue() {
			if (this.error) this.$emit('removeError')
		}
	}
}
</script>

<style>
.dp__input {
	padding: 8px 12px !important;
	border-color: #bbbbbb !important;
}

.dp__pm_am_button {
	background: #0477d9 !important;
}
</style>
