<script>
import {
	LINE_PATH_CONFIG,
	LINE_STYLE_CONFIG
} from '@/components/config/MapSettings'
export default {
	name: 'GoogleMapLine',
	props: {
		map: {
			type: Object,
			required: true
		}
	},
	mounted() {
		const directionsDisplay = new window.google.maps.DirectionsRenderer({
			map: this.map,
			suppressMarkers: true,
			polylineOptions: { ...LINE_STYLE_CONFIG }
		})
		const path = {
			...LINE_PATH_CONFIG,
			travelMode: window.google.maps.TravelMode.DRIVING
		}
		const directionsService = new window.google.maps.DirectionsService()
		directionsService.route(path, (response, status) => {
			if (status === window.google.maps.DirectionsStatus.OK) {
				directionsDisplay.setDirections(response)
			}
		})
	},
	render: () => null
}
</script>
