<template>
	<button
		type="button"
		class="focus-visible:kta-ring flex w-full items-center justify-center rounded border-2 border-white bg-white px-2 py-2 font-bold leading-3 text-cobalt hover:text-kta-blue active:text-cobalt sm:inline-flex sm:w-auto sm:rounded-md sm:border-cobalt sm:bg-transparent sm:py-1.5 sm:font-normal"
		@click="open = !open"
	>
		<MagnifyingGlassIcon class="h-5 w-5 stroke-3 sm:stroke-2" />
		<span class="ml-1 inline-block text-sm sm:hidden md:inline-block">
			Search
		</span>
	</button>

	<TransitionRoot :show="open" as="template" @after-leave="reset" appear>
		<Dialog as="div" class="relative z-50" @close="open = false">
			<TransitionChild
				as="template"
				enter="ease-out duration-300"
				enter-from="opacity-0"
				enter-to="opacity-100"
				leave="ease-in duration-200"
				leave-from="opacity-100"
				leave-to="opacity-0"
			>
				<div
					class="fixed inset-0 bg-gray-900 bg-opacity-50 transition-opacity"
				/>
			</TransitionChild>

			<div
				class="fixed inset-0 z-10 max-h-screen p-2 sm:px-8 sm:py-28 lg:py-36"
			>
				<TransitionChild
					as="template"
					enter="ease-out duration-300"
					enter-from="opacity-0 scale-95"
					enter-to="opacity-100 scale-100"
					leave="ease-in duration-200"
					leave-from="opacity-100 scale-100"
					leave-to="opacity-0 scale-95"
				>
					<DialogPanel
						class="mx-auto flex max-h-full transform flex-col divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow-2xl ring-1 ring-black ring-opacity-5 transition-all sm:text-base lg:max-w-screen-md"
					>
						<Combobox @update:modelValue="onSelect">
							<div class="relative flex items-center shadow-md">
								<MagnifyingGlassIcon
									class="pointer-events-none ml-4 h-6 w-6 text-blue-500"
									aria-hidden="true"
								/>
								<ComboboxInput
									class="h-16 flex-1 border-0 bg-transparent pl-2 pr-4 text-gray-800 placeholder-dark focus:ring-0"
									placeholder="Search..."
									autocomplete="off"
									@change="query = $event.target.value"
								/>
								<button
									class="cursor-pointer p-4 text-cobalt hover:text-kta-blue"
									aria-label="Close"
									@click="close"
								>
									<XMarkIcon class="h-4 w-4 stroke-2" />
								</button>
							</div>

							<ComboboxOptions
								v-if="resultsFound"
								static
								class="scroll-py-10 scroll-pb-2 space-y-4 overflow-y-auto bg-gray-200 pb-6 text-gray-900"
							>
								<li v-for="(prop, index) in indexes" :key="index">
									<h2 class="px-5 py-2 text-sm font-semibold text-blue-700">
										{{ formatProp(prop) }}
									</h2>
									<ul class="space-y-3 px-5 font-light">
										<ComboboxOption
											v-for="(result, hitIndex) in results[prop]"
											:key="hitIndex"
											:value="result"
											as="template"
											v-slot="{ active }"
										>
											<li
												:class="[
													'group flex cursor-pointer select-none items-center justify-between rounded-lg px-4 py-3 shadow-md',
													active ? 'bg-kta-blue text-white' : 'bg-white'
												]"
											>
												<span>
													<p class="w-full leading-6" v-html="result.body"></p>
													<span
														:aria-label="result.title"
														:class="[
															'mt-1 text-sm',
															active ? 'text-blue-300' : 'text-gray-600'
														]"
														v-html="result.title"
													>
													</span>
												</span>
												<svg
													viewBox="0 0 20 20"
													class="h-6 w-6"
													v-show="active"
												>
													<g
														stroke="currentColor"
														fill="none"
														fill-rule="evenodd"
														stroke-linecap="round"
														stroke-linejoin="round"
													>
														<path d="M18 3v4c0 2-2 4-4 4H2"></path>
														<path d="M8 17l-6-6 6-6"></path>
													</g>
												</svg>
											</li>
										</ComboboxOption>
									</ul>
								</li>
							</ComboboxOptions>

							<!-- no results -->
							<div
								v-if="noResultsFound"
								class="px-6 py-14 text-center text-sm sm:px-14"
							>
								<ExclaimationTriangleIcon
									class="mx-auto h-6 w-6 text-dark"
									aria-hidden="true"
								/>
								<p class="mt-4 font-semibold text-extra-dark">
									No results found
								</p>
								<p class="mt-2 text-dark">
									We didn’t find any items matching your search. Please try
									again.
								</p>
							</div>
						</Combobox>
					</DialogPanel>
				</TransitionChild>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'
import {
	Combobox,
	ComboboxInput,
	ComboboxOption,
	ComboboxOptions,
	Dialog,
	DialogPanel,
	TransitionChild,
	TransitionRoot
} from '@headlessui/vue'
import {
	ExclaimationTriangleIcon,
	MagnifyingGlassIcon
} from '@heroicons/vue/24/outline'
import { XMarkIcon } from '@heroicons/vue/24/solid'
import axios from 'axios'
import startCase from 'lodash/startCase'
import { mapStores } from 'pinia'

export default {
	name: 'SiteSearch',
	components: {
		MagnifyingGlassIcon,
		ExclaimationTriangleIcon,
		Combobox,
		ComboboxInput,
		ComboboxOptions,
		ComboboxOption,
		Dialog,
		DialogPanel,
		TransitionChild,
		TransitionRoot,
		XMarkIcon
	},
	emits: ['select'],
	data: () => ({
		query: '',
		results: {},
		open: false,
		noResultsFound: false
	}),
	methods: {
		formatProp(prop) {
			return startCase(prop.replace('-', ' '))
		},
		onSelect(value) {
			this.$router.push(`/${value.slug}`)
			this.$emit('select')
			this.reset()
			this.open = false
		},
		close() {
			this.open = false
			this.reset()
		},
		reset() {
			this.query = ''
			this.results = {}
		},
		async search(query) {
			try {
				const { data } = await axios.post(`${process.env.VUE_APP_API}/search`, {
					query
				})
				this.results = data
				this.noResultsFound = !this.indexes.length && this.query.length
			} catch (error) {
				this.mainStore.handleErrors(error.response.data.error)
			}
		}
	},
	computed: {
		...mapStores(useMainStore),
		resultsFound() {
			return this.indexes.length && this.query.length
		},
		indexes() {
			return Object.keys(this.results)
		}
	},
	watch: {
		query(value) {
			if (value.length) {
				this.search(value)
			} else {
				this.reset()
				this.noResultsFound = false
			}
		}
	}
}
</script>
