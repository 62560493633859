<template>
	<FormAlert :alert="alert" @closeAlert="closeAlert" />
	<form @submit.prevent="submit">
		<SelectInput
			label="I have a question/comment about"
			:width="100"
			:options="subjects"
			v-model="contact.subject"
			:error="errors?.subject"
			@removeError="removeError(['subject'])"
		/>
		<div class="mt-7 grid gap-x-5 gap-y-7 sm:grid-cols-6 md:grid-cols-12">
			<span class="sm:col-span-6">
				<TextInput
					label="First Name"
					v-model="contact.firstName"
					:error="errors?.firstName"
					@removeError="removeError(['firstName'])"
				/>
			</span>
			<span class="sm:col-span-6">
				<TextInput
					label="Last Name"
					v-model="contact.lastName"
					:error="errors?.lastName"
					@removeError="removeError(['lastName'])"
				/>
			</span>
			<span class="sm:col-span-6">
				<TextInput
					label="Email"
					v-model="contact.email"
					:error="errors?.email"
					@removeError="removeError(['email'])"
				/>
			</span>
			<span class="sm:col-span-6">
				<TextInput
					label="Phone"
					v-model="contact.phone"
					placeholder="(316) 555-5555"
					mask="(###) ###-####"
					:error="errors?.phone"
					@removeError="removeError(['phone'])"
				/>
			</span>
			<span class="sm:col-span-6 md:col-span-5">
				<TextInput
					label="Address"
					v-model="contact.address"
					:error="errors?.address"
					@removeError="removeError(['address'])"
				/>
			</span>
			<span class="sm:col-span-2 md:col-span-3">
				<TextInput
					label="City"
					v-model="contact.city"
					:error="errors?.city"
					@removeError="removeError(['city'])"
				/>
			</span>
			<span class="sm:col-span-2">
				<SelectInput
					label="State"
					:options="states"
					v-model="contact.state"
					:error="errors?.state"
					@removeError="removeError(['state'])"
				/>
			</span>
			<span class="sm:col-span-2">
				<TextInput
					label="Zip"
					v-model="contact.zip"
					mask="#####"
					:error="errors?.zip"
					@removeError="removeError(['zip'])"
				/>
			</span>
			<span class="sm:col-span-6 md:col-span-12">
				<TextArea
					label="Message"
					:rows="4"
					v-model="contact.message"
					:error="errors?.message"
					@removeError="removeError(['message'])"
				/>
			</span>
		</div>
		<span class="flex justify-end">
			<ActionButton type="submit" text="Send" />
		</span>
	</form>
	<LoadAnimation v-if="loading" />
</template>

<script>
import { mapStores } from 'pinia'
import { useMainStore } from '@/stores/mainStore'
import LoadAnimation from '@/components/utils/LoadAnimation'
import {
	FormAlert,
	SelectInput,
	TextArea,
	TextInput
} from '@/components/utils/form'
export default {
	name: 'ContactForm',
	components: {
		FormAlert,
		LoadAnimation,
		SelectInput,
		TextArea,
		TextInput
	},
	mounted() {
		this.mainStore.removeErrors()
	},
	data: () => ({
		contact: {
			subject: '',
			firstName: '',
			lastName: '',
			email: '',
			phone: '',
			address: '',
			city: '',
			state: '',
			zip: '',
			message: ''
		},
		alert: null
	}),
	methods: {
		removeError(props) {
			this.mainStore.removeError(props)
		},
		async submit() {
			const response = await this.mainStore.submitContact(this.contact)
			if (response) {
				this.resetForm()
			}
			this.alert = response
				? {
						type: 'success',
						message: 'Thank you! Your message was sent successfully.'
				  }
				: { type: 'fail', message: 'Failed to send message. Please try again.' }
		},
		closeAlert() {
			this.alert = null
		},
		resetForm() {
			for (const key in this.contact) {
				this.contact[key] = ''
			}
			this.contact.subject = ''
			this.contact.state = ''
		}
	},
	computed: {
		...mapStores(useMainStore),
		states() {
			return this.mainStore.states
		},
		subjects() {
			return this.mainStore.getSubjects || []
		},
		loading() {
			return this.mainStore.loading
		},
		errors() {
			return this.mainStore.errors
		}
	}
}
</script>
