<template>
	<svg
		fill="currentColor"
		viewBox="0 0 24 24"
		role="img"
		aria-labelledby="youtubeTitle"
	>
		<title id="youtubeTitle">Youtube icon</title>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M20.5952 4.47768C21.5418 4.74052 22.2872 5.515 22.5403 6.49842C23 8.28101 23 12 23 12C23 12 23 15.719 22.5403 17.5018C22.2872 18.485 21.5418 19.2595 20.5952 19.5224C18.8796 20 12 20 12 20C12 20 5.1204 20 3.40478 19.5224C2.45819 19.2595 1.71269 18.4851 1.45975 17.5017C1 15.7191 1 12 1 12C1 12 1 8.28101 1.45975 6.49842C1.71269 5.515 2.45819 4.74052 3.40478 4.47768C5.1204 4 12 4 12 4C12 4 18.8796 4 20.5952 4.47768ZM15 12.0001L10 15V9L15 12.0001Z"
		></path>
	</svg>
</template>

<script>
export default {
	name: 'YoutubeIcon'
}
</script>
