import { useMainStore } from '@/stores/mainStore'
import startCase from 'lodash/startCase'
import { createRouter, createWebHistory } from 'vue-router'
const CareerDetail = () => import('@/views/CareerDetail')
const HomeView = () => import('@/views/HomeView')
const InteractiveMap = () => import('@/views/InteractiveMap')
const SingleView = () => import('@/views/SingleView')
const PageNotFound = () => import('@/views/PageNotFound')
const Sitemap = () => import('@/views/Sitemap')

const routes = [
	{
		path: '/',
		name: 'home',
		meta: { title: 'Kansas Turnpike Authority' },
		component: HomeView
	},
	{
		path: '/careers/:position',
		name: 'job-detail',
		component: CareerDetail
	},
	{
		path: '/map',
		name: 'interactive-map',
		meta: { title: 'Map - Kansas Turnpike Authority' },
		component: InteractiveMap
	},
	// {
	// 	path: '/paytoll',
	// 	name: 'paytoll',
	// 	meta: {
	// 		title: 'Paytoll - Kansas Turnpike Authority'
	// 	},
	// 	redirect: '/cashless-conversion'
	// },
	{
		path: '/sitemap',
		name: 'sitemap',
		meta: { title: 'Sitemap - Kansas Turnpike Authority' },
		component: Sitemap
	},
	{
		path: '/:slug',
		name: 'page-view',
		meta: { dynamicTitle: true },
		component: SingleView
	},
	{
		path: '/:url(.*)*',
		name: 'page-not-found',
		meta: { title: '404 Page Not Found - Kansas Turnpike Authority' },
		component: PageNotFound,
		async beforeEnter(to) {
			const mainStore = useMainStore()
			await mainStore.fetchLinks()
			const links = mainStore.links

			for (const link of links) {
				if (to.fullPath === link.old) {
					if (link.type === 'internal') {
						router.replace({ path: link.new })
					} else {
						window.location.href = `${link.type}:\\\\${link.new}`
					}

					return false
				}
			}
		}
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior(to, from) {
		// prevent changing url hashes from scrolling to top of page
		if (to.path !== from.path) {
			return { top: 0 }
		}
	}
})

const body = document.body.classList

router.beforeEach((to, from, next) => {
	body.remove('overflow-hidden')
	document.title = to.meta.title
		? to.meta.title
		: to.meta.dynamicTitle
		? `${startCase(to.params.slug)} - Kansas Turnpike Authority`
		: 'Kansas Turnpike Authority'
	next()
})

export default router
