<template>
	<div
		:class="`grid w-full grid-cols-1 ${columnClasses} items-end gap-x-5 gap-y-7 sm:gap-y-5`"
	>
		<component
			:is="component.props.__type"
			v-for="(component, index) in components"
			:key="index"
			:props="component.props"
			:mappedContent="mappedContent"
			@updateValue="$emit('updateValue', $event)"
		/>
	</div>
</template>

<script>
import {
	CheckInput,
	RadioInput,
	SelectInput,
	TextArea,
	TextInput
} from '@/components/formBuilder'
export default {
	name: 'FormRow',
	props: {
		components: {
			type: Array,
			required: true
		},
		columns: {
			type: Number,
			default: 1,
			required: false
		},
		mappedContent: {
			type: Array,
			required: true
		}
	},
	emits: ['updateValue'],
	components: {
		CheckInput,
		RadioInput,
		SelectInput,
		TextArea,
		TextInput
	},
	computed: {
		columnClasses() {
			const cols = {
				1: 'sm:grid-cols-1',
				2: 'sm:grid-cols-2',
				3: 'sm:grid-cols-3',
				4: 'sm:grid-cols-4',
				5: 'sm:grid-cols-5',
				6: 'sm:grid-cols-6',
				7: 'sm:grid-cols-7',
				8: 'sm:grid-cols-8',
				9: 'sm:grid-cols-9',
				10: 'sm:grid-cols-10',
				11: 'sm:grid-cols-11',
				12: 'sm:grid-cols-12'
			}

			return cols[this.columns]
		}
	}
}
</script>
