<template>
	<div
		:class="[
			{ 'sm:shadow-md': scrolled },
			'fixed inset-x-0 top-2 z-30 ease-out motion-safe:transition-all motion-safe:duration-300 print:hidden sm:sticky sm:top-0 sm:bg-white'
		]"
	>
		<nav
			:class="[
				{ 'bottom-0 overflow-y-scroll': open },
				scrolled ? 'lg:h-24' : 'lg:h-32',
				'fixed inset-x-0 top-0 h-auto items-center justify-between px-2 ease-in motion-safe:transition-height sm:static sm:mt-0 sm:flex sm:h-24 sm:rounded-none sm:px-6 md:overflow-visible lg:mx-auto lg:max-w-screen-lg lg:px-10 xl:max-w-screen-xl xl:px-20'
			]"
			aria-label="Main"
		>
			<!-- mobile nav -->
			<div class="absolute inset-x-0 bottom-0 top-3 sm:hidden">
				<div
					:class="[
						{ hidden: !open },
						'absolute inset-0 overflow-y-scroll px-2'
					]"
				>
					<div
						class="fixed inset-0 bg-black/50"
						v-show="open"
						@click="closeMenu"
					></div>
					<div
						class="relative top-8 mb-16 rounded-b-lg bg-kta-blue py-3 pb-8 text-white shadow-lg"
					>
						<ul class="my-6 space-y-6 border-b border-b-cobalt pb-6">
							<li v-for="(menuItem, index) in menu" :key="index">
								<button
									class="flex items-center px-4 text-2xl font-bold uppercase tracking-wide"
									v-if="menuItem.items.length"
									@click="toggleSubmenu(menuItem)"
								>
									<span>{{ menuItem.name }}</span>
									<ChevronRightIcon
										:class="[
											{ 'rotate-90': submenu?.name === menuItem.name },
											'-mr-1 ml-1 h-7 w-7 motion-safe:transition-all motion-safe:duration-300'
										]"
										aria-hidden="true"
									/>
								</button>
								<ul
									v-if="menuItem.items.length"
									:class="[
										submenu === menuItem ? 'max-h-screen' : 'max-h-0',
										'mt-4 overflow-hidden text-lg'
									]"
								>
									<li
										v-for="(item, index) in menuItem.items"
										:key="index"
										class="first:pt-2 last:pb-2"
									>
										<nav-link
											class="w-full"
											@click="closeMenu"
											v-slot="{ isActive }"
											:to="item.url"
										>
											<span
												:class="[
													{ 'bg-cobalt': isActive },
													'inline-block w-full px-4 py-2'
												]"
											>
												{{ item.name }}
											</span>
										</nav-link>
									</li>
								</ul>
								<nav-link
									@click="closeMenu"
									:to="menuItem.url"
									class="px-4 text-2xl font-bold uppercase tracking-wide"
									v-else
									>{{ menuItem.name }}</nav-link
								>
							</li>
						</ul>
						<ul class="my-6 space-y-6">
							<li v-for="(menuItem, index) in secondaryMenu" :key="index">
								<button
									class="flex items-center px-4 text-xl font-bold tracking-wide"
									v-if="menuItem.items.length"
									@click="toggleSubmenu(menuItem)"
								>
									<span>{{ menuItem.name }}</span>
									<ChevronRightIcon
										:class="[
											{ 'rotate-90': submenu?.name === menuItem.name },
											'-mr-1 ml-1 h-7 w-7 motion-safe:transition-all motion-safe:duration-300'
										]"
										aria-hidden="true"
									/>
								</button>
								<ul
									v-if="menuItem.items.length"
									:class="[
										submenu === menuItem ? 'max-h-screen' : 'max-h-0',
										'mt-4 overflow-hidden text-lg'
									]"
								>
									<li
										v-for="(item, index) in menuItem.items"
										:key="index"
										class="first:pt-2 last:pb-2"
									>
										<nav-link
											class="w-full"
											@click="closeMenu"
											v-slot="{ isActive }"
											:to="item.url"
										>
											<span
												:class="[
													{ 'bg-cobalt': isActive },
													'inline-block w-full px-4 py-2'
												]"
											>
												{{ item.name }}
											</span>
										</nav-link>
									</li>
								</ul>
								<nav-link
									@click="closeMenu"
									:to="menuItem.url"
									class="px-4 text-xl font-bold tracking-wide"
									v-else
									>{{ menuItem.name }}</nav-link
								>
							</li>
						</ul>
						<ul class="my-6 space-y-6">
							<li v-for="(menuItem, index) in auxiliaryMenu" :key="index">
								<button
									class="flex items-center px-4 text-xl font-bold tracking-wide"
									v-if="menuItem.items.length"
									@click="toggleSubmenu(menuItem)"
								>
									<span>{{ menuItem.name }}</span>
									<ChevronDownIcon
										:class="[
											{ '-rotate-180': submenu?.name === menuItem.name },
											'-mr-1 ml-1 h-7 w-7 duration-300 motion-safe:transition-all'
										]"
										aria-hidden="true"
									/>
								</button>
								<ul
									v-if="menuItem.items.length"
									:class="[
										submenu === menuItem ? 'max-h-screen' : 'max-h-0',
										'mt-4 overflow-hidden text-lg'
									]"
								>
									<li
										v-for="(item, index) in menuItem.items"
										:key="index"
										class="first:pt-2 last:pb-2"
									>
										<nav-link
											class="w-full"
											@click="closeMenu"
											v-slot="{ isActive }"
											:to="item.url"
										>
											<span
												:class="[
													{ 'bg-cobalt': isActive },
													'inline-block w-full px-4 py-2'
												]"
											>
												{{ item.name }}
											</span>
										</nav-link>
									</li>
								</ul>
								<nav-link
									class="px-4 text-xl font-bold tracking-wide"
									:to="menuItem.url"
									@click="closeMenu"
									v-else
									>{{ menuItem.name }}</nav-link
								>
							</li>
						</ul>
						<nav-link
							class="mx-4 flex items-center justify-center"
							to="paytoll"
							button="gold"
							fullWidth
							@click="closeMenu"
						>
							<PayIcon class="h-5 text-kta-blue" />
							<span class="ml-2">Pay Your Bill</span>
						</nav-link>
						<span class="mx-4 mt-4 block">
							<SiteSearch @select="closeMenu" />
						</span>
					</div>
				</div>
			</div>
			<div
				class="relative mt-2 flex items-center justify-between rounded-lg bg-white px-2 py-1.5 shadow-md sm:mt-0 sm:p-0 sm:shadow-none"
			>
				<nav-link class="focus-visible:kta-ring active:kta-ring" to="">
					<span class="sr-only">home</span>
					<KtaLogo class="not-sr-only block h-8 sm:hidden sm:h-12 xl:block" />
					<KtaLogoCompact
						class="not-sr-only hidden h-9 sm:block md:h-12 xl:hidden"
					/>
				</nav-link>
				<button
					@click="toggleMenu"
					class="space-y-1 pr-2 sm:hidden"
					aria-label="Toggle mobile menu"
				>
					<span
						:class="[
							open ? 'translate-y-1 rotate-45' : '',
							'block h-1 w-8 bg-kta-blue motion-safe:transition motion-safe:duration-150 motion-safe:ease-in'
						]"
					></span>
					<span
						:class="[
							open ? '-translate-y-1 -rotate-45' : '',
							'block h-1 w-8 bg-kta-blue motion-safe:transition motion-safe:duration-150 motion-safe:ease-in'
						]"
					></span>
				</button>
			</div>
			<!-- dekstop nav -->
			<div
				class="hidden space-x-2 sm:flex sm:items-center sm-plus:space-x-4 md:space-x-4 lg:text-lg xl:space-x-6"
			>
				<span v-for="(menuItem, index) in menu" :key="index">
					<Menu
						as="span"
						class="relative inline-block text-left"
						v-if="menuItem.items.length"
					>
						<span>
							<MenuButton
								class="inline-flex items-center justify-center font-bold uppercase text-kta-blue focus:rounded focus:outline-none focus:ring-2 focus:ring-sky focus:ring-offset-4"
							>
								{{ menuItem.name }}
								<ChevronDownIcon
									class="text-violet-200 hover:text-violet-100 -mr-1 ml-1 h-5 w-5"
									aria-hidden="true"
								/>
							</MenuButton>
						</span>
						<transition
							enter-active-class="transition duration-100 ease-out"
							enter-from-class="transform scale-95 opacity-0"
							enter-to-class="transform scale-100 opacity-100"
							leave-active-class="transition duration-75 ease-in"
							leave-from-class="transform scale-100 opacity-100"
							leave-to-class="transform scale-95 opacity-0"
						>
							<MenuItems
								class="absolute right-0 mt-2 w-56 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
							>
								<MenuItem
									v-for="(item, index) in menuItem.items"
									:key="index"
									v-slot="{ active, close }"
								>
									<nav-link
										:class="[
											active ? 'bg-cobalt text-white' : 'text-extra-dark',
											'group flex w-full items-center px-4 py-2 text-sm'
										]"
										:to="item.url"
										@click="close"
									>
										{{ item.name }}
									</nav-link>
								</MenuItem>
							</MenuItems>
						</transition>
					</Menu>
					<nav-link
						class="font-bold uppercase text-kta-blue"
						:to="menuItem.url"
						v-else
						>{{ menuItem.name }}</nav-link
					>
				</span>
				<nav-link to="https://driveks.com/pay-your-bill" button="gold" shadow
					>Pay Your Bill</nav-link
				>
				<SiteSearch />
			</div>
		</nav>
	</div>
</template>

<script>
import SiteSearch from '@/components/SiteSearch.vue'
import { KtaLogo, KtaLogoCompact, PayIcon } from '@/components/icons'
import { useMainStore } from '@/stores/mainStore'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/vue/24/solid'
import { mapStores } from 'pinia'

export default {
	components: {
		ChevronDownIcon,
		ChevronRightIcon,
		KtaLogo,
		KtaLogoCompact,
		Menu,
		MenuButton,
		MenuItems,
		MenuItem,
		PayIcon,
		SiteSearch
	},
	created() {
		this.body = document.body.classList
		window.addEventListener('scroll', this.handleScroll)
	},
	data: () => ({
		open: false,
		body: null,
		submenu: null,
		scrolled: false
	}),
	computed: {
		...mapStores(useMainStore),
		menu() {
			return this.mainStore.getPrimaryMenu
		},
		auxiliaryMenu() {
			return this.mainStore.getAuxiliaryMenu
		},
		secondaryMenu() {
			return this.mainStore.getSecondaryMenu
		}
	},
	methods: {
		closeMenu() {
			this.open = false
		},
		toggleMenu() {
			this.open = !this.open
		},
		toggleSubmenu(item) {
			this.submenu = this.submenu === item ? null : item
		},
		handleScroll() {
			if (this.scrolled && window.scrollY > 0) {
				return
			}

			this.scrolled =
				this.$route.name !== 'home' ? window.scrollY > 96 : window.scrollY >= 32
		}
	},
	watch: {
		open(value) {
			value
				? this.body.add('overflow-hidden')
				: this.body.remove('overflow-hidden')
		}
	}
}
</script>
