<template>
	<article
		class="my-10"
		v-for="(job, index) in jobs"
		:key="index"
		aria-label="Job posts"
	>
		<h2 class="!mb-6 !text-2xl !leading-none sm:!text-3xl sm:leading-none">
			{{ job.title }}
		</h2>
		<section :aria-label="`Job details ${index + 1}`">
			<ul class="mb-4 flex flex-wrap space-x-7">
				<li>
					<span class="sr-only">Position Type </span>{{ job.positionType }}
				</li>
				<li><span class="sr-only">Department </span>{{ job.department }}</li>
				<li><span class="sr-only">Location </span>{{ job.location }}</li>
				<li>
					Posted <time :datetime="job.posted">{{ job.posted }}</time>
				</li>
			</ul>
		</section>
		<section :aria-label="`Job summary ${index + 1}`">
			<p v-html="job.summary"></p>
		</section>
		<NavLink :to="createLink(job)" size="large" button>Learn More</NavLink>
		<hr
			:class="[{ hidden: index === jobs.length - 1 }, 'mt-10 border-medium']"
		/>
	</article>
</template>

<script>
import { mapStores } from 'pinia'
import { useMainStore } from '@/stores/mainStore'
export default {
	name: 'Careers',
	async created() {
		await this.mainStore.fetchJobs()
	},
	methods: {
		createLink(job) {
			const jobName = job.title.toLowerCase().replace(/[\W_]/g, '-')
			return `careers/${jobName}`
		}
	},
	computed: {
		...mapStores(useMainStore),
		jobs() {
			return this.mainStore.getJobs
		},
		siteConfig() {
			return this.mainStore.siteConfiguration
		}
	}
}
</script>

<style scoped>
ul li {
	margin-left: 0;
}
li:first-child {
	list-style: none;
}
</style>
