export const ctsRates = [
	{ zoneCode: 17, class: 2, transponderRate: 0.91 },
	{ zoneCode: 17, class: 3, transponderRate: 1.25 },
	{ zoneCode: 17, class: 4, transponderRate: 1.75 },
	{ zoneCode: 17, class: 5, transponderRate: 2.62 },
	{ zoneCode: 17, class: 6, transponderRate: 3.55 },
	{ zoneCode: 17, class: 7, transponderRate: 4.9 },
	{ zoneCode: 17, class: 8, transponderRate: 5.76 },
	{ zoneCode: 17, class: 9, transponderRate: 6.35 },
	{ zoneCode: 31, class: 2, transponderRate: 0.67 },
	{ zoneCode: 31, class: 3, transponderRate: 0.92 },
	{ zoneCode: 31, class: 4, transponderRate: 1.29 },
	{ zoneCode: 31, class: 5, transponderRate: 1.93 },
	{ zoneCode: 31, class: 6, transponderRate: 2.62 },
	{ zoneCode: 31, class: 7, transponderRate: 3.61 },
	{ zoneCode: 31, class: 8, transponderRate: 4.24 },
	{ zoneCode: 31, class: 9, transponderRate: 4.68 },
	{ zoneCode: 36, class: 2, transponderRate: 0.29 },
	{ zoneCode: 36, class: 3, transponderRate: 0.4 },
	{ zoneCode: 36, class: 4, transponderRate: 0.55 },
	{ zoneCode: 36, class: 5, transponderRate: 0.83 },
	{ zoneCode: 36, class: 6, transponderRate: 1.12 },
	{ zoneCode: 36, class: 7, transponderRate: 1.55 },
	{ zoneCode: 36, class: 8, transponderRate: 1.82 },
	{ zoneCode: 36, class: 9, transponderRate: 2 },
	{ zoneCode: 40, class: 2, transponderRate: 0.14 },
	{ zoneCode: 40, class: 3, transponderRate: 0.2 },
	{ zoneCode: 40, class: 4, transponderRate: 0.28 },
	{ zoneCode: 40, class: 5, transponderRate: 0.41 },
	{ zoneCode: 40, class: 6, transponderRate: 0.56 },
	{ zoneCode: 40, class: 7, transponderRate: 0.77 },
	{ zoneCode: 40, class: 8, transponderRate: 0.91 },
	{ zoneCode: 40, class: 9, transponderRate: 1 },
	{ zoneCode: 43, class: 2, transponderRate: 0.14 },
	{ zoneCode: 43, class: 3, transponderRate: 0.2 },
	{ zoneCode: 43, class: 4, transponderRate: 0.28 },
	{ zoneCode: 43, class: 5, transponderRate: 0.41 },
	{ zoneCode: 43, class: 6, transponderRate: 0.56 },
	{ zoneCode: 43, class: 7, transponderRate: 0.77 },
	{ zoneCode: 43, class: 8, transponderRate: 0.91 },
	{ zoneCode: 43, class: 9, transponderRate: 1 },
	{ zoneCode: 47, class: 2, transponderRate: 0.24 },
	{ zoneCode: 47, class: 3, transponderRate: 0.33 },
	{ zoneCode: 47, class: 4, transponderRate: 0.46 },
	{ zoneCode: 47, class: 5, transponderRate: 0.69 },
	{ zoneCode: 47, class: 6, transponderRate: 0.94 },
	{ zoneCode: 47, class: 7, transponderRate: 1.29 },
	{ zoneCode: 47, class: 8, transponderRate: 1.52 },
	{ zoneCode: 47, class: 9, transponderRate: 1.67 },
	{ zoneCode: 50, class: 2, transponderRate: 0.14 },
	{ zoneCode: 50, class: 3, transponderRate: 0.2 },
	{ zoneCode: 50, class: 4, transponderRate: 0.28 },
	{ zoneCode: 50, class: 5, transponderRate: 0.41 },
	{ zoneCode: 50, class: 6, transponderRate: 0.56 },
	{ zoneCode: 50, class: 7, transponderRate: 0.77 },
	{ zoneCode: 50, class: 8, transponderRate: 0.91 },
	{ zoneCode: 50, class: 9, transponderRate: 1 },
	{ zoneCode: 56, class: 2, transponderRate: 0.19 },
	{ zoneCode: 56, class: 3, transponderRate: 0.26 },
	{ zoneCode: 56, class: 4, transponderRate: 0.37 },
	{ zoneCode: 56, class: 5, transponderRate: 0.55 },
	{ zoneCode: 56, class: 6, transponderRate: 0.75 },
	{ zoneCode: 56, class: 7, transponderRate: 1.03 },
	{ zoneCode: 56, class: 8, transponderRate: 1.21 },
	{ zoneCode: 56, class: 9, transponderRate: 1.34 },
	{ zoneCode: 68, class: 2, transponderRate: 0.67 },
	{ zoneCode: 68, class: 3, transponderRate: 0.92 },
	{ zoneCode: 68, class: 4, transponderRate: 1.29 },
	{ zoneCode: 68, class: 5, transponderRate: 1.93 },
	{ zoneCode: 68, class: 6, transponderRate: 2.62 },
	{ zoneCode: 68, class: 7, transponderRate: 3.61 },
	{ zoneCode: 68, class: 8, transponderRate: 4.24 },
	{ zoneCode: 68, class: 9, transponderRate: 4.68 },
	{ zoneCode: 73, class: 2, transponderRate: 0.24 },
	{ zoneCode: 73, class: 3, transponderRate: 0.33 },
	{ zoneCode: 73, class: 4, transponderRate: 0.46 },
	{ zoneCode: 73, class: 5, transponderRate: 0.69 },
	{ zoneCode: 73, class: 6, transponderRate: 0.94 },
	{ zoneCode: 73, class: 7, transponderRate: 1.29 },
	{ zoneCode: 73, class: 8, transponderRate: 1.52 },
	{ zoneCode: 73, class: 9, transponderRate: 1.67 },
	{ zoneCode: 87, class: 2, transponderRate: 0.77 },
	{ zoneCode: 87, class: 3, transponderRate: 1.06 },
	{ zoneCode: 87, class: 4, transponderRate: 1.47 },
	{ zoneCode: 87, class: 5, transponderRate: 2.21 },
	{ zoneCode: 87, class: 6, transponderRate: 2.99 },
	{ zoneCode: 87, class: 7, transponderRate: 4.13 },
	{ zoneCode: 87, class: 8, transponderRate: 4.85 },
	{ zoneCode: 87, class: 9, transponderRate: 5.34 },
	{ zoneCode: 123, class: 2, transponderRate: 1.68 },
	{ zoneCode: 123, class: 3, transponderRate: 2.31 },
	{ zoneCode: 123, class: 4, transponderRate: 3.22 },
	{ zoneCode: 123, class: 5, transponderRate: 4.83 },
	{ zoneCode: 123, class: 6, transponderRate: 6.55 },
	{ zoneCode: 123, class: 7, transponderRate: 9.03 },
	{ zoneCode: 123, class: 8, transponderRate: 10.61 },
	{ zoneCode: 123, class: 9, transponderRate: 11.69 },
	{ zoneCode: 146, class: 2, transponderRate: 0.96 },
	{ zoneCode: 146, class: 3, transponderRate: 1.32 },
	{ zoneCode: 146, class: 4, transponderRate: 1.84 },
	{ zoneCode: 146, class: 5, transponderRate: 2.76 },
	{ zoneCode: 146, class: 6, transponderRate: 3.74 },
	{ zoneCode: 146, class: 7, transponderRate: 5.16 },
	{ zoneCode: 146, class: 8, transponderRate: 6.06 },
	{ zoneCode: 146, class: 9, transponderRate: 6.68 },
	{ zoneCode: 151, class: 2, transponderRate: 1.44 },
	{ zoneCode: 151, class: 3, transponderRate: 1.98 },
	{ zoneCode: 151, class: 4, transponderRate: 2.76 },
	{ zoneCode: 151, class: 5, transponderRate: 4.14 },
	{ zoneCode: 151, class: 6, transponderRate: 5.61 },
	{ zoneCode: 151, class: 7, transponderRate: 7.74 },
	{ zoneCode: 151, class: 8, transponderRate: 9.09 },
	{ zoneCode: 151, class: 9, transponderRate: 10.02 },
	{ zoneCode: 179, class: 2, transponderRate: 0.24 },
	{ zoneCode: 179, class: 3, transponderRate: 0.33 },
	{ zoneCode: 179, class: 4, transponderRate: 0.46 },
	{ zoneCode: 179, class: 5, transponderRate: 0.69 },
	{ zoneCode: 179, class: 6, transponderRate: 0.94 },
	{ zoneCode: 179, class: 7, transponderRate: 1.29 },
	{ zoneCode: 179, class: 8, transponderRate: 1.52 },
	{ zoneCode: 179, class: 9, transponderRate: 1.67 },
	{ zoneCode: 183, class: 2, transponderRate: 0.1 },
	{ zoneCode: 183, class: 3, transponderRate: 0.13 },
	{ zoneCode: 183, class: 4, transponderRate: 0.18 },
	{ zoneCode: 183, class: 5, transponderRate: 0.28 },
	{ zoneCode: 183, class: 6, transponderRate: 0.37 },
	{ zoneCode: 183, class: 7, transponderRate: 0.52 },
	{ zoneCode: 183, class: 8, transponderRate: 0.61 },
	{ zoneCode: 183, class: 9, transponderRate: 0.67 },
	{ zoneCode: 191, class: 2, transponderRate: 0.72 },
	{ zoneCode: 191, class: 3, transponderRate: 0.99 },
	{ zoneCode: 191, class: 4, transponderRate: 1.38 },
	{ zoneCode: 191, class: 5, transponderRate: 2.07 },
	{ zoneCode: 191, class: 6, transponderRate: 2.81 },
	{ zoneCode: 191, class: 7, transponderRate: 3.87 },
	{ zoneCode: 191, class: 8, transponderRate: 4.55 },
	{ zoneCode: 191, class: 9, transponderRate: 5.01 },
	{ zoneCode: 201, class: 2, transponderRate: 0.24 },
	{ zoneCode: 201, class: 3, transponderRate: 0.33 },
	{ zoneCode: 201, class: 4, transponderRate: 0.46 },
	{ zoneCode: 201, class: 5, transponderRate: 0.69 },
	{ zoneCode: 201, class: 6, transponderRate: 0.94 },
	{ zoneCode: 201, class: 7, transponderRate: 1.29 },
	{ zoneCode: 201, class: 8, transponderRate: 1.52 },
	{ zoneCode: 201, class: 9, transponderRate: 1.67 },
	{ zoneCode: 202, class: 2, transponderRate: 0.1 },
	{ zoneCode: 202, class: 3, transponderRate: 0.13 },
	{ zoneCode: 202, class: 4, transponderRate: 0.18 },
	{ zoneCode: 202, class: 5, transponderRate: 0.28 },
	{ zoneCode: 202, class: 6, transponderRate: 0.37 },
	{ zoneCode: 202, class: 7, transponderRate: 0.52 },
	{ zoneCode: 202, class: 8, transponderRate: 0.61 },
	{ zoneCode: 202, class: 9, transponderRate: 0.67 },
	{ zoneCode: 210, class: 2, transponderRate: 0.38 },
	{ zoneCode: 210, class: 3, transponderRate: 0.53 },
	{ zoneCode: 210, class: 4, transponderRate: 0.74 },
	{ zoneCode: 210, class: 5, transponderRate: 1.1 },
	{ zoneCode: 210, class: 6, transponderRate: 1.5 },
	{ zoneCode: 210, class: 7, transponderRate: 2.06 },
	{ zoneCode: 210, class: 8, transponderRate: 2.42 },
	{ zoneCode: 210, class: 9, transponderRate: 2.67 },
	{ zoneCode: 217, class: 2, transponderRate: 1.2 },
	{ zoneCode: 217, class: 3, transponderRate: 1.65 },
	{ zoneCode: 217, class: 4, transponderRate: 2.3 },
	{ zoneCode: 217, class: 5, transponderRate: 3.45 },
	{ zoneCode: 217, class: 6, transponderRate: 4.68 },
	{ zoneCode: 217, class: 7, transponderRate: 6.45 },
	{ zoneCode: 217, class: 8, transponderRate: 7.58 },
	{ zoneCode: 217, class: 9, transponderRate: 8.35 }
]
