<template>
	<div :class="[gridColumns, 'mx-auto mb-5 grid w-fit gap-5 sm:gap-10']">
		<FlipCard v-for="(item, index) in filteredLeadership" :key="index">
			<template #front>
				<LeadershipCard
					:path="item.image.path"
					:name="item.name"
					:title="item.title"
				/>
			</template>
			<template #back>
				<LeadershipCard :description="item.description" :link="item.link" />
			</template>
		</FlipCard>
	</div>
</template>

<script>
import { mapStores } from 'pinia'
import { useMainStore } from '@/stores/mainStore'
import FlipCard from '@/components/cards/FlipCard'
import LeadershipCard from '@/components/cards/LeadershipCard'
export default {
	name: 'Leadership',
	props: {
		group: {
			type: String,
			required: true
		},
		columns: {
			type: Number,
			required: false
		}
	},
	components: {
		FlipCard,
		LeadershipCard
	},
	async created() {
		if (!this.mainStore.leadership.length)
			await this.mainStore.fetchLeadership(this.group)
	},
	computed: {
		...mapStores(useMainStore),
		leadership() {
			return this.mainStore.leadership
		},
		filteredLeadership() {
			return this.leadership[this.group]
		},
		gridColumns() {
			switch (parseInt(this.columns)) {
				case 1:
					break
				case 2:
					return 'grid-cols-1 sm:grid-cols-2'
				case 3:
					return 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-3'
				case 4:
					return 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-4'
			}
			return `grid-cols-1 sm:grid-cols-2 lg:grid-cols-3`
		}
	}
}
</script>
