<template>
	<label class="relative text-dark">
		<div v-if="label" class="mb-2 leading-none">
			{{ required ? `${label} *` : label }}
		</div>
		<input
			:class="[
				{ 'h-14': large },
				{ 'border-kta-red': error },
				'block w-full rounded border border-medium placeholder:italic placeholder:text-medium focus:border-sky'
			]"
			type="text"
			v-maska="mask"
			:aria-label="label"
			:autocomplete="[autocomplete ? 'on' : 'off']"
			:placeholder="placeholder"
			:value="maskedValue"
			@input="handleInput($event.target.value)"
			@maska="$emit('update:modelValue', $event.target.dataset.maskRawValue)"
		/>
		<slot></slot>
		<span v-if="error" class="absolute pt-1 text-xs italic text-kta-red">{{
			uppercaseFirst(error)
		}}</span>
	</label>
</template>

<script>
import { maska, mask } from 'maska'
import upperFirst from 'lodash/upperFirst'
export default {
	name: 'TextInput',
	directives: { maska },
	props: {
		autocomplete: {
			type: Boolean,
			required: false,
			default: false
		},
		modelValue: {
			required: true
		},
		label: {
			type: String,
			required: false
		},
		large: {
			type: Boolean,
			required: false
		},
		mask: {
			type: String,
			default: '',
			required: false
		},
		placeholder: {
			type: String,
			default: '',
			required: false
		},
		required: {
			type: Boolean,
			default: false,
			required: false
		},
		error: {
			type: String,
			default: '',
			required: false
		},
		errorMsg: {
			type: String,
			default: '',
			required: false
		}
	},
	emits: ['update:modelValue', 'removeError'],
	methods: {
		uppercaseFirst(string) {
			return upperFirst(string)
		},
		handleInput(value) {
			if (this.mask) return
			this.$emit('update:modelValue', value)
		}
	},
	computed: {
		maskedValue() {
			return this.mask
				? mask(this.modelValue ?? '', this.mask)
				: this.modelValue
		}
	},
	watch: {
		modelValue() {
			if (this.error) this.$emit('removeError')
		}
	}
}
</script>
