<template>
	<fieldset>
		<legend v-if="label" class="text-lg font-extrabold leading-6 text-kta-blue">
			{{ label }}
			<span v-if="error" class="ml-2 text-sm font-normal italic text-kta-red"
				>Required
			</span>
		</legend>
		<div
			v-if="description"
			:class="[{ 'font-bold': boldDescription }, 'text-extra-dark']"
			v-html="description"
		></div>
		<ul
			:class="[
				orientation === 'stacked' ? 'flex-col space-y-3' : 'space-x-3',
				'content-ul-none mt-3 flex'
			]"
		>
			<li
				v-for="(option, index) in options"
				:key="index"
				class="content-li-none"
			>
				<label class="flex w-fit items-center">
					<input
						type="checkbox"
						class="h-5 w-5 rounded text-cobalt focus:ring-cobalt"
						v-model="selected"
						:value="option.optionValue"
					/>
					<span
						class="ml-3 text-extra-dark"
						v-html="option.optionName ? option.optionName : option.optionValue"
					>
					</span>
				</label>
			</li>
		</ul>
	</fieldset>
</template>

<script>
export default {
	name: 'CheckInput',
	props: {
		modelValue: {
			type: [String, Array],
			required: true
		},
		label: {
			type: String,
			required: false
		},
		description: {
			type: String,
			required: false
		},
		boldLabel: {
			type: Boolean,
			default: true,
			required: false
		},
		boldDescription: {
			type: Boolean,
			default: false,
			required: false
		},
		options: {
			type: Array,
			required: true
		},
		orientation: {
			type: String,
			default: 'stacked',
			required: false
		},
		required: {
			type: Boolean,
			default: false,
			required: false
		},
		error: {
			type: String,
			default: '',
			required: false
		}
	},
	computed: {
		selected: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		}
	},
	emits: ['update:modelValue', 'removeError'],
	watch: {
		modelValue() {
			if (this.error) this.$emit('removeError')
		}
	}
}
</script>
