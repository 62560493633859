<template>
	<svg viewBox="0 0 36 10" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_2186_114)">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M31.3493 4.6001H36V4.60319L35.6634 6.1441C35.3886 7.31137 34.9161 8.18528 34.0854 8.86464C33.2701 9.544 32.2757 9.86206 30.988 9.86206C28.3692 9.86206 26.6646 8.35203 26.5102 5.90942C26.4113 4.31292 26.9209 2.73186 27.9029 1.60783C28.7583 0.650548 29.972 0.137939 31.4419 0.137939C32.9119 0.137939 34.1904 0.635108 34.9655 1.49666C35.5028 2.105 35.7839 2.78745 35.8734 3.78487H32.9335C32.9029 3.30413 32.8874 3.27276 32.7938 3.08259L32.7915 3.07772C32.5722 2.67319 32.0843 2.41071 31.5284 2.41071C30.8367 2.41071 30.2252 2.8245 29.8021 3.60268C29.4315 4.30983 29.2463 5.14359 29.2988 5.96191C29.3636 6.96243 29.9843 7.5862 30.9139 7.5862C31.9237 7.5862 32.4888 7.10447 32.742 6.32321H31.0405L31.3493 4.6001ZM22.0779 0.372559L17.0381 9.62421H20.0614L20.6327 8.40445H23.7085L23.8567 9.62421H26.9325L25.2186 0.372559H22.0779ZM21.5715 6.47445L23.1217 3.16102L23.5201 6.47445H21.5715ZM19.934 2.7998L21.2557 0.372635H14.1561H8.96181L5.50308 3.79105L6.15777 0.372635H1.67069L0 9.62429L4.38208 9.6212L5.10162 5.87854L7.59685 9.62429H13.1555L8.98034 5.07566L11.4848 2.7998H14.5977L13.3717 9.62429H16.4598L17.6858 2.7998H19.934Z"
				fill="#FFFFFF"
			/>
			<path
				d="M1.17646 3.09644L0.49707 6.8638L8.97713 5.07893L1.17646 3.09644Z"
				fill="#F4C14F"
			/>
		</g>
		<defs>
			<clipPath id="clip0_2186_114">
				<rect
					width="36.0001"
					height="9.72412"
					fill="white"
					transform="translate(0 0.137939)"
				/>
			</clipPath>
		</defs>
	</svg>
</template>

<script>
export default {
	name: 'KtagLogoWhite'
}
</script>
