<template>
	<Listbox v-model="selectedOption" class="flex-1">
		<div class="relative leading-none">
			<ListboxLabel
				:class="[{ 'sr-only': hideLabel }, 'mb-2 inline-block leading-none']"
				v-if="label"
				>{{ label }}</ListboxLabel
			>
			<ListboxButton
				:class="[
					error ? 'border-kta-red' : 'active:ring-1 active:ring-kta-blue',
					size === 'small' ? 'py-0' : 'py-2',
					'text-md relative w-full cursor-default rounded border border-medium bg-white pl-3 pr-10 text-left outline-none focus:border-sky focus:ring-1 focus:ring-kta-blue sm:text-base'
				]"
			>
				<span
					:title="
						hoverTitle ? selectedOption[optionDisplay] ?? selectedOption : null
					"
					:class="[
						{ 'text-dark': !selectedOption[optionDisplay] },
						'block truncate text-base'
					]"
					>{{ selectedOption[optionDisplay] ?? selectedOption }}
				</span>
				<span
					class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
				>
					<ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
				</span>
			</ListboxButton>
			<transition
				leave-active-class="transition duration-100 ease-in"
				leave-from-class="opacity-100"
				leave-to-class="opacity-0"
			>
				<ListboxOptions
					:class="[
						wideOptions ? 'whitespace-nowrap' : 'w-full',
						{ 'bottom-7': openUp },
						'absolute z-20 mt-1 max-h-60 overflow-auto  overscroll-contain rounded-md border border-medium bg-white py-1 ring-1 ring-black ring-opacity-5 scrollbar-hide focus:outline-none sm:text-sm'
					]"
				>
					<ListboxOption
						v-slot="{ active, selected }"
						v-for="(option, index) in options"
						:value="option"
						:key="index"
						as="template"
					>
						<li
							:class="[
								active ? 'bg-kta-blue text-white' : 'text-extra-dark',
								'content-li-none cursor-default select-none px-4 py-2'
							]"
						>
							<span
								:title="hoverTitle ? option[optionDisplay] ?? option : null"
								:class="[
									wordBreak ? 'break-words' : 'truncate',
									selected ? 'font-semibold' : 'font-normal',
									'block text-base'
								]"
								>{{ option[optionDisplay] ?? option }}</span
							>
						</li>
					</ListboxOption>
				</ListboxOptions>
			</transition>
			<div v-if="error" class="pt-1 text-xs italic text-kta-red">
				{{ uppercaseFirst(error) }}
			</div>
		</div>
	</Listbox>
</template>

<script>
import {
	Listbox,
	ListboxButton,
	ListboxLabel,
	ListboxOption,
	ListboxOptions
} from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/24/solid'
import upperFirst from 'lodash/upperFirst'
import { toRaw } from 'vue'
export default {
	name: 'SelectedInput',
	components: {
		Listbox,
		ListboxLabel,
		ListboxButton,
		ListboxOptions,
		ListboxOption,
		ChevronDownIcon
	},
	props: {
		modelValue: {
			required: true
		},
		label: {
			type: String,
			required: false
		},
		hideLabel: {
			type: Boolean,
			required: false,
			default: false
		},
		hoverTitle: {
			type: Boolean,
			required: false,
			default: false
		},
		openUp: {
			type: Boolean,
			default: false
		},
		options: {
			type: [Array, Object],
			required: true
		},
		optionValue: {
			type: String,
			default: '',
			required: false
		},
		optionDisplay: {
			type: String,
			default: '',
			required: false
		},
		defaultOption: {
			type: String,
			default: 'Select Option',
			required: false
		},
		size: {
			type: String,
			default: '',
			required: false
		},
		wideOptions: {
			type: Boolean,
			default: false,
			required: false
		},
		wordBreak: {
			type: Boolean,
			default: false,
			required: false
		},
		error: {
			type: String,
			default: '',
			required: false
		}
	},
	emits: ['update:modelValue', 'removeError'],
	mounted() {
		if (this.modelValue && !this.selected) this.updateSelected(this.modelValue)
	},
	data: () => ({
		selected: null
	}),
	methods: {
		uppercaseFirst(string) {
			return upperFirst(string)
		},
		updateSelected(value) {
			this.selected = this.options.find(
				(option) => toRaw(option[this.optionValue] ?? option) === toRaw(value)
			)
		}
	},
	computed: {
		selectedOption: {
			get() {
				return this.selected ? this.selected : this.defaultOption
			},
			set(value) {
				this.$emit('update:modelValue', value[this.optionValue] ?? value)

				// gets correct option if value[this.optionValue] is not unique value
				this.selected = value
			}
		}
	},
	watch: {
		modelValue(value) {
			if (this.error) this.$emit('removeError')
			this.updateSelected(value)
		}
	}
}
</script>
