<template>
	<div class="md:relative">
		<!-- Back -->
		<div
			:class="[
				[
					displayRates
						? 'rotate-y-0 z-10 opacity-100'
						: '-rotate-y-180 opacity-0'
				],
				'ease-elastic absolute top-28 grid min-h-[326px] w-72 grid-cols-1 rounded-md bg-extra-light shadow-xl motion-safe:transition-all motion-safe:duration-500 md:bottom-10 md:right-0 md:top-auto'
			]"
		>
			<div>
				<div class="flex justify-end px-2 pt-2 text-sm text-dark">
					<button
						type="button"
						class="focus-visible:kta-ring flex cursor-pointer items-center transition-colors hover:text-extra-dark"
						@click="resetSelects"
					>
						<span class="leading-3">Reset</span>
						<ArrowPathIcon class="not-sr-only ml-1 h-4 w-4" />
					</button>
					<button
						type="button"
						class="focus-visible:kta-ring ml-2 flex items-center transition-colors hover:text-extra-dark md:hidden"
						@click="$emit('close')"
					>
						<span>Close</span>
						<XMarkIcon class="not-sr-only block h-4 w-4 md:hidden" />
					</button>
				</div>
				<div class="grid grid-cols-2">
					<span
						class="col-span-2 inline-block p-4 text-lg font-bold uppercase leading-6 text-kta-blue"
					>
						TOLL RATES
					</span>
					<!-- old tolling rates -->
					<div class="border-r border-medium px-4 text-center font-bold">
						<span class="block whitespace-nowrap text-lg">License Plate</span>
						<span class="flex items-start justify-center text-3xl">
							<span class="text-xl">$</span>{{ ibr }}
						</span>
						<span class="block text-xs font-normal italic">(IBR)</span>
					</div>
					<div class="px-4 text-center font-bold">
						<span class="flex h-7 items-center justify-center">
							<KtagLogo class="ml-2 h-5" />
						</span>
						<span class="flex items-start justify-center text-3xl">
							<span class="text-xl">$</span>{{ tbr }}
						</span>
						<span class="block text-xs font-normal italic">(TBR)</span>
					</div>
				</div>
			</div>
			<div class="rounded-b-md bg-kta-blue p-4 text-white">
				<p class="mb-4 text-center text-lg font-bold">
					Save 50% with KTAG (TBR).
				</p>
				<LinkButton
					link="https://driveks.com/tolling/ktag"
					text="Let's Start"
					fullWidth
					buttonStyle="on-dark"
				/>
			</div>
		</div>
		<!-- Front -->
		<div
			:class="[
				[displayRates ? 'rotate-y-180  opacity-0' : 'rotate-y-0  opacity-100'],
				'ease-elastic absolute top-28 h-[326px] w-72 rounded-md bg-extra-light shadow-xl motion-safe:transition-all motion-safe:duration-500 md:bottom-10 md:right-0 md:top-auto'
			]"
		>
			<div class="flex justify-end px-2 pt-2 text-sm text-dark">
				<button
					type="button"
					class="focus-visible:kta-ring flex cursor-pointer items-center transition-colors hover:text-extra-dark"
					@click="resetSelects"
				>
					<span class="leading-3">Reset</span>
					<ArrowPathIcon class="not-sr-only ml-1 h-4 w-4" />
				</button>
				<button
					type="button"
					class="focus-visible:kta-ring ml-2 flex items-center transition-colors hover:text-extra-dark md:hidden"
					@click="$emit('close')"
				>
					<span>Close</span>
					<XMarkIcon class="not-sr-only block h-4 w-4 md:hidden" />
				</button>
			</div>
			<div class="p-4">
				<span
					class="mb-2 inline-block text-lg font-bold uppercase leading-6 text-kta-blue"
				>
					Calculate Your Toll
				</span>
				<div class="space-y-4">
					<div>
						<SelectInput
							openUp
							:options="classes"
							optionDisplay="name"
							defaultOption="Select Vehicle Class"
							v-model="vehicleClass"
						/>
						<div class="mt-1 text-right text-xs italic text-dark">
							<p>(number of axles, including any type of trailer)</p>
						</div>
					</div>
					<SelectInput
						openUp
						:options="entryPlazas"
						optionDisplay="name"
						defaultOption="Select Entry"
						v-model="entry"
						hoverTitle
					/>
					<SelectInput
						openUp
						:options="exitPlazas"
						optionDisplay="name"
						defaultOption="Select Exit"
						v-model="exit"
						hoverTitle
					/>
					<ActionButton
						@click="displayRates = true"
						text="Calculate"
						fullWidth
						:disabled="entry && exit && vehicleClass ? false : true"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ActionButton from '@/components/utils/ActionButton'
import LinkButton from '@/components/utils/LinkButton'
import { SelectInput } from '@/components/utils/form'
import { useMapStore } from '@/stores/mapStore'
import { ArrowPathIcon } from '@heroicons/vue/24/outline'
import { XMarkIcon } from '@heroicons/vue/24/solid'
import { mapStores } from 'pinia'
import { KtagLogo } from '../icons'

export default {
	name: 'MapTollCalculator',
	components: {
		XMarkIcon,
		ArrowPathIcon,
		ActionButton,
		LinkButton,
		SelectInput,
		KtagLogo
	},
	props: {
		entryPlaza: {
			type: Object,
			default: null
		},
		exitPlaza: {
			type: Object,
			default: null
		}
	},
	emits: ['close', 'entryChange', 'exitChange'],
	mounted() {
		// if (!this.rates.length) this.mapStore.fetchRates()
	},
	data() {
		return {
			displayRates: false,
			entry: null,
			exit: null,
			vehicleClass: null,
			classes: [
				{ axels: 2, name: 'Vehicle Class 2' },
				{ axels: 3, name: 'Vehicle Class 3' },
				{ axels: 4, name: 'Vehicle Class 4' },
				{ axels: 5, name: 'Vehicle Class 5' },
				{ axels: 6, name: 'Vehicle Class 6' },
				{ axels: 7, name: 'Vehicle Class 7' },
				{ axels: 8, name: 'Vehicle Class 8' },
				{ axels: 9, name: 'Vehicle Class 9' }
			]
		}
	},
	methods: {
		resetSelects() {
			this.entry = null
			this.exit = null
			this.vehicleClass = null
			this.displayRates = false
		}
	},
	computed: {
		...mapStores(useMapStore),
		plazas() {
			return this.mapStore.plazas
		},
		entryPlazas() {
			return this.plazas.length
				? this.plazas
						.filter((plaza) => plaza.name !== this.exit?.name)
						.filter((plaza) => {
							if (this.exit?.value === 182) return plaza.value < 182
							if (this.exit?.value === 183) return plaza.value > 183
							if (this.exit?.value < 183) return plaza.value !== 183
							if (this.exit?.value > 183) return plaza.value !== 182
							return plaza
						})
				: []
		},
		exitPlazas() {
			return this.plazas.length
				? this.plazas
						.filter((plaza) => plaza.name !== this.entry?.name)
						.filter((plaza) => {
							if (this.entry?.value === 182) return plaza.value < 182
							if (this.entry?.value === 183) return plaza.value > 183
							if (this.entry?.value < 183) return plaza.value !== 183
							if (this.entry?.value > 183) return plaza.value !== 182
							return plaza
						})
				: []
		},
		ctsRate() {
			if (this.entry && this.exit && this.vehicleClass) {
				return this.mapStore.ctsRates
					.filter(
						(zone) =>
							zone.class === this.vehicleClass.axels &&
							(this.entry.value > this.exit.value
								? zone.zoneCode >= this.exit.value &&
								  zone.zoneCode <= this.entry.value
								: zone.zoneCode >= this.entry.value &&
								  zone.zoneCode <= this.exit.value)
					)
					.filter(
						(zone) =>
							zone.zoneCode !== 183 ||
							this.entry.value === 183 ||
							this.exit.value === 183
					)
					.filter(
						(zone) =>
							zone.zoneCode !== 50 ||
							(this.entry.value !== 50 && this.exit.value !== 50) ||
							(this.entry.value >= 50 && this.exit.value >= 50)
					)
					.filter(
						(zone) =>
							zone.zoneCode !== 202 ||
							(this.entry.value !== 202 && this.exit.value !== 202) ||
							(this.entry.value >= 202 && this.exit.value >= 202)
					)
					.reduce(
						(obj, zone) => {
							obj.tbr += zone.transponderRate
							obj.tbr = Number.parseFloat(obj.tbr.toFixed(2))
							obj.ibr = Number.parseFloat((obj.tbr * 2).toFixed(2))
							return obj
						},
						{
							entry: this.entry.value,
							exit: this.exit.value,
							ibr: 0,
							tbr: 0
						}
					)
			}
			return false
		},
		ibr() {
			return this.ctsRate?.ibr?.toFixed(2) || '0.00'
		},
		tbr() {
			return this.ctsRate?.tbr?.toFixed(2) || '0.00'
		}
	},
	watch: {
		entryPlaza(newValue) {
			this.entry = newValue
		},
		exitPlaza(newValue) {
			this.exit = newValue
		},
		entry(newVal) {
			this.$emit('entryChange', newVal)
		},
		exit(newVal) {
			this.$emit('exitChange', newVal)
		}
	}
}
</script>
