<template>
	<div class="mb-5 w-full">
		<div
			class="mx-auto w-full overflow-hidden rounded-lg border border-light bg-white"
		>
			<Disclosure v-for="(item, index) in content" :key="index">
				<DisclosureButton
					:class="[
						{ 'border-b': panels[index]?.open },
						'focus-visible:kta-ring-inset -mt-px flex w-full items-center justify-between border-t border-light bg-extra-light px-4 py-2 text-left text-cobalt first:rounded-t'
					]"
					@click="displayPanel(index)"
					@keyup.enter="displayPanel(index)"
				>
					<span>{{ item.title }}</span>
					<ChevronRightIcon
						:class="[
							{ 'rotate-90 transform': panels[index]?.open },
							'h-5 w-5 flex-none text-kta-blue'
						]"
					/>
				</DisclosureButton>
				<div v-show="panels[index]?.open">
					<DisclosurePanel
						class="remove-last-mb p-4 text-extra-dark"
						v-html="item.body"
						static
					>
					</DisclosurePanel>
				</div>
			</Disclosure>
		</div>
	</div>
</template>

<script>
import { mapStores } from 'pinia'
import { useMainStore } from '@/stores/mainStore'
import { ChevronRightIcon } from '@heroicons/vue/24/solid'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
export default {
	name: 'Accordion',
	components: {
		ChevronRightIcon,
		Disclosure,
		DisclosureButton,
		DisclosurePanel
	},
	props: {
		id: {
			type: Number,
			required: true
		},
		multiExpand: {
			type: Boolean,
			default: false,
			required: false
		}
	},
	async created() {
		if (!this.accordion) await this.mainStore.fetchAccordion(this.id)
		for (let i = 0; i < this.content.length; i++) {
			this.panels.push({ open: false })
		}
	},
	data: () => ({
		panels: []
	}),
	methods: {
		displayPanel(index) {
			if (!this.multiExpand) {
				this.panels.forEach((panel, i) => {
					if (index !== i) panel.open = false
				})
			}
			this.panels[index].open = !this.panels[index].open
		}
	},
	computed: {
		...mapStores(useMainStore),
		accordion() {
			return this.mainStore.accordions.find(
				(accordion) => accordion.id === this.id
			)
		},
		content() {
			return this.accordion?.content
		}
	}
}
</script>
