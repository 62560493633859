<template>
	<ActionButton
		text="View Interactive Map"
		class="md:hidden"
		@click="displayMap = true"
		fullWidth
	/>
	<div
		:class="[
			{ 'hidden md:block': !displayMap },
			'fixed inset-0 z-10 flex h-screen w-full flex-col bg-cobalt md:relative md:h-[720px] md:bg-transparent'
		]"
	>
		<MapTabs
			@updateMarkerType="updateMarkerType($event)"
			:displayTabs="displayTabs"
			:hash="hash"
		/>
		<div class="relative w-full flex-1 md:h-full md:pb-10">
			<GoogleMap
				v-if="markerType"
				@displayMap="displayMap = $event"
				:markerType="markerType"
			/>
		</div>
	</div>
</template>

<script>
import GoogleMap from '@/components/map/GoogleMap'
import MapTabs from '@/components/map/MapTabs'
export default {
	name: 'KtaMap',
	components: {
		GoogleMap,
		MapTabs
	},
	props: {
		displayTabs: {
			type: Boolean,
			default: false,
			required: false
		},
		hash: {
			type: String,
			required: true
		}
	},
	data: () => ({
		displayMap: false,
		markerType: ''
	}),
	methods: {
		updateMarkerType(markerType) {
			this.markerType = markerType
		}
	}
}
</script>
