<template>
	<span>
		<money3
			aria-label="trip amount"
			:class="[
				{ 'border-kta-red': error },
				'block w-full rounded border border-medium focus:border-sky'
			]"
			v-model="value"
			v-bind="money"
		></money3>
		<span v-if="error" class="absolute pt-1 text-xs italic text-kta-red"
			>Required</span
		>
	</span>
</template>

<script>
import { Money3Component } from 'v-money3'
export default {
	name: 'MoneyInput',
	components: { money3: Money3Component },
	props: {
		modelValue: {
			required: true
		},
		error: {
			type: String,
			default: '',
			required: false
		}
	},
	emits: ['update:modelValue', 'removeError'],
	data: () => ({
		money: {
			decimal: '.',
			thousands: ',',
			prefix: '$',
			precision: 2,
			masked: false
		}
	}),
	computed: {
		value: {
			get() {
				return this.modelValue
			},
			set(value) {
				this.$emit('update:modelValue', value)
			}
		}
	},
	watch: {
		value() {
			if (this.error) this.$emit('removeError')
		}
	}
}
</script>
