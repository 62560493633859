<template>
	<svg
		class="h-6 w-6"
		fill="currentColor"
		viewBox="0 0 24 24"
		role="img"
		aria-labelledby="flickrTitle"
	>
		<title id="flickrTitle">Flickr icon</title>
		<circle cx="6" cy="12" r="4"></circle>
		<circle cx="18" cy="12" r="4"></circle>
	</svg>
</template>

<script>
export default {
	name: 'FlickrIcon'
}
</script>
