const PLAZA = 'plaza',
	SERVICE_AREA = 'service_area',
	CAMERA = 'camera',
	ENTRANCE = 'entrance',
	EXIT = 'exit',
	STAGING = 'staging',
	PARKING = 'parking'

export default [
	{
		group: [PLAZA, ENTRANCE, EXIT, CAMERA, PARKING],
		type: 'Toll Plaza',
		title: 'Southern Terminal',
		toll_id: 17,
		value: 17,
		milepost: 17,
		parking: 27,
		position: { lat: 37.238773, lng: -97.338719 }, // [37.05715495080929,-97.3383092880249
		tornadoShelter: 'Basement of Toll Building',
		camera: 'https://www.ksturnpike.com/camfeed/plaza004.jpg'
	},
	{
		group: [PLAZA, ENTRANCE, EXIT, PARKING],
		type: 'Toll Plaza',
		title: 'Wellington: US&#8209;160',
		toll_id: 19,
		value: 19,
		milepost: 19,
		parking: 7,
		position: { lat: 37.27834275536335, lng: -97.34019756317139 },
		tornadoShelter: 'Underground, West of Tollbooth'
	},
	{
		group: [SERVICE_AREA, PARKING],
		type: 'Service Area',
		title: 'Belle Plaine',
		milepost: 26,
		parking: 126,
		parkingComm: 50,
		position: { lat: 37.36600464552768, lng: -97.32255935668945 },
		tornadoShelter: 'Convenience Store storage room'
	},
	{
		group: [PLAZA, ENTRANCE, EXIT, PARKING],
		type: 'Toll Plaza',
		title: 'Mulvane: K&#8209;53',
		toll_id: 32,
		value: 32,
		milepost: 33,
		parking: 8,
		position: { lat: 37.474995, lng: -97.320641 },
		tornadoShelter: 'Underground, West of Tollbooth'
	},
	{
		group: [PLAZA],
		type: 'Toll Plaza',
		title: 'Mulvane (Casino Plaza)',
		toll_id: 33,
		value: 33,
		milepost: 33,
		position: { lat: 37.471419, lng: -97.327257 }
	},
	{
		group: [PLAZA, ENTRANCE, EXIT, PARKING],
		type: 'Toll Plaza',
		title: 'Haysville, Derby: 71st St.',
		toll_id: 39,
		value: 39,
		milepost: 39,
		parking: 10,
		position: { lat: 37.55965812785471, lng: -97.32609987258911 },
		tornadoShelter: 'Underground, East of Tollbooth'
	},
	{
		group: [PLAZA, ENTRANCE, EXIT, CAMERA],
		type: 'Toll Plaza',
		title: 'South Wichita: I&#8209;135, I&#8209;235, 47th St.',
		toll_id: 42,
		value: 42,
		milepost: 42,
		position: { lat: 37.60037721241316, lng: -97.32371807098389 },
		tornadoShelter: 'Underground, West of Tollbooth',
		camera: 'https://www.ksturnpike.com/camfeed/plaza042.jpg'
	},
	{
		group: [PLAZA, ENTRANCE, EXIT, PARKING],
		type: 'Toll Plaza',
		title: 'Wichita: K&#8209;15',
		toll_id: 45,
		value: 45,
		milepost: 45,
		parking: 4,
		position: { lat: 37.62708886619184, lng: -97.29127407073975 },
		tornadoShelter: 'Underground, West of Tollbooth'
	},
	{
		group: [PLAZA, ENTRANCE, EXIT, PARKING],
		type: 'Toll Plaza',
		title: 'East Wichita: US&#8209;54/400, Kellogg Ave.',
		toll_id: 50,
		value: 50,
		milepost: 50,
		parking: 6,
		position: { lat: 37.6750446072031, lng: -97.23032355308533 },
		parkingCommOutside: '25 outside of toll system at KTA headquarters'
		// tornadoShelter: 'Basement of toll office'
	},
	{
		group: [PLAZA, EXIT, PARKING],
		type: 'Toll Plaza',
		title: 'Wichita: US 54/400, Kellogg',
		toll_id: 53,
		value: 53,
		milepost: '53A',
		parking: 13,
		position: { lat: 37.6802382, lng: -97.1920807 },
		tornadoShelter: 'Underground, East of Tollbooth'
	},
	{
		group: [PLAZA, ENTRANCE, EXIT, PARKING],
		type: 'Toll Plaza',
		title: 'Wichita: K&#8209;96',
		toll_id: 53.1,
		value: 53,
		milepost: '53B',
		parking: 13,
		position: { lat: 37.68652882967398, lng: -97.18100309371948 },
		tornadoShelter: 'Underground, East of Tollbooth'
	},
	{
		group: [PLAZA, ENTRANCE, EXIT, CAMERA, PARKING],
		type: 'Toll Plaza',
		title: 'Andover: 21st Street',
		toll_id: 57,
		value: 57,
		milepost: 57,
		parking: 7,
		position: { lat: 37.720228255699475, lng: -97.11205959320068 },
		tornadoShelter: 'Underground, East of Tollbooth',
		camera: 'https://www.ksturnpike.com/camfeed/plaza057.jpg'
	},
	{
		group: [SERVICE_AREA, PARKING],
		type: 'Service Area',
		title: 'Towanda',
		milepost: 65,
		parking: 126,
		parkingComm: 40,
		position: { lat: 37.76628762653784, lng: -96.98138236999512 },
		tornadoShelter: 'Large restroom'
	},
	{
		group: [PLAZA, ENTRANCE, EXIT, PARKING],
		type: 'Toll Plaza',
		title: 'El Dorado: US&#8209;254',
		toll_id: 71,
		value: 71,
		milepost: 71,
		parking: 12,
		parkingCommOutside: 25,
		position: { lat: 37.820065013996775, lng: -96.90183877944946 },
		tornadoShelter: 'Underground, South of Toll Building'
	},
	{
		group: [PLAZA, ENTRANCE, EXIT, PARKING],
		type: 'Toll Plaza',
		title: 'El Dorado: US&#8209;77',
		toll_id: 76,
		value: 76,
		milepost: 76,
		parking: 9,
		position: { lat: 37.87631848923275, lng: -96.84568405151367 },
		tornadoShelter: 'Underground, South of Tollbooth'
	},
	{
		group: [PLAZA, ENTRANCE, EXIT, PARKING],
		type: 'Toll Plaza',
		title: 'Cassoday: K-177',
		toll_id: 92,
		value: 92,
		milepost: 92,
		parking: 5,
		position: { lat: 38.051149502125504, lng: -96.63415431976318 },
		tornadoShelter: 'Underground, Northeast of Tollbooth'
	},
	{
		group: [SERVICE_AREA, PARKING],
		type: 'Service Area',
		title: 'Matfield Green',
		milepost: 97,
		parking: 113,
		parkingComm: 60,
		position: { lat: 38.095229140713855, lng: -96.57967329025269 },
		tornadoShelter: 'Large Restrooms'
	},
	{
		group: [CAMERA],
		type: 'Camera',
		title: 'RPU 5',
		milepost: 103,
		position: { lat: 38.12124299416475, lng: -96.55911684036255 },
		camera: 'https://www.ksturnpike.com/camfeed/rpu5.jpg'
	},
	{
		group: [PLAZA, ENTRANCE, EXIT, CAMERA, PARKING],
		type: 'Toll Plaza',
		title: 'Emporia: I-35N',
		toll_id: 127,
		value: 127,
		milepost: 127,
		parking: 15,
		position: { lat: 38.41384527034067, lng: -96.2383246421814 },
		tornadoShelter: 'Basement of Toll Building',
		camera: 'https://www.ksturnpike.com/camfeed/plaza127.jpg'
	},
	{
		group: [SERVICE_AREA, PARKING],
		type: 'Service Area',
		title: 'Emporia',
		milepost: 132,
		parking: 114,
		parkingComm: 40,
		position: { lat: 38.47087770993531, lng: -96.18500232696533 },
		tornadoShelter: 'Convenience Store Restrooms'
	},
	{
		group: [PLAZA, ENTRANCE, EXIT, CAMERA, PARKING],
		type: 'Toll Plaza',
		title: 'Admire, Council Grove, Osage City: US&#8209;56',
		toll_id: 147,
		value: 147,
		milepost: 147,
		parking: 4,
		position: { lat: 38.64834109753419, lng: -96.03072166442871 },
		tornadoShelter: 'Underground, Northeast of Tollbooth',
		camera: 'https://www.ksturnpike.com/camfeed/plaza147.jpg'
	},
	{
		group: [PLAZA, ENTRANCE, EXIT, PARKING],
		type: 'Toll Plaza',
		title: 'South Topeka: I&#8209;470W, US&#8209;75, Topeka Blvd.',
		toll_id: 177,
		value: 177,
		milepost: 177,
		parking: 35,
		position: { lat: 38.99106208635852, lng: -95.69377183914185 },
		tornadoShelter: 'Above ground, behind Toll Building'
	},
	{
		group: [PLAZA, ENTRANCE, EXIT],
		type: 'Toll Plaza',
		title: 'Topeka: K-4',
		toll_id: 182,
		value: 182,
		milepost: 182,
		position: { lat: 39.029083, lng: -95.618923 },
		tornadoShelter: 'Underground, South of Tollbooth'
	},
	{
		group: [PLAZA, ENTRANCE, EXIT, CAMERA, PARKING],
		type: 'Toll Plaza',
		title: 'Topeka: I&#8209;70',
		toll_id: 183,
		value: 183,
		milepost: 183,
		position: { lat: 39.028721, lng: -95.617885 },
		parkingCommOutside:
			'40 outside of toll system off Rice Road/I-70 exit at 21st Street',
		tornadoShelter: 'Basement of Toll Building; WESTBOUND ACCESS ONLY',
		camera: 'https://www.ksturnpike.com/camfeed/plaza183.jpg'
	},
	{
		group: [SERVICE_AREA, PARKING],
		type: 'Service Area',
		title: 'Topeka',
		milepost: 188,
		parking: 117,
		parkingComm: 60,
		position: { lat: 39.019425, lng: -95.508571 },
		tornadoShelter: 'Basement'
	},
	{
		group: [PLAZA, ENTRANCE, EXIT, PARKING],
		type: 'Toll Plaza',
		title: 'Lecompton, Lawrence: K&#8209;10 (North Plaza)',
		toll_id: 197,
		value: 197,
		milepost: 197,
		parking: 30,
		parkingOther: '25 in adjacent gravel lot',
		position: { lat: 38.998042, lng: -95.342207 },
		tornadoShelter: 'Underground, South of Tollbooth'
	},
	// {
	// 	group: [PLAZA],
	// 	type: 'Toll Plaza',
	// 	title: 'Lecompton, Lawrence: K&#8209;10 (South Plaza)',
	// 	toll_id: 197.1,
	// 	value: 197,
	// 	milepost: 197,
	// 	position: { lat: 38.995086, lng: -95.345072 },
	// 	tornadoShelter: 'Underground, North of Tollbooth'
	// },
	{
		group: [PLAZA, ENTRANCE, EXIT, CAMERA, PARKING],
		type: 'Toll Plaza',
		title: 'West Lawrence: US&#8209;59S, Iowa St.',
		toll_id: 202,
		value: 202,
		milepost: 202,
		parking: 11,
		position: { lat: 38.989782049830154, lng: -95.2570116519928 },
		// tornadoShelter: 'Basement of Toll Building',
		camera: 'https://www.ksturnpike.com/camfeed/plaza202.jpg'
	},
	{
		group: [PLAZA, ENTRANCE, EXIT],
		type: 'Toll Plaza',
		title: 'East Lawrence: US&#8209;59, US&#8209;40',
		toll_id: 204,
		value: 204,
		milepost: 204,
		position: { lat: 38.992965420346316, lng: -95.23251235485077 }
	},
	{
		group: [SERVICE_AREA, PARKING],
		type: 'Service Area',
		title: 'Lawrence',
		milepost: 209,
		parking: 99,
		parkingComm: 60,
		position: { lat: 39.019246207342434, lng: -95.13682723045349 },
		tornadoShelter: 'Convenience Store storage room'
	},
	{
		group: [PLAZA, ENTRANCE, EXIT],
		type: 'Toll Plaza',
		title: 'Tonganoxie, Eudora: 222nd St.',
		toll_id: 212,
		value: 212,
		milepost: 212,
		position: { lat: 39.04387779020329, lng: -95.09347200393677 }
	},
	{
		group: [PLAZA, CAMERA],
		type: 'Toll Plaza',
		title: 'Eastern Terminal',
		toll_id: 217,
		value: 217,
		milepost: 217,
		position: { lat: 39.07026, lng: -95.007405 }, // [39.0931557917845,-94.64233249425888
		tornadoShelter: 'Basement of Toll Building; EASTBOUND ACCESS ONLY',
		camera: 'https://www.ksturnpike.com/camfeed/plaza236.jpg'
	},
	{
		group: [STAGING, PARKING],
		type: 'Commercial Staging Area',
		title: 'Commercial Staging Area (Eastbound)',
		milepost: 231, // 415.37
		parkingComm: 50,
		position: { lat: 39.10541, lng: -94.749654 }
	},
	{
		group: [STAGING, PARKING],
		type: 'Commercial Staging Area',
		title: 'Commercial Staging Area (Westbound)',
		milepost: 231, // 415.37
		parkingComm: 30,
		position: { lat: 39.106855, lng: 94.75056 }
	},
	{
		group: [ENTRANCE, EXIT],
		type: 'Eastern Entrance',
		title: 'Eastern Entrance',
		toll_id: 217,
		value: 217,
		milepost: 217, // exit 420?
		position: { lat: 39.0931557917845, lng: -94.64233249425888 }
	}
]
