const mapConfig = {
	panControl: false,
	scaleControl: false,
	streetViewControl: false,
	zoomControl: true,
	minZoom: 7,
	mapTypeControl: false,
	scrollwheel: false,
	styles: [
		{
			featureType: 'water',
			stylers: [{ visibility: 'off' }]
		},
		{
			featureType: 'poi',
			stylers: [{ visibility: 'off' }]
		},
		{
			featureType: 'transit',
			stylers: [{ visibility: 'off' }]
		},
		{
			featureType: 'administrative',
			elementType: 'geometry.fill',
			stylers: [
				{ visibility: 'off' } // administrative things
			]
		},
		{
			featureType: 'landscape',
			elementType: 'geometry',
			stylers: [
				{ color: '#e5e5e5' } // landscape background
			]
		},
		{
			featureType: 'road',
			elementType: 'geometry',
			stylers: [
				{ weight: 0.5 },
				{ color: '#ffffff' } // default for roads
			]
		},
		{
			featureType: 'road.arterial',
			elementType: 'geometry',
			stylers: [
				{ visibility: 'off' } // smaller roads
			]
		},
		{
			featureType: 'road.highway.controlled_access',
			elementType: 'geometry',
			stylers: [
				{ weight: 1 },
				{ color: '#ffffff' } // controlled access highways strokw
			]
		},
		{
			featureType: 'administrative.locality',
			elementType: 'labels.icon',
			stylers: [
				{ color: '#ffffff' } // city dots
			]
		},
		{
			featureType: 'administrative',
			elementType: 'labels.text.fill',
			stylers: [
				{ color: '#000000' } // city labels
			]
		},
		{
			featureType: 'administrative.province',
			elementType: 'labels',
			stylers: [
				{ visibility: 'off' } // state labels
			]
		},
		{
			featureType: 'administrative',
			elementType: 'labels.text.stroke',
			stylers: [{ color: '#e5e5e5' }, { weight: 4 }]
		},
		{
			featureType: 'road.highway',
			elementType: 'labels',
			stylers: [{ lightness: 50 }]
		},
		{
			featureType: 'administrative.province',
			elementType: 'geometry.stroke',
			stylers: [{ visibility: 'on' }, { weight: 1 }, { color: '#999999' }]
		}
	]
}

const LINE_PATH_CONFIG = {
	destination: { lat: 36.998863, lng: -97.342775 },
	origin: { lat: 39.0931557917845, lng: -94.64233249425888 }
}

const LINE_STYLE_CONFIG = {
	strokeColor: '#2e99d4',
	strokeOpacity: 1,
	strokeWeight: 3
}

export { mapConfig, LINE_PATH_CONFIG, LINE_STYLE_CONFIG }
