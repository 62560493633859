<template>
	<a
		:href="link"
		:class="[
			fullWidth ? 'block w-full' : 'inline-block',
			`rounded border-2 text-center font-bold tracking-wide ${buttonStyles} ${buttonSize}`
		]"
		:size="size"
		:disabled="disabled"
		target="_blank"
	>
		{{ text }}</a
	>
</template>

<script>
export default {
	name: 'LinkButton',
	props: {
		text: {
			type: String,
			required: true
		},
		link: {
			type: String,
			required: true
		},
		buttonStyle: {
			type: String,
			default: 'default',
			required: false
		},
		size: {
			type: String,
			default: 'medium',
			required: false
		},
		disabled: {
			type: Boolean,
			default: false,
			required: false
		},
		fullWidth: {
			type: Boolean,
			default: false,
			required: false
		}
	},
	computed: {
		buttonStyles() {
			if (this.buttonStyle === 'ghost') {
				return this.disabled
					? 'border-medium text-medium line-through'
					: 'border-cobalt text-cobalt hover:border-kta-blue hover:text-kta-blue focus-visible:ring-2 focus-visible:ring-sky'
			}
			if (this.buttonStyle === 'alert') {
				return this.disabled
					? 'border-medium bg-medium text-white line-through'
					: 'border-kta-red bg-kta-red text-white hover:border-kta-red-dark hover:bg-kta-red-dark focus-visible:ring-2 focus-visible:ring-[#e9a8ab]'
			}
			if (this.buttonStyle === 'alert-ghost') {
				return this.disabled
					? 'border-light text-light line-through'
					: 'border-kta-red text-kta-red hover:border-kta-red-dark hover:text-kta-red-dark focus-visible:ring-2 focus-visible:ring-[#e9a8ab]'
			}
			if (this.buttonStyle === 'text-only') {
				return this.disabled
					? 'border-transparent text-medium line-through'
					: 'border-transparent text-cobalt hover:text-kta-blue focus-visible:border-2 focus-visible:border-sky'
			}
			if (this.buttonStyle === 'on-dark') {
				return this.disabled
					? 'border-transparent bg-extra-dark text-medium line-through'
					: 'border-transparent bg-white text-cobalt hover:text-kta-blue focus-visible:kta-ring-inset'
			}
			if (this.buttonStyle === 'gold') {
				return `bg-gold hover:bg-kta-yellow text-kta-blue`
			}
			if (this.buttonStyle === 'white') {
				return `bg-white text-cobalt hover:text-kta-blue`
			}
			return this.disabled
				? 'border-medium bg-medium text-white line-through'
				: 'border-cobalt bg-cobalt text-white hover:border-kta-blue hover:bg-kta-blue focus-visible:kta-ring'
		},
		buttonSize() {
			if (this.size === 'small') {
				return 'text-xs py-2 px-3.5 leading-none'
			}
			if (this.size === 'large') {
				return 'text-base py-3.5 px-5 leading-none'
			}
			return 'text-sm py-2.5 px-4 leading-[18px]'
		}
	}
}
</script>
