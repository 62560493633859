<script>
import aIcon from '@/assets/images/aIcon.webp'
import bIcon from '@/assets/images/bIcon.webp'
import mapIcon from '@/assets/images/mapIcon.webp'
import Plazas from '@/constants/Plazas'
import { useMapStore } from '@/stores/mapStore'
import format from 'date-fns/format'
import { mapStores } from 'pinia'
export default {
	name: 'GoogleMapMarker',
	props: {
		map: {
			type: Object,
			required: true
		},
		markerType: {
			type: String,
			required: true
		},
		markers: {
			type: Array,
			required: true
		},
		entryPlaza: {
			type: Object,
			default: null
		},
		exitPlaza: {
			type: Object,
			default: null
		}
	},
	expose: ['closeInfoWindow'],
	emits: ['entryChange', 'exitChange'],
	activeMarkers: [],
	removedMarkers: [],
	data: () => ({
		currentInfoWindow: null,
		entry: null,
		exit: null
	}),
	async mounted() {
		await this.mapStore.fetchDms()
		await this.mapStore.fetchWeather()
		await this.mapStore.fetchServiceAreas()
		this.addMarkers()
	},
	methods: {
		async addMarkers() {
			const markers = await this.checkMarker(this.markerType)
			markers.forEach((marker) => {
				const googleMarker = new window.google.maps.Marker({
					map: this.map,
					position: marker.position,
					plaza: Plazas.find((plaza) => plaza.id === marker.toll_id) || null,
					icon: mapIcon,
					title:
						marker.name ||
						marker.title ||
						marker.location.name ||
						marker.location ||
						null
				})
				if (this.markerType === 'plaza') {
					googleMarker.addListener('click', () => {
						if (!this.entryPlaza) {
							this.entry = googleMarker.plaza
						} else if (
							!this.exitPlaza &&
							this.entryPlaza.name !== googleMarker.plaza.name
						) {
							this.exit = googleMarker.plaza
						}
					})
				} else {
					this.addInfoWindow(googleMarker, marker)
				}
				this.$options.activeMarkers.push(googleMarker)
			})
		},
		async checkMarker(markerType) {
			if (
				markerType === 'dms' ||
				markerType === 'weather' ||
				markerType === 'service_area'
			) {
				return this.interactiveMap[markerType]
			}
			return this.markers
		},
		addInfoWindow(googleMarker, marker) {
			const infoWindow = new window.google.maps.InfoWindow({
				content: this.buildInfoWindowContent(marker),
				ariaLabel:
					marker.name ||
					marker.title ||
					marker.location.name ||
					marker.location ||
					null
			})
			googleMarker.addListener('click', () => {
				this.currentInfoWindow = infoWindow
				infoWindow.open({
					anchor: googleMarker,
					map: this.map,
					shouldFocus: true
				})
			})
		},
		buildInfoWindowContent(marker) {
			let content = ''
			if (this.markerType === 'service_area') {
				const amenities = marker.amenities.reduce((string, curr) => {
					return `${string} <img class="inline-block h-9 first:mt-5" src="${curr.image.path}" alt="${curr.name}" />`
				}, '')

				content = `
				<div id="infoWindow">
					<div id="iw-image" class="hidden md:block">
						<img class="w-full h-full object-cover ${this.formatPosition(
							marker.imagePosition
						)}
						}" src="${marker.image.path}" alt="${marker.name}">
					</div>
						<div id="iw-content" class="p-6 text-sm text-extra-dark overflow-y-auto max-h-[400px]">
							<h5 class="mb-1 text-lg font-bold leading-none text-kta-blue">
								${marker.name}
							</h5>
							<p class="!mb-4">Service Area • Mile ${marker.mile}</p>
							<span
								class="mb-1 space-y-3 whitespace-pre-line [&>p]:mb-3"
							>${marker.content}</span>
							<span class="flex flex-wrap space-x-4 space-y-5">
								${amenities}
							</span>
						</div>
				</div>
				`
			} else if (this.markerType === 'camera') {
				content = `
					<div id="infoWindow" class="max-w-xl !h-72 sm:!h-60">
						<div class="!h-3/5">
							<img class="w-full h-full object-cover" src="${marker.camera}" alt="${marker.title} camera">
						</div>
						<div class="text-extra-dark text-sm text-left p-6 !h-2/5">
							<h5 class="mb-1 font-bold leading-none text-kta-blue text-lg">${marker.title}</h5>
							<p style="margin-bottom:1rem;">${marker.type} • Mile ${marker.milepost}</p>
					</div>`
			} else if (this.markerType === 'parking') {
				const passenger = marker.parking
					? `Passenger parking spaces: <span class="font-bold text-kta-blue">${marker.parking}</span>`
					: '<em>Passenger parking unavailable</em>'
				const commercial = marker.parkingComm
					? `Commercial parking spaces: <span class="font-bold text-kta-blue">${marker.parkingComm}</span>`
					: '<em>Commercial parking unavailable</em>'
				content = `
					<div id="infoWindow" class="flex max-w-xl sm:!h-40">
						<div class="text-extra-dark text-sm text-left p-6">
							<h5 class="mb-1 font-bold leading-none text-kta-blue text-lg">${marker.title}</h5>
							<p style="margin-bottom:1rem;">${marker.type} • Mile ${marker.milepost}</p>
							<span class="block" style="margin-bottom:0.5rem;">${passenger}</span>
							<span class="block">${commercial}</span>
					</div>`
			} else if (this.markerType === 'dms') {
				const message = marker.content.reduce((string, curr) => {
					return `${string} <p style="margin-bottom:0.3rem;"><em>${curr.join(
						' '
					)}</em></p>`
				}, '')
				content = `
					<div id="infoWindow" class="flex max-w-md">
						<div class="text-extra-dark text-sm text-left p-6">
							<h5 class="mb-1 font-bold leading-none text-kta-blue text-lg">${
								marker.direction || marker.location
							}</h5>
							<p style="margin-bottom:1rem;">Message Sign • Mile ${marker.milepost}</p>
							${message}
					</div>`
			} else if (this.markerType === 'weather') {
				const dateTime = format(new Date(marker.time), 'M/d/yy h:mm a')
				const precipitation = marker.precipitationType || 'None'
				content = `
					<div id="infoWindow" class="flex max-w-md">
						<div class="text-extra-dark text-sm text-left p-6">
							<h5 class="mb-1 font-bold leading-none text-kta-blue text-lg">${marker.temperature.degree}° • ${marker.location.name}</h5>
							<p style="margin-bottom:1rem;">${dateTime} • Mile ${marker.location.milepost}</p>
							<p style="margin-bottom:0.3rem;">Wind: ${marker.wind.direction} @ ${marker.wind.speed} Mph</p>
							<p style="margin-bottom:0;">Precipitation: ${precipitation}</p>
					</div>`
			}
			return content
		},
		closeInfoWindow() {
			if (this.currentInfoWindow) {
				this.currentInfoWindow.close()
				this.currentInfoWindow = null
			}
		},
		formatPosition(position) {
			switch (position) {
				case 'top':
					return 'object-top'
				case 'center':
					return 'object-center'
				case 'bottom':
					return 'object-bottom'
				default:
					return 'object-center'
			}
		},
		resetMarkers() {
			this.$options.activeMarkers.forEach((marker) => marker.setMap(null))
			this.$options.activeMarkers = []
			this.resetPlazas()
		},
		resetPlazas() {
			this.entry = null
			this.exit = null
			this.$options.activeMarkers.forEach((marker) => marker.setIcon(null))
		},
		hideMarkers(value) {
			// prevent hiding markers if a user has selected a 2nd marker
			if (value && this.entry && this.exit) {
				return
			}

			this.$options.activeMarkers.forEach((marker) => {
				if (
					value &&
					((value === 182 && marker.plaza.value > 182) ||
						(value === 183 && marker.plaza.value < 183) ||
						(value < 183 && marker.plaza.value === 183) ||
						(value > 183 && marker.plaza.value === 182))
				) {
					marker.setMap(null)
				} else {
					marker.setMap(this.map)
				}
			})
		}
	},
	computed: {
		...mapStores(useMapStore),
		interactiveMap() {
			return this.mapStore.interactiveMap
		}
	},
	watch: {
		currentInfoWindow(newInfoWindow, oldInfoWindow) {
			if (oldInfoWindow) oldInfoWindow.close()
		},
		entryPlaza(newEntry, oldEntry) {
			this.$options.activeMarkers.forEach((marker) => {
				if (marker.plaza && marker.plaza.name === newEntry?.name) {
					marker.setIcon(aIcon)
					marker.setZIndex(1000)
				}
				if (
					!newEntry ||
					(marker.plaza && marker.plaza.name === oldEntry?.name)
				) {
					marker.setIcon(mapIcon)
					marker.setZIndex(null)
				}
			})
			this.entry = newEntry
			this.hideMarkers(this.entry?.value)
		},
		exitPlaza(newExit, oldExit) {
			this.$options.activeMarkers.forEach((marker) => {
				if (marker.plaza && marker.plaza.name === newExit?.name) {
					marker.setIcon(bIcon)
					marker.setZIndex(1001)
				}
				if (!newExit || (marker.plaza && marker.plaza.name === oldExit?.name)) {
					marker.setIcon(mapIcon)
					marker.setZIndex(null)
				}
			})
			this.exit = newExit
			this.hideMarkers(this.exit?.value)
		},
		entry(newVal) {
			this.$emit('entryChange', newVal)
		},
		exit(newVal) {
			this.$emit('exitChange', newVal)
		},
		markers: {
			handler() {
				this.resetMarkers()
				this.addMarkers()
			},
			deep: true
		}
	},
	render: () => null
}
</script>
