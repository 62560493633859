<template>
	<GoogleMapLoader
		ref="googleMap"
		:apiKey="mapApi"
		:mapConfig="mapConfig"
		@closeInfoWindow="closeInfoWindow()"
	>
		<template v-slot="{ map }">
			<GoogleMapMarker
				ref="googleMarker"
				:map="map"
				:markerType="markerType"
				:markers="markers"
				:entryPlaza="entryPlaza"
				:exitPlaza="exitPlaza"
				@entryChange="entryPlaza = $event"
				@exitChange="exitPlaza = $event"
			/>
			<GoogleMapLine :map="map" />
		</template>
	</GoogleMapLoader>
	<div
		:class="[
			{ 'flex-col': displayCalculator },
			'absolute left-3 top-0 flex flex-col items-start md:bottom-4 md:left-auto md:right-4 md:flex-row md:items-end'
		]"
	>
		<MapTollCalculator
			:class="[displayCalculator ? 'fixed md:block' : 'hidden md:block']"
			v-if="markerType === 'plaza'"
			:entryPlaza="entryPlaza"
			:exitPlaza="exitPlaza"
			@entryChange="entryPlaza = $event"
			@exitChange="exitPlaza = $event"
			@close="displayCalculator = false"
		/>
		<!-- :class="[displayCalculator ? 'hidden' : 'mr-2 block md:hidden']" -->
		<div class="mr-2 block md:hidden" v-if="markerType === 'plaza'">
			<ActionButton
				@click="toggleCalculator"
				text="Calculate Your Toll"
				size="small"
			/>
		</div>
		<!-- Close Map Button -->
		<ActionButton
			@click="$emit('displayMap', false)"
			size="small"
			class="flex items-center md:hidden"
			text="Close Map"
		/>
	</div>
</template>

<script>
import { mapConfig } from '@/components/config/MapSettings'
import Pins from '@/components/config/Pins'
import GoogleMapLine from './GoogleMapLine'
import GoogleMapLoader from './GoogleMapLoader'
import GoogleMapMarker from './GoogleMapMarker'
import MapTollCalculator from './MapTollCalculator'
export default {
	name: 'GoogleMap',
	components: {
		GoogleMapLoader,
		GoogleMapMarker,
		GoogleMapLine,
		MapTollCalculator
	},
	props: {
		markerType: {
			type: String,
			required: false
		}
	},
	emits: ['displayMap'],
	data: () => ({
		displayCalculator: false,
		activeMarkers: [],
		entryPlaza: null,
		exitPlaza: null
	}),
	methods: {
		closeInfoWindow() {
			this.$refs.googleMarker.closeInfoWindow()
		},
		resetZoom() {
			this.$refs.googleMap.resetZoom()
		},
		toggleCalculator() {
			this.displayCalculator = !this.displayCalculator
		}
	},
	computed: {
		mapApi() {
			return process.env.VUE_APP_GOOGLEMAPS_KEY
		},
		mapConfig() {
			return mapConfig
		},
		markers() {
			return Pins.filter((pin) => pin.group.includes(this.markerType))
		}
	},
	watch: {
		markerType() {
			this.resetZoom()
		}
	}
}
</script>

<style>
.gm-style-iw {
	font: Arial, Helvetica, sans-serif !important;
	font-weight: 400 !important;
	max-height: 430px !important;
}
.gm-style-iw-c {
	max-width: 315px !important;
	padding: 0 !important;
}
.gm-style-iw-d {
	overflow: hidden !important;
	max-height: 600px !important;
}
.gm-style-iw-chr {
	position: absolute !important;
	top: -8px !important;
	right: -8px !important;
}

@media (min-width: 640px) {
	.gm-style-iw {
		max-height: 600px !important;
	}
	.gm-style-iw-c {
		max-width: 470px !important;
	}
	#iw-image {
		height: 200px !important;
	}
	#iw-content {
		height: 50% !important;
	}
}
</style>
