<template>
	<label :class="`w-full text-dark ${span}`">
		<span v-if="props.label" class="mb-2 leading-none">
			{{ props.required ? `${props.label} *` : props.label }}
		</span>
		<input
			:class="[
				{ 'border-kta-red': error },
				'block w-full rounded border border-medium focus:border-sky'
			]"
			:aria-label="props.label"
			type="text"
			v-model="value"
		/>
		<span v-if="error" class="absolute pt-1 text-xs italic text-kta-red"
			>Required</span
		>
	</label>
</template>

<script>
import { mapStores } from 'pinia'
import { useFormStore } from '@/stores/formStore'
export default {
	name: 'TextInput',
	props: {
		props: {
			type: Object,
			required: false
		},
		mappedContent: {
			type: Array,
			required: true
		}
	},
	emits: ['updateValue'],
	computed: {
		...mapStores(useFormStore),
		index() {
			return this.mappedContent.findIndex(
				(obj) => obj.label === this.props.label
			)
		},
		span() {
			return this.formStore.span[this.props.width]
		},
		value: {
			get() {
				return this.mappedContent[this.index].value
			},
			set(value) {
				this.$emit('updateValue', { index: this.index, value })
			}
		},
		error() {
			return this.mappedContent[this.index].error
		}
	},
	watch: {
		value(value) {
			if (value && this.error) {
				this.error = false
			}
		}
	}
}
</script>
