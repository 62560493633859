<template>
	<svg viewBox="0 0 601 163" fill="none" role="img" aria-labelledby="txTitle">
		<title id="txTitle">Tx tag logo</title>
		<path
			d="M0.600109 0.900024H193.3V58.2C130.3 58.2 36.6001 61.2 0.600109 117.4C0.500109 117.6 0.600109 0.900024 0.600109 0.900024Z"
			fill="#006699"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M193.3 60.6C146.1 60.6 60.1001 72.1 60.1001 93.4C60.1001 114.8 170 120.7 193.3 120.7V60.6Z"
			fill="#E4002C"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M30.3001 27L35.6001 10.2L41.0001 27H58.3001L44.3001 37.4L49.7001 54.3L35.7001 43.7L21.7001 54.3L27.1001 37.4L13.1001 27H30.3001ZM185 4.29999C182.8 4.29999 181 6.09999 181 8.29999C181 10.6 182.8 12.3 185 12.3C187.2 12.3 188.9 10.5 188.9 8.29999C189 6.09999 187.2 4.29999 185 4.29999ZM185.1 4.89998C186.9 4.89998 188.3 6.39999 188.3 8.29999C188.3 10.2 186.9 11.7 185.1 11.7C183.3 11.7 181.9 10.2 181.9 8.29999C181.8 6.39999 183.3 4.89998 185.1 4.89998ZM184.3 8.69998H184.8C185.4 8.69998 185.7 8.89998 185.8 9.49998C185.9 10.1 186 10.5 186.1 10.6H186.8C186.7 10.5 186.6 10.2 186.5 9.49998C186.4 8.79998 186.1 8.49998 185.8 8.39998C186.3 8.29998 186.7 7.89999 186.7 7.29999C186.7 6.89999 186.6 6.59998 186.3 6.39998C186 6.19998 185.6 6.09999 184.9 6.09999C184.4 6.09999 184 6.19998 183.7 6.19998V10.7H184.4V8.69998H184.3ZM184.3 6.69998C184.4 6.69998 184.6 6.69998 184.8 6.69998C185.6 6.69998 185.9 7.09998 185.9 7.49998C185.9 7.99998 185.4 8.29999 184.8 8.29999H184.3V6.69998Z"
			fill="white"
		/>
		<path
			d="M326.2 0.900024V22H285.7V120.9H258.5V22.2H217.6V0.900024H326.2V0.900024ZM299.5 42.9L328.5 80.4L298.4 120.9H325.6L341.6 98L358.5 120.9H385.5L355.2 80.4L384 42.9H355.7L341.5 62.9L326.5 42.9H299.5V42.9ZM368.5 0.900024V22.2H409.4V120.9H436.6V22H477.1V0.900024H368.5ZM484.2 41.6C474.6 41.6 464.7 44.1 456.8 48.4V67.8C462 61.2 471.9 56.7 480.8 56.7C489.2 56.7 493.8 61 493.8 69.1V71.1C492.9 71.7 492.4 72 492 72.2C490.6 72.9 489.1 73.5 487.3 74C486.2 74.4 483 75.4 477.4 77.1C458.8 82.7 450 91 450 102.7C450 114.2 459.4 122 473.1 122C479.6 122 486.6 119.7 494 114.6C496.5 119.8 500.3 122 505.9 122C509.9 122 515.4 120.6 520.1 118.1L527.7 114V100.3C525 102.5 522.1 103.9 520.1 103.9C517.4 103.9 516.7 101.7 516.7 93.8V66.2C516.6 51 504.1 41.6 484.2 41.6ZM473.7 98.9C473.7 92.3 481.8 86.3 493.7 84.3V100.1C489 105 485.1 107.1 481.3 107.1C476.9 107.2 473.7 103.4 473.7 98.9ZM600.5 42.9H563.8C540.3 42.9 526.5 53.7 526.5 71.7C526.5 83.6 533.3 92.2 546.1 97.1C536.9 99.6 532.1 104.1 532.1 110.4C532.1 114.5 534.1 117.6 538.9 120.8C527.4 124.6 522.1 130.2 522.1 139.2C522.1 152.9 536.7 162.3 558.3 162.3C582.4 162.3 598.1 151.9 598.1 135.8C598.1 123.2 588 115.3 569.1 112.7L561.7 111.6C555.4 110.7 553 109.3 553 106.4C553 103.7 555.4 102.1 560.9 101.2C570.1 99.7 572.6 99 577.5 97C587.9 92.7 594.3 84 594.3 73.9C594.3 68.3 593.2 65.1 589.3 58.4H600.5V42.9V42.9ZM542.2 138.5C542.2 133.3 548.5 130.6 560.6 130.6C571.8 130.6 577.5 133.5 577.5 139.1C577.5 144.1 570.8 147.4 559.8 147.4C548.8 147.3 542.2 144.1 542.2 138.5ZM560.2 57.8C567.4 57.8 572.8 63.8 572.8 71.5C572.8 79.6 567.8 85.5 560.7 85.5C553.3 85.5 548.1 79.7 548.1 71.6C548.1 63.6 553.1 57.8 560.2 57.8Z"
			fill="#006699"
		/>
	</svg>
</template>

<script>
export default {
	name: 'TxTagLogo'
}
</script>
