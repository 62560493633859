<template>
	<article
		:class="[
			'animate-translate lg:hover:animate-flip-front lg:hover:animate-flip-back grid grid-cols-1'
		]"
		aria-roledescription="Flip card"
	>
		<div
			ref="frontCard"
			:class="[
				[
					!cardFlipped
						? 'rotate-y-0 visible z-10 opacity-100 '
						: 'rotate-y-180 invisible z-0 opacity-0'
				],
				'flicker-fix focus-visible:kta-ring ease-elastic col-start-1 row-start-1 motion-safe:transition-all motion-safe:duration-500'
			]"
			tabindex="0"
			@click="flipCard(true)"
			@keypress.self.prevent="handleEvent"
		>
			<slot name="front"></slot>
		</div>
		<div
			ref="backCard"
			:class="[
				[
					cardFlipped
						? 'rotate-y-0 visible z-10 opacity-100'
						: '-rotate-y-180 invisible z-0 opacity-0'
				],
				'flicker-fix focus-visible:kta-ring ease-elastic col-start-1 row-start-1 motion-safe:transition-all motion-safe:duration-500'
			]"
			tabindex="0"
			@focusin="flipCard(true)"
			@focusout="flipCard(false)"
			@click="flipCard(false)"
			@keypress.self.prevent="handleEvent"
		>
			<slot name="back"></slot>
		</div>
	</article>
</template>

<script>
export default {
	name: 'FlipCard',
	data: () => ({
		cardFlipped: false
	}),
	methods: {
		flipCard(bool) {
			this.cardFlipped = bool
		},
		handleEvent(e) {
			if (e.keyCode === 32 || e.keyCode === 13)
				this.cardFlipped = !this.cardFlipped

			setTimeout(() => {
				this.cardFlipped
					? this.$refs.backCard.focus()
					: this.$refs.frontCard.focus()
			}, 100)
		}
	}
}
</script>

<style scoped>
.animate-translate {
	-webkit-transform: translate3d(0, 0, 0);
}
.flicker-fix {
	-webkit-backface-visibility: hidden;
}
</style>
