<template>
	<label class="text-dark" :style="`width: ${width}%`">
		<div v-if="label" class="mb-2 leading-none">
			{{ required ? `${label} *` : label }}
		</div>
		<textarea
			:class="[
				{ 'border-kta-red': error },
				'block w-full rounded-md border border-medium focus:border-sky'
			]"
			:aria-label="label"
			:rows="rows"
			:value="modelValue"
			@input="$emit('update:modelValue', $event.target.value)"
		></textarea>
		<span v-if="error" class="absolute pt-1 text-xs italic text-kta-red">{{
			uppercaseFirst(error)
		}}</span>
	</label>
</template>

<script>
import upperFirst from 'lodash/upperFirst'
export default {
	name: 'TextArea',
	props: {
		modelValue: {
			type: String,
			required: true,
			default: ''
		},
		label: {
			type: String,
			required: true
		},
		width: {
			type: [String, Number],
			default: 100,
			required: false
		},
		rows: {
			type: [String, Number],
			default: 3,
			required: false
		},
		required: {
			type: Boolean,
			default: false,
			required: false
		},
		error: {
			type: String,
			default: '',
			required: false
		}
	},
	emits: ['update:modelValue', 'removeError'],
	methods: {
		uppercaseFirst(string) {
			return upperFirst(string)
		}
	},
	watch: {
		modelValue() {
			if (this.error) this.$emit('removeError')
		}
	}
}
</script>
