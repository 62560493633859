export default [
	{
		id: 17,
		value: 17,
		lane: [],
		name: '17 - Southern Terminal'
	},
	{
		id: 19,
		value: 19,
		lane: [],
		name: '19 - Wellington: US-160'
	},
	{
		id: 32,
		value: 32,
		lane: [],
		name: '32 - Mulvane: K-53'
	},
	{
		id: 33,
		value: 33,
		lane: [],
		name: '33 - Mulvane: K-53'
	},
	{
		id: 39,
		value: 39,
		lane: [],
		name: '39 - Haysville, Derby: 71st St.'
	},
	{
		id: 42,
		value: 42,
		lane: [],
		name: '42 - South Wichita: I-135, I-235, 47th St.'
	},
	{
		id: 45,
		value: 45,
		lane: [],
		name: '45 - Wichita: K-15'
	},
	{
		id: 50,
		value: 50,
		lane: [],
		name: '50 - Wichita: Webb Rd.'
	},
	{
		id: 53,
		value: 53,
		lane: [25, 26],
		name: '53 - Wichita: US 54/400, Kellogg'
	},
	{
		id: 53.1,
		value: 53,
		lane: [],
		name: '53 - Wichita: K-96'
	},
	{
		id: 57,
		value: 57,
		lane: [],
		name: '57 - Andover: 21st Street'
	},
	{
		id: 71,
		value: 71,
		lane: [],
		name: '71 - EL Dorado: US-254'
	},
	{
		id: 76,
		value: 76,
		lane: [],
		name: '76 - El Dorado: US-77'
	},
	{
		id: 92,
		value: 92,
		lane: [],
		name: '92 - Cassoday: K-177'
	},
	{
		id: 127,
		value: 127,
		lane: [],
		name: '127 - Emporia: I-35N'
	},
	{
		id: 147,
		value: 147,
		lane: [],
		name: '147 - Admire, Council Grove, Osage City: US-56'
	},
	{
		id: 177,
		value: 177,
		lane: [],
		name: '177 - South Topeka: I-470W, US-75, Topeka Blvd.'
	},
	{
		id: 182,
		value: 182,
		lane: [],
		name: '182 - Topeka: K-4'
	},
	{
		id: 183,
		value: 183,
		lane: [],
		name: '183 - Topeka: I-70'
	},
	{
		id: 197,
		value: 197,
		lane: [],
		name: '197 - Lecompton, Lawrence: K-10'
	},
	{
		id: 202,
		value: 202,
		lane: [],
		name: '202 - West Lawrence: US-59S, Iowa St.'
	},
	{
		id: 204,
		value: 204,
		lane: [],
		name: '204 - East Lawrence: US-59, US-40'
	},
	{
		id: 212,
		value: 212,
		lane: [],
		name: '212 - Tonganoxie, Eudora: 222nd St.'
	},
	{
		id: 217,
		value: 217,
		lane: [],
		name: '217 - Eastern Entrance'
	}
]
