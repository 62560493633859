<template>
	<a
		v-if="isExternalLink"
		:href="to"
		target="_blank"
		:class="[
			button
				? `rounded px-4 py-2.5 text-sm font-bold leading-4 tracking-wide transition-all duration-150 ease-in-out ${buttonStyle} ${buttonSize}`
				: '',
			uppercase ? 'uppercase' : '',
			shadow
				? 'shadow-inset motion-safe:hover:-translate-y-0.5 motion-safe:hover:shadow-inset-wide'
				: '',
			fullWidth ? 'block text-center' : 'inline-block',
			'focus-visible:kta-ring'
		]"
	>
		<slot :isActive="isActive" />
	</a>
	<router-link
		v-else
		:to="typeof to === 'string' ? `/${to}` : to"
		:class="[
			button
				? `rounded px-4 py-2.5 text-sm font-bold leading-4 tracking-wide transition-all duration-150 ease-in-out ${buttonStyle} ${buttonSize}`
				: '',
			uppercase ? 'uppercase' : '',
			shadow
				? 'shadow-inset motion-safe:hover:-translate-y-0.5 motion-safe:hover:shadow-inset-wide'
				: '',
			fullWidth ? 'block text-center' : 'inline-block',
			'focus-visible:kta-ring'
		]"
	>
		<slot :isActive="isActive" />
	</router-link>
</template>

<script>
export default {
	name: 'NavLink',
	props: {
		to: {
			type: [String, Object],
			default: '#',
			required: false
		},
		button: {
			type: [Boolean, String],
			default: false,
			required: false
		},
		size: {
			type: String,
			default: 'default',
			required: false
		},
		fullWidth: {
			type: Boolean,
			default: false,
			required: false
		},
		shadow: {
			type: Boolean,
			default: false,
			required: false
		},
		uppercase: {
			type: Boolean,
			default: false,
			required: false
		}
	},
	computed: {
		buttonStyle() {
			if (this.button === 'gold') {
				return `bg-gold hover:bg-kta-yellow text-kta-blue`
			}
			if (this.button === 'white') {
				return `bg-white text-cobalt hover:text-kta-blue`
			}
			return this.button ? `bg-cobalt hover:bg-kta-blue text-white` : ''
		},
		buttonSize() {
			if (this.size === 'small') {
				return 'text-xs py-2 px-3.5 leading-none'
			}
			if (this.size === 'large') {
				return 'text-base py-3.5 px-5 leading-none'
			}
			return ''
		},
		isExternalLink() {
			return typeof this.to === 'string' && this.to.startsWith('http')
		},
		isActive() {
			return `/${this.to}` === this.$route.fullPath
		}
	}
}
</script>
