<template>
	<div class="mb-5">
		<div :class="`grid grid-cols-1 ${pageColumns} gap-x-5 gap-y-12`">
			<article
				:class="['flex items-stretch', { 'content-tables': styleTables }]"
				v-for="post in pages[currentPage]"
				:key="post"
			>
				<BasicCard
					:title="post.title"
					:body="post.summary ? post.summary : post.body"
					:link="`/${post.slug}`"
					:published="post.published"
					:linkText="post.summary ? 'READ MORE' : ''"
					shadowStyle="wide"
				/>
			</article>
		</div>
		<Pagination
			v-if="pagination && pages.length"
			@pageChange="goToPage($event)"
			:pageCount="pages.length"
		/>
	</div>
</template>

<script>
import { mapStores } from 'pinia'
import { useMainStore } from '@/stores/mainStore'
import chunk from 'lodash/chunk'
import { format, isValid } from 'date-fns'
import BasicCard from '@/components/cards/BasicCard'
import Pagination from '@/components/utils/Pagination'
export default {
	name: 'Posts',
	components: {
		BasicCard,
		Pagination
	},
	props: {
		categories: {
			type: String,
			required: true
		},
		columns: {
			type: [Number, String],
			default: '2',
			required: false
		},
		postsPerPage: {
			type: [Number, String],
			default: '4',
			required: false
		},
		pagination: {
			type: Boolean,
			default: false,
			required: false
		}
	},
	async created() {
		this.posts = await this.mainStore.fetchPosts(this.categories)
	},
	data: () => ({
		currentPage: 0,
		posts: []
	}),
	methods: {
		goToPage(index) {
			this.currentPage = index
		},
		formatDate(date) {
			if (isValid(new Date(date))) return format(new Date(date), 'MMM do, yyyy')
		}
	},
	computed: {
		...mapStores(useMainStore),
		styleTables() {
			return this.categories !== 'Turnpike Times'
		},
		pages() {
			return chunk(this.posts, this.postsPerPage)
		},
		pageColumns() {
			switch (parseInt(this.columns)) {
				case 2:
					return 'sm:grid-cols-2'
				case 3:
					return 'sm:grid-cols-3'
				case 4:
					return 'sm:grid-cols-2 md:grid-cols-4'
				case 5:
					return 'sm:grid-cols-2 md:grid-cols-5'
				case 6:
					return 'sm:grid-cols-2 md:grid-cols-6'
				case 7:
					return 'sm:grid-cols-2 md:grid-cols-7'
			}
			return `sm:grid-cols-1`
		}
	}
}
</script>
