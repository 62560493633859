<template>
	<footer class="bg-kta-blue text-white">
		<div
			class="mx-auto grid w-full grid-cols-2 justify-center px-6 py-6 sm:grid-cols-3 sm:px-8 lg:max-w-5xl lg:grid-cols-4 lg:gap-y-5 xl:max-w-7xl xl:px-20"
		>
			<div
				class="hidden font-bold sm:col-span-2 lg:order-1 lg:block lg:items-center lg:border-b-2 lg:border-b-cobalt lg:pb-5"
				aria-hidden="true"
			>
				<Kta1cHorzLogo class="h-10" />
			</div>
			<div
				class="order-1 col-span-2 mb-8 text-center sm:order-2 sm:col-auto sm:mb-0 lg:order-2 lg:col-span-2 lg:flex lg:items-center lg:justify-end lg:border-b-2 lg:border-b-cobalt lg:pb-5 lg:text-right"
			>
				<a
					class="focus-visible:kta-ring whitespace-nowrap sm:flex sm:items-center"
					href="https://www.myktag.com/order-tags"
					target="_blank"
				>
					<span class="text-lg uppercase">Get Your</span>
					<span>
						<KtagLogoWhite class="ml-2 inline-block h-7" />
					</span>
					<span>
						<ChevronRightIcon
							class="mb-1 inline-block h-7 motion-safe:animate-rotateX"
						/>
					</span>
				</a>
			</div>
			<div
				class="sm:leading-auto order-3 col-span-2 block whitespace-nowrap text-center leading-6 sm:col-auto sm:text-left lg:order-3 lg:col-span-2 lg:grid lg:grid-cols-2 lg:items-end lg:gap-x-8 xl:grid-cols-3 xl:gap-x-20"
			>
				<div
					class="block text-xl font-bold uppercase tracking-wide sm:text-base md:text-xl"
				>
					Emergency Line
				</div>
				<span class="block sm:text-sm md:text-base xl:col-span-2"
					>Call *KTA (*582)</span
				>
				<span
					class="mt-4 block text-xl font-bold uppercase tracking-wide sm:text-base md:text-xl lg:mt-0"
				>
					Road Conditions
				</span>
				<span class="block sm:text-sm md:text-base xl:col-span-2"
					>Call 511 <span class="text-sky">|</span> KanDrive.org</span
				>
			</div>
			<nav
				class="hidden justify-end space-x-6 sm:order-4 sm:col-span-2 sm:flex lg:row-span-2"
				aria-label="Footer"
			>
				<div
					v-for="(sections, index) in secondaryMenu"
					:key="index"
					class="flex flex-col"
				>
					<h3 class="not-sr-only font-bold uppercase text-sky">
						{{ sections.name }}
					</h3>
					<nav-link
						v-for="(item, index) in sections.items"
						:key="index"
						class="focus-visible:kta-ring"
						:to="item.url"
					>
						{{ item.name }}
					</nav-link>
				</div>
			</nav>
			<div
				class="order-2 col-span-2 mb-6 space-x-4 text-center sm:order-1 sm:text-left md:mb-8 lg:order-5 lg:col-auto lg:mb-0 lg:whitespace-nowrap"
			>
				<nav-link
					button
					to="communication"
					class="font-normal hover:bg-sky hover:text-kta-blue sm:px-3 sm:py-2 sm:text-xs"
				>
					Get Our Newsletter
				</nav-link>
				<nav-link
					button
					isExternalLink
					to="http://my.textcaster.com/asa/Default.aspx?ID=cba25052-6478-40e5-a9b0-e7eaf2322f85"
					class="font-normal hover:bg-sky hover:text-kta-blue sm:px-3 sm:py-2 sm:text-xs"
				>
					Get KTA Alerts
				</nav-link>
			</div>
			<div
				class="order-6 col-span-2 my-5 space-x-4 text-center sm:order-5 sm:my-1 sm:text-left md:my-3 lg:order-6 lg:col-auto lg:my-1 lg:pl-8 xl:pl-0"
			>
				<a
					target="_blank"
					href="https://www.facebook.com/KansasTurnpike"
					class="focus-visible:kta-ring inline-block hover:text-sky"
				>
					<span class="sr-only">KTA Facebook</span>
					<FacebookIcon class="h-8 w-8 lg:h-7 lg:w-7" />
				</a>
				<a
					target="_blank"
					href="https://twitter.com/KansasTurnpike"
					class="focus-visible:kta-ring inline-block hover:text-sky"
				>
					<span class="sr-only">KTA Twitter</span>
					<TwitterIcon class="h-8 w-8 lg:h-7 lg:w-7" />
				</a>
				<a
					target="_blank"
					href="https://www.youtube.com/kansasturnpike"
					class="focus-visible:kta-ring inline-block hover:text-sky"
				>
					<span class="sr-only">KTA Youtube</span>
					<YoutubeIcon class="h-8 w-8 lg:h-7 lg:w-7" />
				</a>
				<a
					target="_blank"
					href="https://www.flickr.com/photos/ksturnpike"
					class="focus-visible:kta-ring inline-block hover:text-sky"
				>
					<span class="sr-only">KTA Flickr</span>
					<FlickrIcon class="h-8 w-8 lg:h-7 lg:w-7" />
				</a>
			</div>
			<div
				class="order-7 col-span-2 text-center text-sm text-sky sm:order-8 sm:col-span-3 lg:order-7 lg:col-span-2 lg:text-left"
			>
				&copy; 2021 Kansas Turnpike Authority
			</div>
			<nav
				class="order-5 col-span-2 mx-auto mt-6 space-x-5 text-center sm:order-7 sm:col-span-3 sm:mb-3 sm:space-x-3 md:mt-0 lg:order-8 lg:col-span-2 lg:mx-0 lg:w-auto lg:text-right lg:text-sm"
				aria-label="Footer auxiliary"
			>
				<span
					class="inline-block space-x-5 sm:space-x-3"
					v-for="(item, index) in auxiliaryMenu"
					:key="index"
				>
					<nav-link class="whitespace-nowrap" :to="item.url">{{
						item.name
					}}</nav-link>
					<span class="text-sky" v-if="index < auxiliaryMenu.length - 1"
						>|</span
					>
				</span>
			</nav>
		</div>
	</footer>
</template>

<script>
import {
	FacebookIcon,
	FlickrIcon,
	Kta1cHorzLogo,
	KtagLogoWhite,
	TwitterIcon,
	YoutubeIcon
} from '@/components/icons'
import { useMainStore } from '@/stores/mainStore'
import { ChevronRightIcon } from '@heroicons/vue/24/solid'
import { mapStores } from 'pinia'
export default {
	components: {
		ChevronRightIcon,
		FacebookIcon,
		FlickrIcon,
		Kta1cHorzLogo,
		KtagLogoWhite,
		TwitterIcon,
		YoutubeIcon
	},
	name: 'SiteFooter',
	computed: {
		...mapStores(useMainStore),
		auxiliaryMenu() {
			return this.mainStore.getAuxiliaryMenu
		},
		secondaryMenu() {
			return this.mainStore.getSecondaryMenu
		}
	}
}
</script>
