<template>
	<svg
		viewBox="0 0 150 40"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		role="img"
		aria-labelledby="ktaCompactTitle"
	>
		<title id="ktaCompactTitle">K T A compact logo</title>
		<path
			d="M118.953 10.4491L123.853 0.2771H80.9992L67.3992 12.8171L69.6512 0.2771H56.7042L49.7432 39.0271H62.6852L64.8852 26.7571L68.5272 23.5811L75.2392 39.0271H90.7682L79.1512 14.8621L83.8422 10.4491H97.4662L92.3322 39.0271H105.268L110.401 10.4491H118.953Z"
			fill="#00336A"
		/>
		<path
			d="M142.812 0.278076H129.65L108.541 39.0271H121.209L123.609 33.9141H136.496L137.113 39.0271H150L142.812 0.278076ZM127.526 25.8411L134.026 11.9561L135.7 25.8411H127.526Z"
			fill="#00336A"
		/>
		<path
			d="M37.1252 19.0012L47.4322 38.9852H22.3572C19.7792 36.2439 18.3378 32.6263 18.3242 28.8632C18.3242 19.1482 30.8042 6.9392 47.0372 0.981201L37.1252 19.0012Z"
			fill="#00336A"
		/>
		<path
			d="M0.00500107 30.7371L0 10.4681C0 7.76287 1.07464 5.16846 2.98751 3.25558C4.90038 1.34271 7.49479 0.268066 10.2 0.268066H47.2C22.179 3.56107 4.395 16.0931 0 30.7421"
			fill="#FFA80A"
		/>
		<path
			d="M6.08402 38.982C3.81302 22.438 18.647 14.121 23.114 11.405L23.244 11.526C17.334 16.747 8.88902 26.406 13.824 38.982H6.08402Z"
			fill="#00336A"
		/>
	</svg>
</template>

<script>
export default {
	name: 'KtaLogoCompact'
}
</script>
