<template>
	<svg
		viewBox="0 0 386 48"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		role="img"
		aria-label="K T A logo"
	>
		<path
			d="M62.796 31.0272H68.637L69.629 25.3832L71.274 23.9212L74.308 31.0212H81.314L76.065 19.9062L83.755 13.1992H76.897L70.759 18.9662L71.774 13.1992H65.931L62.796 31.0272Z"
			fill="#00336A"
		/>
		<path
			d="M95.483 23.057C96.026 19.963 96.055 17.686 89.917 17.686C84.793 17.686 82.554 19.2951 82.071 22.0421H87.022C87.1571 21.6262 87.4348 21.2715 87.8061 21.0406C88.1774 20.8097 88.6183 20.7174 89.051 20.78C89.596 20.78 90.712 20.9031 90.551 21.8441C90.124 24.2701 81.362 21.596 80.326 27.488C79.851 30.188 81.816 31.399 84.267 31.399C86.1544 31.4802 88.004 30.8532 89.453 29.6411V31.028H94.775L94.819 30.78C94.246 30.237 94.287 29.865 94.419 29.123L95.483 23.057ZM87.104 28.4781C86.089 28.4781 85.549 28.032 85.661 27.388C85.961 25.705 88.116 26.1021 89.942 25.2881C89.752 27.0681 88.91 28.4781 87.103 28.4781H87.104Z"
			fill="#00336A"
		/>
		<path
			d="M96.039 31.028H101.188L102.349 24.419C102.641 22.76 103.241 21.7451 104.825 21.7451C106.285 21.7451 106.43 22.8831 106.205 24.1711L104.998 31.028H110.147L111.514 23.255C112.14 19.69 110.885 17.686 107.468 17.686C106.539 17.7019 105.626 17.9257 104.796 18.3411C103.965 18.7564 103.238 19.3527 102.668 20.086L103.025 18.057H98.325L96.039 31.028Z"
			fill="#00336A"
		/>
		<path
			d="M111.901 26.993C111.56 30.483 114.666 31.399 117.637 31.399C122.388 31.399 124.937 29.5661 125.413 26.8701C126.266 22.0181 117.861 23.231 118.177 21.424C118.291 20.78 118.909 20.5081 119.799 20.5081C120.156 20.4857 120.507 20.6005 120.782 20.829C120.906 20.95 121 21.0977 121.057 21.2609C121.115 21.4241 121.134 21.5983 121.113 21.7701H125.789C126.097 18.33 122.889 17.686 120.071 17.686C117.371 17.686 113.763 18.651 113.179 21.967C112.295 26.993 120.533 25.334 120.167 27.414C120.006 28.329 119.121 28.5771 118.33 28.5771C117.897 28.5924 117.473 28.4503 117.136 28.177C116.98 28.0263 116.866 27.8368 116.807 27.6279C116.748 27.419 116.746 27.1982 116.8 26.988L111.901 26.993Z"
			fill="#00336A"
		/>
		<path
			d="M140.91 23.057C141.453 19.963 141.482 17.686 135.344 17.686C130.22 17.686 127.981 19.2951 127.498 22.0421H132.449C132.584 21.6262 132.862 21.2715 133.233 21.0406C133.604 20.8097 134.045 20.7174 134.478 20.78C135.023 20.78 136.139 20.9031 135.973 21.8441C135.547 24.2701 126.785 21.596 125.75 27.488C125.275 30.188 127.239 31.399 129.689 31.399C131.576 31.4802 133.426 30.8532 134.875 29.6411V31.028H140.198L140.242 30.78C139.669 30.237 139.71 29.865 139.842 29.123L140.91 23.057ZM132.531 28.4781C131.516 28.4781 130.976 28.032 131.088 27.388C131.388 25.705 133.543 26.1021 135.369 25.2881C135.181 27.0681 134.337 28.4781 132.531 28.4781Z"
			fill="#00336A"
		/>
		<path
			d="M141.311 26.993C140.971 30.483 144.076 31.399 147.047 31.399C151.798 31.399 154.347 29.5661 154.823 26.8701C155.676 22.0181 147.269 23.231 147.587 21.424C147.701 20.78 148.319 20.5081 149.209 20.5081C149.566 20.4861 149.918 20.6009 150.193 20.829C150.316 20.9503 150.41 21.0982 150.467 21.2614C150.524 21.4245 150.543 21.5985 150.522 21.7701H155.201C155.507 18.33 152.301 17.686 149.483 17.686C146.783 17.686 143.174 18.651 142.591 21.967C141.707 26.993 149.945 25.334 149.579 27.414C149.418 28.329 148.533 28.5771 147.741 28.5771C147.308 28.5922 146.884 28.4501 146.548 28.177C146.392 28.0263 146.278 27.8368 146.219 27.6279C146.16 27.419 146.158 27.1982 146.212 26.988L141.311 26.993Z"
			fill="#00336A"
		/>
		<path
			d="M158.976 17.8831H164.224L161.913 31.0271H167.755L170.066 17.8831H175.314L176.136 13.2041H159.798L158.976 17.8831Z"
			fill="#00336A"
		/>
		<path
			d="M187.585 18.0572H182.436L181.336 24.2942C180.982 26.3002 180.317 27.4142 178.836 27.4142C177.597 27.4142 177.078 26.8452 177.393 25.0622L178.624 18.0562H173.475L172.087 25.9562C171.435 29.6702 172.963 31.4022 175.787 31.4022C176.755 31.4659 177.724 31.2917 178.609 30.8948C179.495 30.4979 180.269 29.8903 180.866 29.1252L180.53 31.0302H185.309L187.585 18.0572Z"
			fill="#00336A"
		/>
		<path
			d="M186.818 31.028H191.967L192.755 26.5471C193.247 23.7471 194.485 22.76 196.589 22.76C196.938 22.7848 197.285 22.8343 197.627 22.9081L198.533 17.761C198.316 17.723 198.096 17.6979 197.876 17.686C195.876 17.686 194.44 18.651 193.364 20.686H193.318L193.779 18.062H189.1L186.818 31.028Z"
			fill="#00336A"
		/>
		<path
			d="M197.657 31.028H202.806L203.967 24.419C204.259 22.76 204.859 21.7451 206.443 21.7451C207.903 21.7451 208.05 22.8831 207.823 24.1711L206.623 31.028H211.77L213.137 23.255C213.765 19.69 212.508 17.686 209.091 17.686C208.162 17.702 207.249 17.9259 206.419 18.3412C205.588 18.7566 204.861 19.3528 204.291 20.086L204.648 18.057H199.948L197.657 31.028Z"
			fill="#00336A"
		/>
		<path
			d="M224.412 17.6861C223.538 17.6835 222.677 17.8911 221.9 18.2914C221.123 18.6917 220.454 19.2729 219.949 19.9861H219.899L220.24 18.0541H215.61L212.554 35.4301H217.703L218.703 29.7381C219.004 30.2682 219.446 30.7042 219.98 30.9969C220.515 31.2895 221.12 31.4275 221.729 31.3951C224.629 31.3951 228.12 29.5381 229.017 24.4401C229.643 20.8791 228.324 17.6861 224.412 17.6861ZM223.856 24.5181C223.541 26.3001 222.471 27.4631 221.111 27.4631C219.773 27.4631 219.223 26.2261 219.541 24.4191C219.841 22.6861 220.897 21.4981 222.134 21.4981C223.494 21.4991 224.217 22.4641 223.856 24.5181Z"
			fill="#00336A"
		/>
		<path
			d="M231.871 16.3981H237.02L237.664 12.7351H232.515L231.871 16.3981Z"
			fill="#00336A"
		/>
		<path
			d="M234.448 31.0271L236.728 18.0571H231.581L229.299 31.0271H234.448Z"
			fill="#00336A"
		/>
		<path
			d="M243.246 26.4212L245.208 31.0212H251.398L247.375 23.0752L253.061 18.0512H247.046L243.057 22.0112L244.607 13.1992H239.458L236.325 31.0212H241.474L242.113 27.3822L243.246 26.4212Z"
			fill="#00336A"
		/>
		<path
			d="M265.727 25.7051C266.627 21.0051 264.316 17.6861 259.513 17.6861C257.485 17.6347 255.506 18.3099 253.933 19.59C252.36 20.87 251.296 22.6704 250.934 24.6661C250.169 29.0221 252.969 31.3991 257.127 31.3991C260.147 31.3991 263.601 30.3341 265.059 27.1161H260.132C259.901 27.4693 259.584 27.7587 259.212 27.9576C258.84 28.1565 258.423 28.2584 258.001 28.2541C257.68 28.2724 257.359 28.2147 257.064 28.0858C256.769 27.9568 256.509 27.7603 256.305 27.512C256.1 27.2637 255.957 26.9708 255.887 26.6568C255.817 26.3429 255.822 26.0168 255.901 25.7051H265.727ZM259.159 20.8291C259.44 20.827 259.717 20.8871 259.972 21.005C260.226 21.1229 260.451 21.2957 260.631 21.511C260.811 21.7263 260.941 21.9788 261.012 22.2502C261.082 22.5217 261.092 22.8054 261.04 23.0811H256.361C256.454 22.4207 256.795 21.8205 257.315 21.4022C257.835 20.984 258.494 20.7791 259.159 20.8291Z"
			fill="#00336A"
		/>
		<path
			d="M283.77 13.2031H277.832L268.309 31.0271H274.029L275.111 28.6741H280.926L281.208 31.0271H287.025L283.77 13.2031ZM276.876 24.9621L279.808 18.5761L280.565 24.9621H276.876Z"
			fill="#00336A"
		/>
		<path
			d="M302.647 18.0572H297.498L296.398 24.2942C296.044 26.3002 295.379 27.4142 293.898 27.4142C292.661 27.4142 292.142 26.8452 292.455 25.0622L293.686 18.0562H288.539L287.149 25.9562C286.497 29.6702 288.025 31.4022 290.849 31.4022C291.817 31.4659 292.786 31.2917 293.671 30.8948C294.556 30.4979 295.331 29.8903 295.927 29.1252L295.593 31.0302H300.371L302.647 18.0572Z"
			fill="#00336A"
		/>
		<path
			d="M310.498 27.3392H309.681C308.246 27.3392 308.147 26.9172 308.356 25.7302L309.181 21.0302H311.653L312.175 18.0592H309.7L310.409 14.0242H305.335L304.625 18.0592H302.723L302.201 21.0302H304.034L303.081 26.4492C302.293 30.9302 303.027 31.4002 309.85 31.0292L310.498 27.3392Z"
			fill="#00336A"
		/>
		<path
			d="M311.284 31.0282H316.435L317.596 24.4192C317.888 22.7602 318.488 21.7452 320.072 21.7452C321.532 21.7452 321.679 22.8832 321.452 24.1712L320.252 31.0282H325.401L326.767 23.2552C327.394 19.6902 326.138 17.6862 322.722 17.6862C321.905 17.6898 321.099 17.8705 320.358 18.2158C319.618 18.561 318.961 19.0627 318.433 19.6862L319.573 13.2012H314.424L311.284 31.0282Z"
			fill="#00336A"
		/>
		<path
			d="M335.935 17.6863C331.553 17.6863 328.143 20.1863 327.351 24.6923C326.573 29.1223 329.39 31.3993 333.551 31.3993C337.712 31.3993 341.319 29.1723 342.151 24.4193C342.862 20.3853 340.515 17.6863 335.935 17.6863ZM337.004 24.4193C336.573 26.8693 335.545 27.9333 334.158 27.9333C332.846 27.9333 332.118 26.8693 332.548 24.4193C332.806 22.9583 333.643 21.1513 335.401 21.1513C336.745 21.0773 337.34 22.5143 337.004 24.4193Z"
			fill="#00336A"
		/>
		<path
			d="M341.934 31.0283H347.085L347.873 26.5473C348.364 23.7473 349.603 22.7603 351.706 22.7603C352.055 22.785 352.402 22.8345 352.744 22.9083L353.651 17.7613C353.434 17.7232 353.214 17.6982 352.994 17.6863C350.994 17.6863 349.558 18.6513 348.482 20.6863H348.433L348.893 18.0623H344.214L341.934 31.0283Z"
			fill="#00336A"
		/>
		<path
			d="M355 16.3981H360.149L360.793 12.7351H355.644L355 16.3981Z"
			fill="#00336A"
		/>
		<path
			d="M357.577 31.0271L359.857 18.0571H354.708L352.426 31.0271H357.577Z"
			fill="#00336A"
		/>
		<path
			d="M368.789 27.3392H367.972C366.537 27.3392 366.438 26.9172 366.646 25.7302L367.474 21.0302H369.949L370.471 18.0592H368L368.709 14.0242H363.635L362.927 18.0592H361.02L360.498 21.0302H362.33L361.377 26.4492C360.589 30.9302 361.324 31.4002 368.146 31.0292L368.789 27.3392Z"
			fill="#00336A"
		/>
		<path
			d="M375.742 18.0571H370.569L372.75 29.8651C373.018 31.3001 372.564 31.7701 370.955 31.7701C370.608 31.7701 370.266 31.7461 369.894 31.7461L369.263 35.3351C369.663 35.3351 370.583 35.4351 371.523 35.4351C376.103 35.4351 376.868 34.1731 378.934 30.1631L385.221 18.0631H380.27L376.613 26.0581L375.742 18.0571Z"
			fill="#00336A"
		/>
		<path
			d="M45.062 23.1462L57.572 47.4022H27.136C24.0083 44.074 22.2593 39.6834 22.242 35.1162C22.242 23.3232 37.39 8.50519 57.094 1.27319L45.062 23.1462Z"
			fill="#00336A"
		/>
		<path
			d="M4.03739e-08 37.3891V12.7891C-0.000131288 11.1626 0.320129 9.55199 0.942474 8.04926C1.56482 6.54652 2.47707 5.18107 3.62714 4.03091C4.7772 2.88075 6.14255 1.96841 7.64523 1.34595C9.14792 0.72348 10.7585 0.403076 12.385 0.403076H57.3C26.929 4.40308 5.344 19.6101 4.03739e-08 37.3921"
			fill="#FFA80A"
		/>
		<path
			d="M7.38301 47.3961C4.62601 27.3151 22.632 17.2211 28.055 13.9231L28.212 14.0711C21.038 20.4091 10.788 32.1321 16.779 47.3971L7.38301 47.3961Z"
			fill="#00336A"
		/>
	</svg>
</template>

<script>
export default {
	name: 'KtaLogo'
}
</script>
