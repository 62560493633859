<template>
	<div
		:class="[
			{
				'transition-all duration-150 motion-safe:hover:-translate-y-1 motion-safe:hover:shadow-inset-wide':
					hover
			},
			`group-focus-visible:kta-ring-inset ${shadowClasses} relative flex h-full w-full flex-col overflow-hidden rounded-lg bg-white`
		]"
	>
		<img
			v-if="path"
			:class="[{ absolute: imageFull }, 'z-0 h-full w-full object-cover']"
			:src="path"
			alt="Card Image"
			decoding="async"
			loading="lazy"
		/>
		<div
			:class="[
				{ 'justify-end': imageFull },
				textAlignment === 'center' ? 'items-center' : 'items-start',
				'z-10 flex h-full w-full flex-col px-5 py-4 sm:py-7'
			]"
		>
			<KtaIcons
				:size="bigIcon ? 'w-full h-10' : 'w-12 h-10'"
				v-if="icon"
				:name="icon"
			/>
			<router-link
				v-if="link"
				:to="link"
				:class="[
					imageFull ? 'text-white' : 'text-cobalt',
					textAlignment === 'center' ? 'text-center' : 'text-left',
					'focus-visible:kta-ring py-2 text-xl font-bold leading-6 hover:text-kta-blue'
				]"
				>{{ title }}</router-link
			>
			<div
				v-else
				:class="[
					imageFull ? 'text-white' : 'text-cobalt',
					textAlignment === 'center' ? 'text-center' : 'text-left',
					'py-2 text-xl font-bold leading-6'
				]"
			>
				{{ title }}
			</div>
			<span class="mb-4 text-sm italic" v-if="publishedDate">
				Posted
				<time datetime="published">{{ publishedDate }}</time>
			</span>
			<div
				:class="[
					imageFull ? 'text-white' : 'grow',
					textAlignment === 'center' ? 'text-center' : 'text-left'
				]"
			>
				<div class="remove-last-mb" v-html="body"></div>
			</div>
			<router-link
				v-if="buttonText || linkText"
				:class="[
					buttonText
						? imageFull
							? 'focus-visible:kta-ring-inset rounded bg-white px-4 py-2.5 text-sm font-bold leading-4 tracking-wide text-cobalt hover:text-kta-blue'
							: ' focus-visible:kta-ring mt-5 rounded bg-cobalt px-4 py-2.5 text-sm font-bold leading-4 tracking-wide text-white hover:bg-kta-blue'
						: ' focus-visible:kta-ring mt-5 text-cobalt hover:text-kta-blue',
					'inline-flex self-center font-bold sm:text-sm'
				]"
				v-text="buttonText || linkText"
				:to="link"
			></router-link>
		</div>
	</div>
</template>

<script>
import KtaIcons from '@/components/icons/KtaIcons'
import { format } from 'date-fns'
export default {
	name: 'BasicCard',
	components: {
		KtaIcons
	},
	props: {
		icon: {
			type: String,
			default: '',
			required: false
		},
		bigIcon: {
			type: Boolean,
			default: false,
			required: false
		},
		path: {
			type: String,
			default: '',
			required: false
		},
		imageFull: {
			type: Boolean,
			default: false,
			required: false
		},
		title: {
			type: String,
			required: true
		},
		body: {
			type: String,
			required: true
		},
		buttonText: {
			type: String,
			default: '',
			required: false
		},
		linkText: {
			type: String,
			default: '',
			required: false
		},
		link: {
			type: [String, Object],
			default: '',
			required: false
		},
		hover: {
			type: Boolean,
			default: false,
			required: false
		},
		shadowStyle: {
			type: String,
			default: '',
			required: false
		},
		textAlignment: {
			type: String,
			default: 'left',
			required: false
		},
		published: {
			type: String,
			required: false
		}
	},
	computed: {
		shadowClasses() {
			if (this.shadowStyle === 'wide') {
				return 'shadow-wide'
			} else if (this.shadowStyle === 'inset') {
				return 'shadow-inset'
			}
			return ''
		},
		publishedDate() {
			if (this.published) {
				return format(new Date(this.published), 'P')
			}
			return false
		}
	}
}
</script>
