const weather = `<svg class="h-full" viewBox="0 0 400 400" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
	<path d="M176.352 77.0595L176.349 77.0513L176.363 77.0486L176.352 77.0595Z" fill="currentColor"/>
	<path d="M176.349 77.0513L146.407 0L159.001 81.6973C164.618 79.7529 170.397 78.197 176.349 77.0513Z" fill="currentColor"/>
	<path d="M118.072 105.362L53.5877 53.5892L105.359 118.076C109.284 113.546 113.532 109.297 118.072 105.362Z" fill="currentColor"/>
	<path d="M253.582 0L240.988 81.6973L240.999 81.7081C235.377 79.7622 229.583 78.1946 223.637 77.0595L253.582 0Z" fill="currentColor"/>
	<path d="M318.294 159.005C320.24 164.627 321.797 170.411 322.943 176.368L400 146.422L318.305 159.016L318.294 159.005Z" fill="currentColor"/>
	<path d="M346.401 53.5892L294.63 118.076C290.695 113.535 286.446 109.286 281.917 105.362L346.401 53.5892Z" fill="currentColor"/>
	<path d="M81.6951 159.005L0 146.411L77.0574 176.357C78.1925 170.411 79.76 164.616 81.7059 158.995L81.6951 159.005Z" fill="currentColor"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M233.59 348.551H180.536C175.889 348.551 171.441 347.693 167.342 346.127L146.407 400L155.8 339.069C148.224 332.267 143.456 322.396 143.456 311.438V292.209C136.368 287.854 129.673 282.607 123.533 276.467C81.303 234.235 81.303 165.765 123.533 123.533C165.764 81.3016 234.233 81.3016 276.463 123.533C309.587 156.658 316.73 205.926 297.891 246.013C312.113 252.619 323.122 265.503 328.795 281.919C336.439 274.894 345.366 269.351 357.482 269.351C378.305 269.351 395.658 285.599 396.987 306.373C398.37 327.83 383.366 348.551 359.893 348.551H245.651L253.582 400L233.59 348.551ZM210.102 222.876C194.439 222.876 179.644 229.143 168.404 240.585C157.189 252.014 151.023 267.033 151.023 282.897V311.438C151.023 327.721 164.253 340.984 180.536 340.984H359.893C378.143 340.984 390.595 324.855 389.435 306.86L389.435 306.858C388.363 290.076 374.323 276.919 357.482 276.919C346.096 276.919 338.015 283.011 329.687 291.643L324.957 296.546L323.295 289.94C315.979 260.865 291.715 243.21 263.018 250.29L260.569 250.894L259.069 248.866C247.315 232.966 229.877 222.876 210.102 222.876Z" fill="currentColor"/>
	<path d="M318.294 240.995L399.989 253.589L322.932 223.643C321.797 229.589 320.229 235.384 318.283 241.005L318.294 240.995Z" fill="currentColor"/>
	<path d="M77.0493 223.646L77.0574 223.643L77.0466 223.632L77.0493 223.646Z" fill="currentColor"/>
	<path d="M77.0493 223.646L0 253.589L81.6951 240.995C79.7507 235.377 78.1949 229.598 77.0493 223.646Z" fill="currentColor"/>
	<path d="M53.5877 346.411L105.359 281.924C109.294 286.465 113.543 290.714 118.072 294.638L53.5877 346.411Z" fill="currentColor"/>
</svg>
`

const camera = `<svg class="h-full" viewBox="0 0 400 400" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
	<path d="M161.23 227.097C175.268 227.097 186.648 215.706 186.648 201.655C186.648 187.604 175.268 176.213 161.23 176.213C147.192 176.213 135.812 187.604 135.812 201.655C135.812 215.706 147.192 227.097 161.23 227.097Z"/>
	<path d="M161.23 236.627C155.298 236.627 149.706 235.145 144.81 232.53V277.574C144.81 283.414 140.088 288.14 134.254 288.14H21V321H167.093C172.927 321 177.649 316.274 177.649 310.434V232.53C172.753 235.145 167.17 236.627 161.23 236.627Z"/>
	<path d="M287.7 229.508L379 190.43L95.4737 79.7242C90.0069 77.5935 83.8532 80.3246 81.7729 85.8159L51.5268 165.531C49.4755 170.945 52.1557 176.998 57.5354 179.119L126.61 206.255C126.407 204.744 126.3 203.214 126.3 201.645C126.3 181.588 143.165 165.483 163.474 166.742C181.171 167.846 195.452 182.479 196.159 200.222C196.614 211.65 191.592 221.915 183.503 228.617L296.147 272.877C301.672 275.046 307.893 272.237 309.925 266.649L326.848 220.075L296.505 232.888L287.72 229.508H287.7Z"/>
	<path d="M366.412 220.395C352.256 215.262 336.069 215.717 336.069 215.717L319.93 260.296C319.93 260.296 332.063 271.036 346.219 276.169C355.643 279.588 362.28 267.87 367.863 252.471C373.436 237.072 375.855 223.814 366.422 220.395H366.412Z"/>
</svg>
`

const dms = `<svg class="h-full" viewBox="0 0 400 400" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M182.85 59.9833L32.7063 320.045C25.0179 333.356 34.626 350 50.0029 350H350.29C365.667 350 375.275 333.356 367.587 320.045L217.443 59.9833C209.755 46.6722 190.538 46.6722 182.85 59.9833ZM175.22 282.373C173.846 285.706 173.164 289.223 173.164 292.936C173.164 296.658 173.846 300.176 175.22 303.499C176.584 306.822 178.494 309.706 180.94 312.152C183.386 314.598 186.27 316.508 189.593 317.872C192.916 319.237 196.444 319.928 200.156 319.928C203.869 319.928 207.397 319.237 210.72 317.872C214.042 316.508 216.927 314.598 219.373 312.152C221.819 309.706 223.719 306.822 225.093 303.499C226.467 300.176 227.149 296.649 227.149 292.936C227.149 289.223 226.457 285.696 225.093 282.373C223.719 279.05 221.819 276.166 219.373 273.72C216.927 271.274 214.042 269.374 210.72 268C207.387 266.636 203.879 265.944 200.156 265.944C196.434 265.944 192.916 266.636 189.593 268C186.261 269.374 183.386 271.274 180.94 273.72C178.494 276.166 176.584 279.05 175.22 282.373ZM180.677 230.464C180.901 241.066 189.554 249.534 200.147 249.534C210.739 249.534 219.392 241.056 219.616 230.464L221.721 130.397C221.955 119.483 213.165 110.509 202.252 110.509H198.042C187.128 110.509 178.338 119.483 178.572 130.397L180.677 230.464Z"/>
</svg>
`

const exit = `<svg class="h-full" viewBox="0 0 400 400" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path d="M338.784 50.1761L89.9478 98.0493C82.5204 99.4757 79.7098 108.633 85.0609 113.976L151.266 180.182L52.76 278.688C49.08 282.368 49.08 288.344 52.76 292.024L107.976 347.24C111.656 350.92 117.632 350.92 121.312 347.24L219.818 248.734L286.024 314.939C291.375 320.29 300.524 317.48 301.951 310.052L349.824 61.2159C351.082 54.6663 345.334 48.91 338.784 50.1761V50.1761Z"/>
</svg>
`

const service = `<svg class="h-full" viewBox="0 0 400 400" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M198.916 50H268.63C282.413 50 293.603 61.1892 293.603 74.973V340.7H301.759V350H165.924V340.7H173.943L173.943 180.468C173.442 180.468 172.911 180.462 172.357 180.456C168.018 180.411 162.183 180.349 157.057 182.813C154.313 184.132 151.817 186.178 149.965 189.459C148.089 192.782 146.732 197.624 146.732 204.727V305.714C146.732 312.449 144.297 317.822 140.355 321.488C136.473 325.098 131.38 326.819 126.397 326.804C121.414 326.788 116.332 325.036 112.461 321.428C108.529 317.764 106.086 312.409 106.086 305.714V163.741H99V120.889C99 117.5 101.749 114.751 105.138 114.751H109.646L142.086 82.3108L154.241 94.4649L130.054 118.651V151.473C130.054 158.251 124.557 163.741 117.786 163.741H114.195V305.714C114.195 310.325 115.82 313.475 117.988 315.496C120.218 317.573 123.27 318.686 126.422 318.696C129.573 318.705 132.615 317.613 134.834 315.55C136.992 313.543 138.624 310.386 138.624 305.714V204.727C138.624 196.615 140.173 190.311 142.904 185.472C145.66 180.592 149.475 177.461 153.544 175.505C160.531 172.147 168.393 172.275 172.577 172.344C173.09 172.352 173.549 172.359 173.943 172.359L173.943 74.973C173.943 61.1892 185.132 50 198.916 50ZM263.668 213.346C263.668 190.141 233.773 147.459 233.773 147.459C233.773 147.459 203.878 190.141 203.878 213.346C203.878 229.854 217.265 243.241 233.773 243.241C250.281 243.241 263.668 229.854 263.668 213.346Z"/>
</svg>
`

const parking = `<svg class="h-full" viewBox="0 0 400 400" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M350 200C350 282.843 282.843 350 200 350C117.157 350 50 282.843 50 200C50 117.157 117.157 50 200 50C282.843 50 350 117.157 350 200ZM210.524 125.746H155.973V272.592H188.332V215.341H211.976C219.711 215.341 226.903 214.611 233.543 213.16C240.176 211.708 245.916 209.251 250.757 205.797C255.597 202.343 259.4 197.705 262.165 191.9C264.93 186.095 266.316 179.041 266.316 170.332C266.316 161.624 264.832 154.4 261.857 148.659C258.881 142.927 254.868 138.362 249.824 134.973C244.773 131.592 238.862 129.208 232.092 127.822C225.322 126.435 218.13 125.746 210.524 125.746ZM209.9 187.968H188.332L188.316 187.976V153.127H204.913C207.954 153.127 211.14 153.305 214.457 153.646C217.773 153.987 220.781 154.789 223.481 156.03C226.181 157.27 228.395 159.03 230.122 161.316C231.849 163.603 232.716 166.611 232.716 170.341C232.716 173.803 232.059 176.673 230.746 178.951C229.424 181.23 227.697 183.03 225.557 184.343C223.416 185.657 221 186.589 218.3 187.141C215.6 187.692 212.803 187.968 209.9 187.968Z"/>
</svg>
`

export default {
	weather,
	camera,
	dms,
	exit,
	service,
	parking
}
