<template>
	<svg
		fill="currentColor"
		viewBox="0 0 310.222 38"
		role="img"
		aria-labelledby="ktaHorzTitle"
	>
		<title id="ktaHorzTitle">Kansas Turnpike Authority white logo</title>
		<path
			d="M350.722,293.094h4.7l.8-4.563,1.323-1.181,2.443,5.744h5.642l-4.227-8.987,6.193-5.423h-5.523l-4.943,4.663.817-4.663h-4.705Z"
			transform="translate(-300.152 -268.331)"
		/>
		<g transform="translate(64.626 13.976)">
			<path
				d="M373.752,285.79c.437-2.5.461-4.343-4.482-4.343-4.127,0-5.929,1.3-6.318,3.522h3.987a1.493,1.493,0,0,1,1.634-1.02c.439,0,1.337.1,1.2.86-.343,1.962-7.4-.2-8.234,4.563-.383,2.182,1.2,3.162,3.173,3.162a6.085,6.085,0,0,0,4.177-1.421l0,1.121h4.286l.035-.2c-.461-.439-.428-.74-.324-1.34ZM367,290.173c-.817,0-1.252-.361-1.162-.881.239-1.361,1.977-1.04,3.448-1.7C369.137,289.032,368.459,290.173,367,290.173Z"
				transform="translate(-361.485 -281.447)"
			/>
			<path
				d="M371.221,292.234h4.146l.935-5.343c.235-1.341.718-2.162,1.994-2.162,1.175,0,1.293.92,1.111,1.962l-.972,5.544h4.146l1.1-6.284c.5-2.882-.507-4.5-3.258-4.5a5,5,0,0,0-3.868,1.941l.288-1.64h-3.787Z"
				transform="translate(-358.506 -281.447)"
			/>
			<path
				d="M381.007,288.972c-.274,2.822,2.227,3.562,4.619,3.562,3.826,0,5.881-1.482,6.262-3.662.687-3.923-6.082-2.942-5.827-4.4.091-.521.589-.741,1.306-.741a1.125,1.125,0,0,1,.791.26.913.913,0,0,1,.266.76h3.768c.248-2.781-2.332-3.3-4.6-3.3-2.173,0-5.08.78-5.55,3.462-.712,4.063,5.923,2.722,5.627,4.4-.129.739-.842.94-1.48.94a1.441,1.441,0,0,1-.961-.32,1,1,0,0,1-.27-.961Z"
				transform="translate(-355.518 -281.447)"
			/>
			<path
				d="M401.764,285.79c.437-2.5.461-4.343-4.482-4.343-4.127,0-5.929,1.3-6.318,3.522h3.987a1.493,1.493,0,0,1,1.634-1.02c.439,0,1.337.1,1.2.86-.343,1.962-7.4-.2-8.233,4.563-.383,2.182,1.2,3.162,3.172,3.162a6.085,6.085,0,0,0,4.177-1.421l0,1.121h4.286l.035-.2c-.461-.439-.428-.74-.324-1.34Zm-6.748,4.383c-.818,0-1.252-.361-1.162-.881.239-1.361,1.977-1.04,3.448-1.7C397.148,289.032,396.471,290.173,395.016,290.173Z"
				transform="translate(-352.914 -281.447)"
			/>
			<path
				d="M399.142,288.972c-.274,2.822,2.227,3.562,4.619,3.562,3.826,0,5.881-1.482,6.262-3.662.687-3.923-6.083-2.942-5.827-4.4.092-.521.589-.741,1.306-.741a1.13,1.13,0,0,1,.793.26.916.916,0,0,1,.265.76h3.768c.247-2.781-2.333-3.3-4.6-3.3-2.173,0-5.08.78-5.55,3.462-.712,4.063,5.923,2.722,5.628,4.4-.129.739-.842.94-1.48.94a1.44,1.44,0,0,1-.961-.32,1,1,0,0,1-.27-.961Z"
				transform="translate(-349.969 -281.447)"
			/>
		</g>
		<path
			d="M410.03,282.466h4.226L412.4,293.093h4.7l1.861-10.627h4.226l.662-3.783H410.692Z"
			transform="translate(-282.006 -268.331)"
		/>
		<g transform="translate(138.462 13.976)">
			<path
				d="M430.624,281.676h-4.146l-.883,5.043c-.285,1.622-.82,2.523-2.016,2.523-1,0-1.416-.46-1.162-1.9l.991-5.664h-4.146l-1.118,6.384c-.525,3,.705,4.4,2.976,4.4a4.78,4.78,0,0,0,4.09-1.841l-.27,1.541h3.849Z"
				transform="translate(-418.023 -281.376)"
			/>
			<path
				d="M427.2,292.234h4.146l.635-3.623c.4-2.262,1.393-3.062,3.087-3.062a5.953,5.953,0,0,1,.836.119l.73-4.162a4.448,4.448,0,0,0-.529-.06,3.9,3.9,0,0,0-3.633,2.422h-.039l.371-2.122h-3.768Z"
				transform="translate(-415.216 -281.447)"
			/>
			<path
				d="M433.883,292.234h4.147l.935-5.343c.235-1.341.718-2.162,1.994-2.162,1.175,0,1.294.92,1.111,1.962l-.97,5.544h4.145l1.1-6.284c.505-2.882-.507-4.5-3.259-4.5a5,5,0,0,0-3.868,1.941l.287-1.64h-3.787Z"
				transform="translate(-413.17 -281.447)"
			/>
			<path
				d="M452.618,281.447a4.382,4.382,0,0,0-3.594,1.862h-.04l.274-1.562H445.53L443.069,295.8h4.147l.806-4.6a2.638,2.638,0,0,0,2.437,1.34c2.333,0,5.147-1.5,5.869-5.624C456.831,284.029,455.768,281.447,452.618,281.447Zm-.448,5.524c-.253,1.441-1.115,2.381-2.211,2.381-1.078,0-1.52-1-1.264-2.461.246-1.4,1.092-2.361,2.088-2.361C451.879,284.53,452.462,285.31,452.17,286.971Z"
				transform="translate(-410.36 -281.447)"
			/>
		</g>
		<path
			d="M454.98,281.356h4.146l.518-2.962H455.5Z"
			transform="translate(-268.253 -268.421)"
		/>
		<path
			d="M457.54,292.163l1.836-10.487h-4.145l-1.838,10.487Z"
			transform="translate(-268.738 -267.4)"
		/>
		<path
			d="M463.3,289.371l1.58,3.722h4.984l-3.24-6.425,4.579-4.062h-4.844l-3.213,3.2,1.249-7.125h-4.147l-2.523,14.41h4.146l.515-2.942Z"
			transform="translate(-267.413 -268.331)"
		/>
		<path
			d="M478.672,287.931c.726-3.8-1.136-6.484-5-6.484a6.851,6.851,0,0,0-6.909,5.643c-.616,3.522,1.639,5.444,4.988,5.444,2.432,0,5.213-.861,6.388-3.463h-3.968a2.024,2.024,0,0,1-1.716.92,1.653,1.653,0,0,1-1.693-2.061Zm-5.289-3.943a1.532,1.532,0,0,1,1.515,1.821H471.13A2.118,2.118,0,0,1,473.383,283.988Z"
			transform="translate(-264.68 -267.471)"
		/>
		<path
			d="M489.9,278.683h-4.782l-7.669,14.411h4.606l.871-1.9h4.683l.227,1.9h4.685Zm-5.552,9.507,2.361-5.163.61,5.163Z"
			transform="translate(-261.378 -268.331)"
		/>
		<g transform="translate(231.122 10.353)">
			<path
				d="M501.575,281.676h-4.147l-.883,5.043c-.285,1.622-.82,2.523-2.017,2.523-1,0-1.414-.46-1.162-1.9l.991-5.664h-4.145l-1.119,6.384c-.525,3,.705,4.4,2.978,4.4a4.777,4.777,0,0,0,4.089-1.841l-.269,1.541h3.847Z"
				transform="translate(-488.974 -277.753)"
			/>
			<path
				d="M505.029,289.955h-.658c-1.156,0-1.235-.341-1.067-1.3l.665-3.8h1.994l.421-2.4h-1.993l.571-3.262h-4.086l-.572,3.262h-1.534l-.421,2.4h1.476l-.768,4.382c-.635,3.623-.043,4,5.451,3.7Z"
				transform="translate(-486.106 -278.527)"
			/>
			<path
				d="M503.949,293.094H508.1l.935-5.343c.235-1.341.718-2.162,1.994-2.162,1.175,0,1.294.92,1.111,1.961l-.97,5.544h4.146l1.1-6.284c.505-2.882-.507-4.5-3.257-4.5a4.545,4.545,0,0,0-3.454,1.621l.918-5.243h-4.147Z"
				transform="translate(-484.392 -278.684)"
			/>
			<path
				d="M520.794,281.447a6.6,6.6,0,0,0-6.913,5.664c-.627,3.582,1.642,5.423,4.99,5.423s6.256-1.8,6.929-5.643C526.372,283.629,524.482,281.447,520.794,281.447Zm.861,5.444c-.347,1.981-1.175,2.841-2.292,2.841-1.057,0-1.643-.86-1.3-2.841.208-1.181.882-2.642,2.3-2.642C521.449,284.189,521.925,285.35,521.655,286.891Z"
				transform="translate(-481.385 -277.824)"
			/>
			<path
				d="M522.849,292.234H527l.635-3.623c.4-2.262,1.393-3.062,3.087-3.062a5.941,5.941,0,0,1,.836.119l.73-4.162a4.443,4.443,0,0,0-.529-.06,3.9,3.9,0,0,0-3.633,2.422h-.039l.371-2.122h-3.768Z"
				transform="translate(-478.609 -277.824)"
			/>
		</g>
		<path
			d="M530.906,281.356h4.146l.518-2.962h-4.146Z"
			transform="translate(-245.022 -268.421)"
		/>
		<path
			d="M533.466,292.163l1.836-10.487h-4.145l-1.838,10.487Z"
			transform="translate(-245.507 -267.4)"
		/>
		<g>
			<path
				d="M540.974,289.955h-.658c-1.156,0-1.235-.341-1.068-1.3l.667-3.8h1.993l.421-2.4h-1.993l.571-3.262H536.82l-.57,3.262h-1.536l-.42,2.4h1.475L535,289.234c-.635,3.623-.043,4,5.451,3.7Z"
				transform="translate(-243.985 -268.174)"
			/>
			<path
				d="M544.918,281.676h-4.166l1.756,9.547c.216,1.16-.15,1.541-1.446,1.541-.279,0-.555-.02-.854-.02l-.508,2.9c.319,0,1.063.08,1.82.08,3.688,0,4.3-1.02,5.968-4.262l5.063-9.787h-3.987l-2.945,6.464Z"
				transform="translate(-242.331 -267.4)"
			/>
			<path
				d="M344.092,289.01l10.074,19.611h-24.51a14.618,14.618,0,0,1-3.941-9.933c0-9.535,12.2-21.515,28.067-27.363Z"
				transform="translate(-307.804 -270.621)"
			/>
			<path
				d="M312,300.694l0-19.892a9.993,9.993,0,0,1,9.974-10.015h36.171C333.687,274.02,316.3,286.317,312,300.694"
				transform="translate(-312 -270.788)"
			/>
			<path
				d="M316.607,306.19c-2.22-16.236,12.28-24.4,16.647-27.064l.127.119c-5.778,5.124-14.031,14.6-9.207,26.944Z"
				transform="translate(-310.661 -268.193)"
			/>
		</g>
	</svg>
</template>

<script>
export default {
	name: 'Kta1cHorzLogo'
}
</script>
