import axios from 'axios'
import { getTime } from 'date-fns'
import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'
export default {
	async fetchAccordion(id) {
		try {
			const { data } = await axios.get(
				`${process.env.VUE_APP_API}/accordions/published/${id}`
			)
			this.accordions.push(data)
		} catch (error) {
			this.handleErrors(error.response.data.error)
		}
	},
	async fetchJobs() {
		try {
			const { data } = await axios.get(process.env.VUE_APP_API_ATM)
			this.jobs = data
		} catch (error) {
			this.handleErrors(error.response.data.error)
		}
	},
	async fetchLeadership(group) {
		try {
			const { data } = await axios.get(
				`${process.env.VUE_APP_API}/leadership/group/${group}`
			)
			this.leadership[group] = data
		} catch (error) {
			this.handleErrors(error.response.data.error)
		}
	},
	async fetchLinks() {
		try {
			const { data } = await axios.get(`${process.env.VUE_APP_API}/links`)
			this.links = data
		} catch (error) {
			this.handleErrors(error.response.data.error)
		}
	},
	async fetchMenus() {
		try {
			const { data } = await axios.get(
				`${process.env.VUE_APP_API}/menus/published`
			)
			this.menus = data
		} catch (error) {
			this.handleErrors(error.response.data.error)
		}
	},
	async fetchPage(slug) {
		try {
			const { data } = await axios.get(
				`${process.env.VUE_APP_API}/slugs/${slug}`
			)
			this.page = data
			return true
		} catch (error) {
			this.page = null
			return false
		}
	},
	async fetchPosts(categories) {
		try {
			const { data } = await axios.get(
				`${process.env.VUE_APP_API}/posts/published?categories=${categories}`
			)
			return data.items
		} catch (error) {
			this.handleErrors(error.response.data.error)
		}
	},
	async fetchSiteConfiguration() {
		try {
			const { data } = await axios.get(`${process.env.VUE_APP_API}/config`)
			this.siteConfiguration = data
			this.processAlert()
		} catch (error) {
			this.handleErrors(error.response.data.error)
		}
	},
	async fetchSlides() {
		try {
			const { data } = await axios.get(
				`${process.env.VUE_APP_API}/slides/published`
			)
			this.slider = data
		} catch (error) {
			this.handleErrors(error.response.data.error)
		}
	},
	async fetchSitemap() {
		try {
			const { data } = await axios.get(`${process.env.VUE_APP_API}/sitemap`)
			this.sitemap = data
		} catch (error) {
			this.handleErrors(error.response.data.error)
		}
	},
	getContactEmail(subject) {
		if (!subject) return ''
		const contact = this.siteConfiguration.contact.contactItems.find(
			(contact) => contact.subject === subject
		)
		return contact.contact
	},
	async submitContact(contact) {
		this.toggleLoad()
		try {
			contact.emailTo = this.getContactEmail(contact.subject)
			await axios.post(`${process.env.VUE_APP_API}/forms/contact`, contact)
			this.toggleLoad()
			return true
		} catch (error) {
			this.handleErrors(error.response.data.error)
			this.toggleLoad()
			return false
		}
	},
	async submitSubscriber(subscriber) {
		this.toggleLoad()
		try {
			const data = structuredClone(subscriber)
			data.lists = data.lists.map((list) => ({
				ListID: list
			}))

			await axios.post(`${process.env.VUE_APP_API}/subscriptions`, data)
			this.toggleLoad()
			return true
		} catch (error) {
			this.handleErrors(error.response.data.error)
			this.toggleLoad()
			return false
		}
	},
	closeAlert() {
		this.siteConfiguration.alert.active = false
	},
	processAlert() {
		const start = getTime(new Date(this.siteConfiguration.alert.start))
		const expire = getTime(new Date(this.siteConfiguration.alert.end))
		this.siteConfiguration.alert.active =
			start <= Date.now() && Date.now() < expire ? true : false
	},
	closeMessageAlert() {
		this.messageAlert = {
			position: 'top',
			level: '',
			title: '',
			text: '',
			timer: null
		}
	},
	displayAlert(options) {
		this.messageAlert = merge(this.messageAlert, options)
	},
	toggleLoad() {
		this.loading = !this.loading
	},
	handleErrors(errors) {
		if (typeof errors?.message === 'string') {
			this.displayAlert({
				level: 'error',
				position: 'top-right',
				title: `${errors.message}. If the issue persists, please contact Customer Service at 316-652-2650`
			})
			return
		}
		if (errors?.message?.length) {
			this.errors = this.mapErrors(errors.message)
			return
		}

		// default error if unable to handle all other errors
		this.displayAlert({
			level: 'error',
			position: 'top-right',
			title: 'A service is currently unavailable. Please try again later.',
			timer: 6000
		})

		window.dataLayer.push({
			event: 'unhandledError',
			errorLocation: window.location.href,
			errorMessage: JSON.stringify(errors)
		})
	},
	mapErrors(errors) {
		return errors.reduce((errors, error) => {
			if (!error.children.length) {
				const key = Object.keys(error.constraints)[0]
				errors[error.property] = error.constraints[key]
				return errors
			}
			errors[error.property] = this.mapErrors(error.children)
			return errors
		}, {})
	},
	removeError(props) {
		const last = props.pop()
		if (last) {
			delete props.reduce((o, k) => o[k], this.errors)[last]
		}

		props.reduce((props) => {
			const last = props.pop()
			const obj = props.reduce((o, k) => o[k], this.errors)
			if (last !== undefined && isEmpty(obj[last])) {
				delete obj[last]
			}
			return props
		}, props)
	},
	removeErrors() {
		this.errors = {}
	}
}
