<template>
	<fieldset :class="`w-full ${span}`">
		<legend
			v-if="props.label"
			:class="[
				{ 'font-bold': props.boldLabel },
				'font-extrabold leading-6 text-kta-blue'
			]"
		>
			{{ props.required ? `${props.label} *` : props.label }}
		</legend>
		<div
			v-if="props.description"
			:class="[{ 'font-bold': props.boldDescription }, 'text-extra-dark']"
		>
			{{ props.description }}
		</div>
		<ul
			:class="[
				props.orientation === 'stacked' ? 'flex-col space-y-2' : 'space-x-3',
				'content-ul-none mt-3 flex'
			]"
		>
			<li
				v-for="(option, index) in props.options"
				:key="index"
				class="content-li-none"
			>
				<label class="flex w-fit items-center">
					<input
						v-model="value"
						:value="option.optionValue"
						type="checkbox"
						:class="[
							{ 'border-kta-red': error },
							'h-5 w-5 rounded text-cobalt focus:ring-cobalt'
						]"
					/>
					<span class="ml-3 text-extra-dark">
						{{ option.optionName ? option.optionName : option.optionValue }}
					</span>
				</label>
			</li>
		</ul>
		<span v-if="error" class="absolute pt-1 text-xs italic text-kta-red"
			>Required</span
		>
	</fieldset>
</template>

<script>
import { mapStores } from 'pinia'
import { useFormStore } from '@/stores/formStore'
export default {
	name: 'CheckInput',
	props: {
		props: {
			type: Object,
			required: false
		},
		mappedContent: {
			type: Array,
			required: true
		}
	},
	emits: ['updateValue'],
	computed: {
		...mapStores(useFormStore),
		index() {
			return this.mappedContent.findIndex(
				(obj) => obj.label === this.props.label
			)
		},
		span() {
			return this.formStore.span[this.props.width]
		},
		value: {
			get() {
				return this.mappedContent[this.index].value
			},
			set(value) {
				this.$emit('updateValue', { index: this.index, value })
			}
		},
		error() {
			return this.mappedContent[this.index].error
		}
	},
	watch: {
		value(value) {
			if (value && this.error) {
				this.error = false
			}
		}
	}
}
</script>
