<template>
	<AlertBox />
	<MessageAlert />
	<PrimaryNav :class="{ 'md:top-8': alertIsActive }" />
	<main class="grow">
		<router-view />
	</main>
	<SiteFooter />
</template>

<script>
import { mapStores } from 'pinia'
import { useMainStore } from '@/stores/mainStore'
import startCase from 'lodash/startCase'
import AlertBox from '@/components/AlertBox'
import MessageAlert from '@/components/MessageAlert'
import PrimaryNav from '@/views/_partials/PrimaryNav'
import SiteFooter from '@/views/_partials/SiteFooter'
export default {
	components: {
		AlertBox,
		MessageAlert,
		PrimaryNav,
		SiteFooter
	},
	head() {
		return {
			meta: [
				{ name: 'description', content: this.metaDescription },
				// Twitter share data
				{ name: 'twitter:card', content: 'summary_large_image' },
				{ name: 'twitter:site', content: '@KansasTurnpike' },
				{ name: 'twitter:title', content: this.metaTitle },
				{ name: 'twitter:image', content: this.metaImage },
				{ name: 'twitter:domain', content: 'www.ksturnpike.com' },
				{ name: 'twitter:description', content: this.metaDescription },
				// Facebook (Open Graph) share data
				{ property: 'og:type', content: 'website' },
				{ property: 'og:site_name', content: 'Kansas Turnpike Authority' },
				{ property: 'og:title', content: this.metaTitle },
				{ property: 'og:image', content: this.metaImage },
				{ property: 'og:url', content: this.metaUrl },
				{ property: 'og:description', content: this.metaDescription },
				{ property: 'og:image:height', content: '600' },
				{ property: 'og:image:width', content: '1200' },
				{
					'data-n-head': 'ssr',
					'data-hid': 'og:image:type',
					property: 'og:image:type',
					content: this.metaImageType
				}
			]
		}
	},
	async created() {
		if (!Object.keys(this.mainStore.siteConfiguration).length)
			await this.mainStore.fetchSiteConfiguration()
		if (!this.mainStore.menus.length) await this.mainStore.fetchMenus()
	},
	computed: {
		...mapStores(useMainStore),
		alertIsActive() {
			return this.mainStore.getAlert?.active
		},
		metaImage() {
			if (this.mainStore?.page?.headerImage) {
				return window.location.origin + this.mainStore.page.headerImage.path
			}

			return 'https://www.ksturnpike.com/icons/KtaLogo.png'
		},
		metaImageType() {
			if (this.mainStore?.page?.headerImage) {
				return this.mainStore.page.headerImage.type.substring(6)
			}

			return 'image/png'
		},
		metaTitle() {
			const title = this.$route.meta.title
				? this.$route.meta.title
				: this.$route.meta.dynamicTitle
				? `${startCase(this.$route.params.slug)} - Kansas Turnpike Authority`
				: 'Kansas Turnpike Authority'

			return title
		},
		metaUrl() {
			return window.location.origin + this.$route.fullPath
		},
		metaDescription() {
			return (
				this.mainStore.page?.metaDescription ||
				'The Kansas Turnpike Authority maintains 236 miles of user-fee supported roadway from the Oklahoma border to Kansas City.'
			)
		}
	}
}
</script>
