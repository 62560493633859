<template>
	<div class="flex items-center justify-center">
		<button
			class="focus-visible:kta-ring"
			type="button"
			:aria-label="ariaLabel"
			@click="openModal"
		>
			<slot></slot>
		</button>
		<button
			v-if="buttonText"
			class="focus-visible:kta-ring rounded-md bg-black bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
			type="button"
			:aria-label="ariaLabel"
			@click="openModal"
		>
			{{ buttonText }}
		</button>
	</div>
	<TransitionRoot appear :show="isOpen" as="template">
		<Dialog
			class="relative z-50"
			as="div"
			:initialFocus="closeButtonRef"
			@close="closeModal"
		>
			<TransitionChild
				as="template"
				enter="duration-300 ease-out"
				enter-from="opacity-0"
				enter-to="opacity-100"
				leave="duration-200 ease-in"
				leave-from="opacity-100"
				leave-to="opacity-0"
			>
				<div class="fixed inset-0 bg-black bg-opacity-25" />
			</TransitionChild>
			<div class="fixed inset-0 overflow-y-auto">
				<div
					class="flex min-h-full items-center justify-center px-8 text-center"
				>
					<TransitionChild
						as="template"
						enter="duration-300 ease-out"
						enter-from="opacity-0 scale-95"
						enter-to="opacity-100 scale-100"
						leave="duration-200 ease-in"
						leave-from="opacity-100 scale-100"
						leave-to="opacity-0 scale-95"
					>
						<DialogPanel
							:class="[
								`${modalSize} w-full transform overflow-hidden rounded bg-white text-left align-middle shadow-wide transition-all`
							]"
						>
							<img
								v-if="imageUrl"
								:src="`${baseUrl}${imageUrl}`"
								alt="Modal Image"
								:class="[
									{ 'p-2': paddedImage },
									'max-h-96 w-full object-cover'
								]"
							/>
							<button
								ref="closeButton"
								:class="[
									paddedImage ? 'right-3 top-3' : 'right-2 top-2',
									imageUrl
										? 'rounded-full bg-dark/50 p-0.5 text-white'
										: 'text-dark ',
									'focus-visible:kta-ring absolute cursor-pointer hover:text-extra-dark'
								]"
								aria-label="Close"
								@click="closeModal()"
							>
								<XMarkIcon class="h-4 w-4" />
							</button>
							<div v-if="title || body" class="px-4 py-6">
								<DialogTitle
									v-if="title"
									class="mb-2 text-2xl font-bold leading-6 text-cobalt"
									v-html="title"
									as="h3"
								>
								</DialogTitle>
								<div>
									<p class="text-dark" v-html="body"></p>
								</div>
							</div>
							<slot name="body"></slot>
						</DialogPanel>
					</TransitionChild>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script>
import {
	TransitionRoot,
	TransitionChild,
	Dialog,
	DialogPanel,
	DialogTitle
} from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/solid'

export default {
	name: 'Modal',
	components: {
		TransitionRoot,
		TransitionChild,
		Dialog,
		DialogPanel,
		DialogTitle,
		XMarkIcon
	},
	props: {
		ariaLabel: {
			default: null,
			required: false
		},
		imageUrl: {
			type: String,
			required: false
		},
		title: {
			type: String,
			required: false
		},
		body: {
			type: String,
			required: false
		},
		paddedImage: {
			type: Boolean,
			default: false,
			required: false
		},
		buttonText: {
			type: String,
			default: '',
			required: false
		},
		size: {
			type: String,
			default: 'medium',
			required: false
		}
	},
	data: () => ({
		isOpen: false
	}),
	methods: {
		closeModal() {
			this.isOpen = false
		},
		openModal() {
			this.isOpen = true
		}
	},
	computed: {
		closeButtonRef() {
			return this.$refs.closeButton
		},
		baseUrl() {
			return window.location.origin
		},
		modalSize() {
			if (this.size === 'large') return 'max-w-screen-lg'
			if (this.size === 'medium') return 'max-w-screen-md'
			if (this.size === 'small') return 'max-w-screen-sm'

			return 'max-w-screen-md'
		}
	}
}
</script>
