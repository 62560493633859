<template>
	<nav
		role="navigation"
		aria-label="Pagination"
		:class="[
			{ 'mt-12': !noMargin },
			'flex select-none justify-center -space-x-px text-cobalt'
		]"
	>
		<a
			class="focus-visible:kta-ring-inset cursor-pointer rounded-l-md border border-light px-3 py-2 hover:border-cobalt hover:bg-cobalt hover:text-white"
			tabindex="0"
			@click="prev()"
			@keyup.enter="prev()"
		>
			<span>Previous</span>
		</a>
		<ul class="content-ul-none flex">
			<li
				v-for="index in pagination"
				:key="index"
				class="content-li-none hidden sm:flex"
			>
				<span
					v-if="isNaN(index)"
					aria-hidden="true"
					class="hidden cursor-default border border-light px-4 py-2 font-bold text-medium sm:block"
					>{{ index }}</span
				>
				<a
					v-else
					:aria-label="`Navigate to page ${index}`"
					:aria-current="this.currentPage === index"
					:class="[
						this.currentPage === index
							? 'border-kta-blue bg-kta-blue text-white'
							: 'hidden hover:border-cobalt hover:bg-cobalt hover:text-white sm:block',
						'focus-visible:kta-ring-inset cursor-pointer space-x-4 border border-light px-4 py-2 sm:block'
					]"
					tabindex="0"
					@click="goToPage(index)"
					@keyup.enter="goToPage(index)"
				>
					{{ index }}
				</a>
			</li>
		</ul>
		<a
			class="focus-visible:kta-ring-inset cursor-pointer rounded-r-md border border-light px-3 py-2 hover:border-cobalt hover:bg-cobalt hover:text-white"
			tabindex="0"
			@click="next()"
			@keyup.enter="next()"
		>
			Next
		</a>
	</nav>
	<span class="mt-4 flex justify-center text-sm sm:hidden"
		>Page {{ currentPage }} of {{ pageCount }}</span
	>
</template>

<script>
export default {
	name: 'Pagination',
	props: {
		pageCount: {
			type: Number,
			required: true
		},
		noMargin: {
			type: Boolean,
			default: false,
			required: false
		}
	},
	emits: ['pageChange'],
	data: () => ({
		currentPage: 1
	}),
	methods: {
		prev() {
			if (this.currentPage > 1) {
				this.currentPage -= 1
				this.$emit('pageChange', this.currentPage - 1)
			}
		},
		next() {
			if (this.currentPage < this.pageCount) {
				this.currentPage += 1
				this.$emit('pageChange', this.currentPage - 1)
			}
		},
		goToPage(index) {
			this.currentPage = index
			this.$emit('pageChange', this.currentPage - 1)
		}
	},
	computed: {
		pagination() {
			if (this.pageCount > 7) {
				const start = this.currentPage < 5 ? [1, 2, 3, 4, 5] : [1]
				const middle =
					this.currentPage > 4 && this.currentPage + 4 <= this.pageCount
						? [
								'...',
								this.currentPage - 2,
								this.currentPage - 1,
								this.currentPage,
								this.currentPage + 1,
								this.currentPage + 2,
								'...'
						  ]
						: ['...']
				const end =
					this.currentPage + 4 > this.pageCount
						? [
								this.pageCount - 4,
								this.pageCount - 3,
								this.pageCount - 2,
								this.pageCount - 1,
								this.pageCount
						  ]
						: [this.pageCount]
				return [...start, ...middle, ...end]
			}
			return [...Array(this.pageCount).keys()].map((index) => index + 1)
		}
	}
}
</script>
