import plazas from '@/constants/Plazas'
import { ctsRates } from '@/constants/Rates'
import { useMainStore } from '@/stores/mainStore'
import axios from 'axios'
import { defineStore } from 'pinia'

export const useMapStore = defineStore('map', {
	state: () => ({
		interactiveMap: {},
		plazas,
		rates: [],
		ctsRates
	}),
	getters: {
		mainStore() {
			return useMainStore()
		}
	},
	actions: {
		async fetchDms() {
			try {
				const { data } = await axios.get(`${process.env.VUE_APP_API}/dms`)
				this.interactiveMap.dms = data
			} catch (error) {
				this.mainStore.handleErrors(error?.response?.data?.error)
				this.interactiveMap.dms = []
			}
		},
		async fetchRates() {
			try {
				const { data } = await axios.get(`${process.env.VUE_APP_API}/rates`)
				this.rates = data
			} catch (error) {
				this.mainStore.handleErrors(error?.response?.data?.error)
				this.rates = []
			}
		},
		async fetchServiceAreas() {
			try {
				let { data } = await axios.get(
					`${process.env.VUE_APP_API}/service-areas/published`
				)
				this.interactiveMap.service_area = data
			} catch (error) {
				this.mainStore.handleErrors(error?.response?.data?.error)
				this.interactiveMap.service_area = []
			}
		},
		async fetchWeather() {
			try {
				let { data } = await axios.get(`${process.env.VUE_APP_API}/weather`)
				data = data.map((item) => ({
					...item,
					position: {
						lat: item.location.position.lat,
						lng: item.location.position.lng
					}
				}))

				this.interactiveMap.weather = data
			} catch (error) {
				this.mainStore.handleErrors(error?.response?.data?.error)
				this.interactiveMap.weather = []
			}
		}
	}
})
