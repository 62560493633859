<template>
	<FormAlert :alert="alert" @closeAlert="alert = null" />
	<form
		@submit.prevent="submit"
		class="w-full sm:w-1/2"
		aria-label="Email Sign-up Form"
	>
		<h3 class="not-sr-only">Email Sign-up</h3>
		<div class="w-full">
			<div class="mb-7">
				<TextInput
					label="Full Name"
					v-model="subscriber.name"
					:error="errors?.name"
					@removeError="removeError(['name'])"
				/>
			</div>
			<div class="mb-7">
				<TextInput
					label="Email"
					v-model="subscriber.email"
					:error="errors?.email"
					@removeError="removeError(['email'])"
				/>
			</div>
			<div class="mb-7">
				<TextInput
					label="ZIP Code"
					v-model="subscriber.zip"
					:error="errors?.zip"
					@removeError="removeError(['zip'])"
				/>
			</div>
		</div>
		<CheckInput
			:options="options"
			v-model="subscriber.lists"
			label="Subscription Options"
			boldLabel
			:error="errors?.lists"
			@removeError="removeError(['lists'])"
		/>
		<span class="mt-3 inline-block w-full">
			<ActionButton type="submit" text="Submit" fullWidth />
		</span>
	</form>
	<LoadAnimation v-if="loading" />
</template>

<script>
import LoadAnimation from '@/components/utils/LoadAnimation'
import { CheckInput, FormAlert, TextInput } from '@/components/utils/form'
import { useMainStore } from '@/stores/mainStore'
import { mapStores } from 'pinia'
export default {
	name: 'SignUpForm',
	components: {
		CheckInput,
		FormAlert,
		LoadAnimation,
		TextInput
	},
	mounted() {
		this.mainStore.removeErrors()
	},
	data: () => ({
		alert: null,
		subscriber: {
			name: '',
			email: '',
			zip: '',
			lists: []
		},
		options: [
			{
				optionValue: 'TurnpikeTimes',
				optionName: 'Turnpike Times'
			},
			{
				optionValue: 'NewsReleases',
				optionName: 'KTA News Releases'
			},
			{
				optionValue: 'Employment',
				optionName: 'Employment Openings'
			},
			{
				optionValue: 'ConstructionNews',
				optionName: 'Construction News'
			},
			{
				optionValue: 'CashlessTollingUpdates',
				optionName: 'Cashless Tolling Updates'
			}
		]
	}),
	methods: {
		removeError(props) {
			this.mainStore.removeError(props)
		},
		async submit() {
			const response = await this.mainStore.submitSubscriber(this.subscriber)
			if (response) {
				this.subscriber = {
					name: '',
					email: '',
					zip: '',
					lists: []
				}
			}

			this.alert = response
				? { type: 'success', message: 'Email signup successful.' }
				: { type: 'fail', message: 'Failed to sign up. Please try again.' }
		}
	},
	computed: {
		...mapStores(useMainStore),
		loading() {
			return this.mainStore.loading
		},
		errors() {
			return this.mainStore.errors
		}
	}
}
</script>
