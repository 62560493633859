import { createApp } from 'vue/dist/vue.esm-bundler'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import { createHead, VueHeadMixin } from '@unhead/vue'
import '@/assets/main.css'

import Accordion from '@/components/Accordion'
import ActionButton from '@/components/utils/ActionButton'
import ContactForm from '@/components/ContactForm'
import Form from '@/components/formBuilder/Form'
import KtaMap from '@/components/map/KtaMap'
import Careers from '@/components/Careers'
import Leadership from '@/components/Leadership'
import Modal from '@/components/Modal'
import NavLink from '@/components/utils/NavLink'
import Posts from '@/components/Posts'
import SignUpForm from '@/components/SignUpForm'

const head = createHead()
const app = createApp(App)
	.mixin(VueHeadMixin)
	.use(head)
	.use(router)
	.use(createPinia())
	.component('Accordion', Accordion)
	.component('ActionButton', ActionButton)
	.component('ContactForm', ContactForm)
	.component('Form', Form)
	.component('KtaMap', KtaMap)
	.component('Careers', Careers)
	.component('Leadership', Leadership)
	.component('Modal', Modal)
	.component('NavLink', NavLink)
	.component('Posts', Posts)
	.component('SignUpForm', SignUpForm)

app.mount('#app')
