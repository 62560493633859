<template>
	<div ref="googleMap" class="h-full w-full lg:rounded-b-lg"></div>
	<button
		type="button"
		title="Reset zoom"
		class="absolute right-2.5 top-[170px] rounded-sm bg-white p-2 drop-shadow-md hover:text-extra-dark lg:block"
		@click="resetZoom()"
	>
		<ArrowPathIcon class="not-sr-only h-6 w-6" />
	</button>
	<template v-if="this.map">
		<slot :map="map" />
	</template>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader'
import { ArrowPathIcon } from '@heroicons/vue/24/outline'
export default {
	name: 'GoogleMapLoader',
	components: {
		ArrowPathIcon
	},
	props: {
		mapConfig: {
			type: Object,
			required: true
		},
		apiKey: {
			type: String,
			required: true
		}
	},
	emits: ['closeInfoWindow'],
	expose: ['resetZoom'],
	data: () => ({
		map: null
	}),
	async mounted() {
		this.initializeMap()
	},
	methods: {
		initializeMap() {
			const loader = new Loader({
				apiKey: this.apiKey
			})
			loader.load().then((google) => {
				this.map = new google.maps.Map(this.$refs['googleMap'], {
					...this.mapConfig,
					mapTypeId: google.maps.MapTypeId.ROADMAP,
					zoomControlOptions: {
						style: google.maps.ZoomControlStyle.SMALL,
						position: google.maps.ControlPosition.RIGHT_TOP
					}
				})
				this.map.addListener('click', () => {
					this.$emit('closeInfoWindow')
				})
				this.setBounds()
			})
		},
		setBounds() {
			this.bounds = new window.google.maps.LatLngBounds()
			this.bounds.extend({ lat: 36.998863, lng: -97.342775 })
			this.bounds.extend({ lat: 39.0931557917845, lng: -94.64233249425888 })
			this.map.fitBounds(this.bounds, 500)
		},
		resetZoom() {
			this.map.fitBounds(this.bounds)
			this.map.setZoom(8)
		}
	}
}
</script>
