<template>
	<form v-if="form" class="mb-5 space-y-7" @submit.prevent="submit()">
		<h3>{{ form.title }}</h3>
		<FormAlert v-if="alert" :alert="alert" @closeAlert="alert = null" />
		<FormRow
			v-for="(row, index) in form.content"
			:columns="columns"
			:components="row.components"
			:key="index"
			:mappedContent="mappedContent"
			@updateValue="updateValue($event)"
		/>
		<ActionButton type="submit" text="Submit" />
	</form>
</template>

<script>
import { mapStores } from 'pinia'
import { useFormStore } from '@/stores/formStore'
import { FormAlert } from '@/components/utils/form'
import FormRow from './FormRow'
export default {
	name: 'Form',
	props: {
		id: {
			type: Number,
			required: true
		}
	},
	components: {
		FormAlert,
		FormRow
	},
	data: () => ({
		alert: null,
		form: null,
		mappedContent: []
	}),
	async created() {
		const { mappedContent, ...form } = await this.formStore.fetchForm(this.id)
		this.form = form
		this.mappedContent = mappedContent
	},
	methods: {
		async submit() {
			const isValid = this.validateForm()
			if (!isValid) {
				return
			}
			const response = await this.formStore.submit(this.id, this.mappedContent)

			if (response) this.resetForm()

			this.alert = response
				? {
						type: 'success',
						message: 'Thank you! Your submission was successful.'
				  }
				: {
						type: 'fail',
						message: 'Failed to submit form. Please try again.'
				  }
		},
		updateValue(data) {
			this.mappedContent[data.index].value = data.value ?? ''
			if (this.mappedContent[data.index].error) {
				this.mappedContent[data.index].error = false
			}
		},
		resetForm() {
			this.mappedContent = this.formStore.mapFormItems(this.form.content)
		},
		validateForm() {
			let passedValidation = true
			this.mappedContent.forEach((input) => {
				if (!input.value.length && input.required) {
					input.error = true
					passedValidation = false
				}
			})
			return passedValidation
		}
	},
	computed: {
		...mapStores(useFormStore),
		columns() {
			return this.form.columns
		}
	}
}
</script>
