import { format } from 'date-fns'

function formatPay(min, max, payType) {
	const payMin =
		payType.toLowerCase() === 'annual'
			? Math.round(parseFloat(min)).toLocaleString('en-US')
			: parseFloat(min).toLocaleString('en-US', {
					minimumFractionDigits: 2
			  })
	const payMax =
		payType.toLowerCase() === 'annual'
			? Math.round(parseFloat(max)).toLocaleString('en-US')
			: parseFloat(max).toLocaleString('en-US', {
					minimumFractionDigits: 2
			  })

	return parseInt(max)
		? `$${payMin} - $${payMax} ${payType}`
		: `$${payMin} ${payType}`
}

export default {
	getAlert(state) {
		return state.siteConfiguration.alert
	},
	getAuxiliaryMenu(state) {
		return state.menus.find(
			(menu) => menu.name === this.getNavigation?.auxiliary.name
		)?.items
	},
	getSecondaryMenu(state) {
		return state.menus.find(
			(menu) => menu.name === this.getNavigation?.secondary.name
		)?.items
	},
	getPrimaryMenu(state) {
		return state.menus.find(
			(menu) => menu.name === this.getNavigation?.primary.name
		)?.items
	},
	getNavigation(state) {
		return state.siteConfiguration?.navigation
	},
	getSlider(state) {
		return { slides: state.slider, delay: state.siteConfiguration.sliderDelay }
	},
	getSubjects(state) {
		return state.siteConfiguration.contact.contactItems.map(
			({ subject }) => subject
		)
	},
	getJobs(state) {
		const parser = new DOMParser()
		return state.jobs.reduce(
			(
				jobs,
				{
					applyLink,
					department,
					description,
					locationName: location,
					positionType,
					posted,
					summary,
					title,
					wageMin,
					wageMax,
					wageType
				}
			) => {
				// remove HTML entities
				description = parser.parseFromString(description, 'text/html').body
					.textContent

				const salaryRange = formatPay(wageMin, wageMax, wageType)

				posted = format(new Date(posted), 'LLL d, yyyy')

				const salaryRangeTitle = parseInt(wageMax)
					? 'Salary Range'
					: 'Starting at'

				if (!jobs.length) {
					jobs.push({
						applyLink,
						department,
						description,
						location,
						positionType,
						posted,
						salaryRange,
						salaryRangeTitle,
						summary,
						title
					})
					return jobs
				}
				const found = jobs.find((job) => title === job?.title)
				if (!found) {
					jobs.push({
						applyLink,
						department,
						description,
						location,
						positionType,
						posted,
						salaryRange,
						salaryRangeTitle,
						summary,
						title
					})
					return jobs
				}
				if (found.locations) {
					found.locations.push({ applyLink, location })
					return jobs
				}
				found.locations = [
					{
						applyLink: found.applyLink,
						location: found.location
					},
					{
						applyLink,
						location
					}
				]
				found.location = 'Various Locations'
				found.applyLink = ''
				return jobs
			},
			[]
		)
	}
}
