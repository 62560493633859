<template>
	<Listbox v-model="selectedOption">
		<div :class="`relative w-full leading-none ${span}`">
			<ListboxLabel
				class="mb-2 block leading-none text-dark"
				v-if="props.label"
			>
				{{ props.required ? `${props.label} *` : props.label }}</ListboxLabel
			>
			<ListboxButton
				:class="[
					error ? 'border-kta-red' : 'active:ring-1 active:ring-kta-blue',
					'text-md flex w-full cursor-default items-center justify-between rounded border border-medium bg-white px-3 py-2 text-left outline-none focus:border-sky focus:ring-1 focus:ring-kta-blue sm:text-base'
				]"
			>
				<span class="block truncate text-base text-dark">
					{{ selectedOption }}</span
				>
				<span class="pointer-events-none inset-y-3 right-0 flex items-center">
					<ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
				</span>
			</ListboxButton>
			<transition
				leave-active-class="transition duration-100 ease-in"
				leave-from-class="opacity-100"
				leave-to-class="opacity-0"
			>
				<ListboxOptions
					class="absolute z-10 mt-1 max-h-60 w-full overflow-auto overscroll-contain rounded-md border border-medium bg-white py-1 text-base ring-1 ring-black ring-opacity-5 scrollbar-hide focus:outline-none sm:text-sm"
				>
					<ListboxOption
						v-slot="{ active, selected }"
						v-for="(option, index) in props.options"
						:key="index"
						:value="option"
						as="template"
					>
						<li
							:class="[
								active ? 'bg-kta-blue text-white' : 'text-extra-dark',
								'content-li-none relative cursor-default select-none px-4 py-2'
							]"
						>
							<span
								:class="[
									selected ? 'font-semibold' : 'font-normal',
									'block truncate text-base'
								]"
								>{{
									option.optionName ? option.optionName : option.optionValue
								}}</span
							>
							<span
								v-if="selected"
								class="absolute inset-y-0 left-0 flex items-center pl-3 text-kta-blue"
							>
							</span>
						</li>
					</ListboxOption>
				</ListboxOptions>
			</transition>
			<span v-if="error" class="absolute pt-1 text-xs italic text-kta-red"
				>Required</span
			>
		</div>
	</Listbox>
</template>

<script>
import { useFormStore } from '@/stores/formStore'
import {
	Listbox,
	ListboxButton,
	ListboxLabel,
	ListboxOption,
	ListboxOptions
} from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/24/solid'
import { mapStores } from 'pinia'
export default {
	name: 'SelectInput',
	components: {
		Listbox,
		ListboxLabel,
		ListboxButton,
		ListboxOptions,
		ListboxOption,
		ChevronDownIcon
	},
	props: {
		props: {
			type: Object,
			required: false
		},
		mappedContent: {
			type: Array,
			required: true
		}
	},
	emits: ['updateValue'],
	data: () => ({
		selected: null
	}),
	computed: {
		...mapStores(useFormStore),
		index() {
			return this.mappedContent.findIndex(
				(obj) => obj.label === this.props.label
			)
		},
		span() {
			return this.formStore.span[this.props.width]
		},
		value: {
			get() {
				return this.mappedContent[this.index].value
			},
			set(value) {
				this.$emit('updateValue', { index: this.index, value })
			}
		},
		selectedOption: {
			get() {
				return this.selected
					? this.selected.optionName
						? this.selected.optionName
						: this.selected.optionValue
					: 'Select Option'
			},
			set(value) {
				this.selected = value
			}
		},
		error() {
			return this.mappedContent[this.index].error
		}
	},
	watch: {
		selected(value) {
			this.value = value.optionValue
		},
		value(value) {
			if (!value) this.selected = ''
			if (value && this.error) {
				this.error = false
			}
		}
	}
}
</script>
