import { defineStore } from 'pinia'
import actions from './actions'
import getters from './getters'
import states from '@/constants/States'

export const useMainStore = defineStore('main', {
	state: () => ({
		accordions: [],
		jobs: [],
		leadership: {},
		links: [],
		pages: [],
		page: {},
		siteConfiguration: {},
		slider: {},
		states,
		sitemap: {},
		menus: [],
		loading: false,
		messageAlert: {
			position: 'top',
			level: '',
			title: '',
			text: '',
			timer: null
		},
		errors: {}
	}),
	getters,
	actions
})
